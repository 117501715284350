import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import './css/bootstrap-4-3-1.css'

class Menu extends React.Component {
  logout(setUserInfo) {
    fetch("/signOut",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST'
      })
    .then(res => {
      setUserInfo(-1, 0);
    })
    .catch(error => console.error(error))
  }

  render() {
    return (
        <ListGroup>
          {this.props.items
            .filter(item => item.roles.includes(this.props.user_role))
            .map(item =>
              <ListGroup.Item 
                variant={item.variant}
                action href={item.link}>
                {item.name}
              </ListGroup.Item>
          )}
          <ListGroup.Item action 
            onClick={() => this.logout(this.props.setUserInfo)}>Выход
          </ListGroup.Item>
        </ListGroup>
    )
  }
}

export default Menu;
