import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as yup from 'yup';
import { Formik } from 'formik';
import '../../css/bootstrap-4-3-1.css';
import '../../css/styles.css';

const schema = yup.object({
  name: yup.string().required('Название организации обязательное поле!')
    .max(20, 'Название не должно превышать 20 символов!'),
  adress: yup.string().required('Адрес обязательное поле!'),
});

class AddBranchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      quotaError: false
    }
    this.show = this.show.bind(this);
  }

  show() {
    this.setState({
      show: true,
      quotaError: false
    });
  }

  close() {
    this.setState({show: false});
  }

  onSubmit(values){
    let handleToUpdate = this.props.handleToUpdate;
    values.branch_id = 0;
    fetch("/addBranch",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(values)
      })
      .then(res => res.json())
      .then(data => {
        if (data.code === 5){
          this.props.quit();
        }
        else if (data.code === 2){
          this.setState({quotaError: true})
        }
        else{
          this.close();
          handleToUpdate();
        }
      })
      .catch(error => console.error(error))
  }

  render() {
    return (
      <React.Fragment>
        <Button variant='outline-primary' onClick={ () => this.show() } block>
          Новый филиал</Button>
        <Modal size="lg" show={this.state.show} onHide={() => this.close()}>
          <Modal.Header closeButton>
            <Modal.Title>Создание нового филиала</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Formik
              validationSchema={schema}
              onSubmit={(values) => {
                  this.onSubmit(values);
                }
              }
              initialValues={{name: '', adress: ''}}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="branchName">
                      <Form.Label column md={4}>Название</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          placeholder="Название"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!(errors.name && touched.name)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="branchAdress">
                      <Form.Label column md={4}>Адрес</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          placeholder="Адрес"
                          name="adress"
                          value={values.adress}
                          onChange={handleChange}
                          isInvalid={!!(errors.adress && touched.adress)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.adress}
                        </Form.Control.Feedback>

                        {this.state.quotaError 
                          ? <div class="text-danger">
                              Превышена квота филиалов организации!
                            </div> 
                          : ''}
                      </Col>
                    </Form.Group>

                    <div class="d-flex flex-row-reverse">
                      <Button type="submit">Создать</Button>
                      <Button variant="secondary" onClick={() => this.close()}>Отмена</Button>
                    </div>
                </Form>
              )}
            </Formik>

          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}

export default AddBranchForm;
