import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as yup from 'yup';
import { Formik } from 'formik';
import '../../css/bootstrap-4-3-1.css';
import '../../css/styles.css';

class AddUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      branches: [],
      branchVisible: false,
      userNameError: false,
      userQuotaError: false,
      branchEmptiness: false,
      branchError: false
    }
    this.show = this.show.bind(this);
    this.updateData = this.updateData.bind(this);
  }

  getSchema(){
    return yup.object({
      name: yup.string().required('Имя пользователя обязательное поле!')
        .max(20, 'Имя не должно превышать 20 символов!'),
      mail: yup.string().email('Введен невалидный почтовый адрес!')
        .required('Почтовый адрес обязательное поле!'),
      password: yup.string().required('Придумайте пароль!')
        .max(20, 'Пароль не должен превышать 20 символов!')
        .min(6, 'Пароль должен быть не менее 6 символов!'),
      user_role: yup.number(),
      branch_id: yup.string().test('branchTest', 'Выберите филиал!', function(value){
        const user_role = this.parent.user_role;
        if (user_role === 1) return true;
        if (value !== 0) return true;
        return false; 
      }),
    });
  }

  show() {
    Promise.all([
      this.updateData(),
    ])
    .then(res => {
      this.setState({
        show: true,
        userNameError: false,
        userQuotaError: false,
        branchError: false
      })
    })
  }

  updateData(){
    fetch("/getAvailableBranches")
      .then(res => res.json())
      .then(
        data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            if (data.code === 7){
             this.setState({'branchEmptiness': true}) 
            }
            else
            {
              this.setState({
                isLoaded: true,
                branches: data
              });
            }
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  close() {
    this.setState({show: false});
  }

  onSubmit(values){
    let handleToUpdate = this.props.handleToUpdate;
    values.user_id = 0;
    fetch("/checkUser",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(values)
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === 0){
          fetch("/addUser",
            {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              method: 'POST',
              body: JSON.stringify(values)
            })
            .then(data => {
              if (data.code === 5){
                this.props.quit();
              }
              else{
                this.close();
                handleToUpdate();
              }
            })
            .catch(error => console.error(error))
        }
        else{
          if (data.code === 1){
            this.setState({userNameError: true});
          }
          if (data.code === 5){
            this.props.quit();
          }
          if (data.code === 2){
            this.setState({userNameError: false});
            this.setState({userQuotaError: true}); 
          }
        }
      })
      .catch(error => console.error(error))
  }

  render() {
    return (
      <React.Fragment>
        <Button variant='outline-primary' onClick={ () => this.show() }>
          Создать пользователя</Button>
        <Modal size="lg" show={this.state.show} onHide={() => this.close()}>
          <Modal.Header closeButton>
            <Modal.Title>Создание нового пользователя</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Formik
              validationSchema={this.getSchema()}
              onSubmit={(values, event) => {
                  if (this.state.branchError){
                    event.preventDefault();
                  }
                  this.onSubmit(values);
                }
              }

              initialValues={{
                name: '', 
                mail: '', 
                password: '', 
                user_role: '1', 
                branch_id: '0'}}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="name">
                      <Form.Label column md={4}>Имя пользователя</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          placeholder="Имя"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!(errors.name && touched.name)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="mail">
                      <Form.Label column md={4}>E-mail</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type="email"
                          placeholder="Почта"
                          name="mail"
                          value={values.mail}
                          onChange={handleChange}
                          isInvalid={!!(errors.mail && touched.mail)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.mail}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="user_role">
                      <Form.Label column md={4}>Роль</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          as="select"
                          name="user_role"
                          value={values.user_role}
                          onChange={(event) => {
                            let parsed = parseInt(event.target.value);
                            if (parsed === 1){
                              this.setState({'branchVisible': false})
                              this.setState({'branchError': false})
                            }
                            else{
                              this.setState({'branchVisible': true})
                              if (this.state.branchEmptiness){
                                this.setState({'branchError': true})
                              }
                            }
                            handleChange(event);
                          }}
                        >
                          <option value='1'>Модератор</option>
                          <option value='2'>Пользователь</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>

                    {this.state.branchVisible 
                      ? <Form.Group as={Row} controlId="branch_id">
                          <Form.Label column md={4}>Филиал</Form.Label>
                          <Col md={8}>
                            <Form.Control
                              as="select"
                              name="branch_id"
                              value={values.branch_id}
                              onChange={handleChange}
                              isInvalid={!!(errors.branch_id && touched.branch_id)}
                            >
                              <option value='0'>Выберите филиал</option>
                              {
                                Object.keys(this.state.branches)
                                  .map((key, index) => (
                                    <option value={this.state.branches[key].branch_id}>
                                        {this.state.branches[key].name}</option>
                              ))
                            }

                            </Form.Control>

                              <Form.Control.Feedback type="invalid">
                                {errors.branch_id}
                              </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                      :''}

                    <Form.Group as={Row} controlId="password">
                      <Form.Label column md={4}>Пароль</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type="password"
                          placeholder="Пароль"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!(errors.password && touched.password)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>

                        {this.state.branchError 
                          ? <div class="text-danger">
                              Для создания пользователей необходимо наличие филиалов!
                            </div> 
                          : ''}
                        {this.state.userNameError 
                          ? <div class="text-danger">
                              Пользователь с таким именем уже существует!
                            </div> 
                          : ''}
                        {this.state.userQuotaError 
                          ? <div class="text-danger">
                              Превышена квота пользователей организации!
                            </div> 
                          : ''}
                      </Col>
                    </Form.Group>

                    <div class="d-flex flex-row-reverse">
                      <Button type="submit">Создать</Button>
                      <Button variant="secondary" 
                        onClick={() => this.close()}>Отмена</Button>
                    </div>
                </Form>
              )}
            </Formik>

          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}

export default AddUserForm;
