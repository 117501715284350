import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as yup from 'yup';
import { Formik } from 'formik';
import '../../css/bootstrap-4-3-1.css';
import 'react-picky/dist/picky.css';
import '../../css/styles.css';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

let moment = require('moment');
require('moment/locale/ru');

class AddTransactionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      transaction_types: [],
      date: moment(),
    }
    this.show = this.show.bind(this);
    this.getTransactionTypes = this.getTransactionTypes.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    this.getTransactionTypes();
  }

  getSchema(){
    return yup.object({
      sum: yup.string().required('Сумма - обязательное поле!'),
    });
  }

  show() {
    this.setState({
      show: true
    })
    console.log(this.state.transaction_types)
  }

  close() {
    this.setState({show: false});
  }

  getTransactionTypes(){
    return fetch("/getTransactionTypes")
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            this.setState({
              transaction_types: data
            });
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  handleDateChange(value){
    let d = moment(value, "DD.MM.YYYY", true);
    let y = isNaN(d.year());
    if (y){
      return
    }
    this.setState({date: d})
  }

  onSubmit(values){
    let handleToUpdate = this.props.handleToUpdate;
    values.date = this.state.date.add(3, 'hours');

    fetch("/addTransaction",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(values)
      })
      .then(res => res.json())
      .then(data => {
        if (data.code === 5){
          this.props.quit();
        }
        else{
          this.close();
          handleToUpdate();
        }
      })
      .catch(error => console.error(error))
  }

  render() {
    return (
      <React.Fragment>
        <Button variant='outline-primary' onClick={ () => this.show() } block>
          Новая транзакция</Button>
        <Modal size="lg" show={this.state.show} onHide={() => this.close()}>
          <Modal.Header closeButton>
            <Modal.Title>Новая финансовая транзакция</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Formik
              validationSchema={this.getSchema()}
              onSubmit={(values) => {
                  this.onSubmit(values);
                }
              }
              initialValues={{sum: '', description: '', base: 0, 
                branch_id: this.props.branch_id, type_id: 0, cash: true}}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
                setFieldValue
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="transactionSum">
                      <Form.Label column md={4}>Сумма</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type="number"
                          placeholder="Руб."
                          name="sum"
                          value={values.sum}
                          onChange={e => {
                            e.preventDefault();
                            const { value } = e.target;
                            const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                            if (regex.test(value.toString())) {
                              setFieldValue("sum", value);
                            }
                          }}
                          isInvalid={!!(errors.sum && touched.sum)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.sum}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    { this.props.branch_id === 0 
                    ? <Form.Group as={Row} controlId="branch_id">
                        <Form.Label column md={4}>Филиал</Form.Label>
                        <Col md={3}>
                          <Form.Control
                            as="select"
                            name="branch_id"
                            value={values.branch_id}
                            onChange={handleChange}
                            isInvalid={!!(errors.branch_id && touched.branch_id)}
                          >
                            <option value='0'>Общеорганизационная</option>
                            {
                              Object.keys(this.props.branches
                                .filter(branch => 
                                  branch.state === 0))
                                .map((key, index) => (
                                <option value={this.props.branches[key].branch_id}>
                                  {this.props.branches[key].name}</option>
                            ))
                          }

                          </Form.Control>

                            <Form.Control.Feedback type="invalid">
                              {errors.branch_id}
                            </Form.Control.Feedback>
                        </Col>

                        <Form.Label column md={2}>Дата</Form.Label>
                        <Col md={3}>
                          <Datetime
                            locale="ru"
                            dateFormat="DD.MM.YYYY"
                            defaultHour={14}
                            timeFormat="HH:mm"
                            value={this.state.date}
                            onChange={this.handleDateChange}
                          />
                        </Col>
                      </Form.Group>
                    : ''}

                    <Form.Group as={Row} controlId="transactionDescription">
                      <Form.Label column md={4}>Описание</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          as="textarea" 
                          rows="5"
                          placeholder="Описание"
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                          isInvalid={!!(errors.description && touched.description)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.description}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="base">
                      <Form.Label column md={4}>Тип транзакции</Form.Label>
                      <Col md={4}>
                        <Form.Control
                          as="select"
                          name="base"
                          value={values.base}
                          onChange={handleChange}
                        >
                          <option value='0'>Расходная транзакция</option>
                          <option value='1'>Приходная транзакция</option>
                        </Form.Control>
                      </Col>

                      <Form.Label column md={3}>Наличный р-т</Form.Label>
                      <Col md={1}>
                        <Form.Control
                          type="checkbox"
                          name="paymentCash"
                          checked={values.cash}
                          value={values.cash}
                          onChange={e => {
                            setFieldValue("cash", !values.cash);
                          }}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="type_id">
                      <Form.Label column md={4}>Вид транзакции</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          as="select"
                          name="type_id"
                          value={values.type_id}
                          onChange={handleChange}
                        >
                          <option value='0'>Не указан</option>
                          {
                              this.state.transaction_types
                                .filter(transaction_type => 
                                  transaction_type.base === parseInt(values.base))
                                .filter(transaction_type => 
                                  transaction_type.state === 0)
                                .map(transaction_type => (
                                  <option value={transaction_type.transaction_type_id}>
                                    {transaction_type.value}</option>
                            ))
                          }
                        </Form.Control>
                      </Col>
                    </Form.Group>

                    <div class="d-flex flex-row-reverse">
                      <Button type="submit">Создать</Button>
                      <Button variant="secondary" onClick={() => this.close()}>Отмена</Button>
                    </div>
                </Form>
              )}
            </Formik>

          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}

export default AddTransactionForm;
