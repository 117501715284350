import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as yup from 'yup';
import { Formik } from 'formik';
import '../../css/bootstrap-4-3-1.css';
import '../../css/styles.css';

class AddSaleForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    }
    this.show = this.show.bind(this);
  }

  getSchema(){
    return yup.object({
      value: yup.number().required('Размер скидки - обязательное поле!')
        .max(99, 'Скидка не может превышать 99%')
        .positive('Скидка должна быть положительным числом!'),
      condition: yup.number().required('Сумма трат - обязательное поле!')
        .min(0, 'Сумма должна быть неотрицательным числом!'),
    });
  }

  show() {
    this.setState({
      show: true,
    })
  }

  close() {
    this.setState({show: false});
  }

  onSubmit(values){
    let handleToUpdate = this.props.handleToUpdate;

    fetch("/addSale",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(values)
      })
      .then(data => {
        if (data.code === 5){
          this.props.quit()
        }
        else{
          handleToUpdate();
          this.close();
        }
      })
      .catch(error => console.error(error))
  }

  render() {
    return (
      <React.Fragment>
        <Button variant='outline-primary' onClick={() => this.show()} block>
          Добавить скидку</Button>
        <Modal size="lg" show={this.state.show} onHide={() => this.close()}>
          <Modal.Header closeButton>
            <Modal.Title>Создание новой скидки</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Formik
              validationSchema={this.getSchema()}
              onSubmit={(values, event) => {
                  this.onSubmit(values);
                }
              }
              initialValues={{value: '', condition: ''}}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="value">
                      <Form.Label column md={4}>Размер скидки</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type="number"
                          placeholder="Скидка"
                          name="value"
                          value={values.value}
                          onChange={handleChange}
                          isInvalid={!!(errors.value && touched.value)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.value}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="condition">
                      <Form.Label column md={4}>Условие получения</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type="number"
                          placeholder="Потраченная сумма"
                          name="condition"
                          value={values.condition}
                          onChange={handleChange}
                          isInvalid={!!(errors.condition && touched.condition)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.condition}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <div class="d-flex flex-row-reverse">
                      <Button type="submit">Создать</Button>
                      <Button variant="secondary" 
                        onClick={() => this.close()}>Отмена</Button>
                    </div>
                </Form>
              )}
            </Formik>

          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}

export default AddSaleForm;
