import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Picky from 'react-picky';
import * as yup from 'yup';
import { Formik, Field } from 'formik';
import '../../css/bootstrap-4-3-1.css'
import 'react-picky/dist/picky.css';
import '../../css/styles.css';
import MaskedInput from 'react-text-mask';

const phoneNumberMask = [
  "+",
  "7",
  " ",
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const dateMask = [
  /[0-3]/,
  /[0-9]/,
  ".",
  /[0-1]/,
  /[0-9]/,
  ".",
  /[1-2]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/
];

class AddClientForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      numberError: false,
      phoneError: false,
      behaviors: [],
      attractions: [],
      clientCategories: [],
      selectedBehaviors: [],
      selectedAttractions: []
    }
    this.show = this.show.bind(this);
    this.updateData = this.updateData.bind(this);
    this.getBehaviors = this.getBehaviors.bind(this);
    this.getAttractions = this.getAttractions.bind(this);
    this.getClientCategories = this.getClientCategories.bind(this);
    this.selectBehavior = this.selectBehavior.bind(this);
    this.selectAttraction = this.selectAttraction.bind(this);
  }

  getSchema(){
    return yup.object({
      name: yup.string().required('ФИО - обязательное поле!'),
      phone: yup.string().required('Телефон - обязательное поле!'),
      mail: yup.string().email('Введен невалидный почтовый адрес!'),
      comment: yup.string().nullable().max(1000, 'Комментарий не должен превышать 1000 символов!'),
    });
  }

  show() {
    this.setState({
      show: true,
      numberError: false,
      phoneError: false,
      selectedBehaviors: [],
      selectedAttractions: []
    })
  }

  close() {
    this.setState({show: false});
  }

  selectBehavior(value) {
    this.setState({selectedBehaviors: value});
  }

  selectAttraction(value) {
    this.setState({selectedAttractions: value});
  }

  getBehaviors(){
    return fetch("/getAvailableBehaviors",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({client_id: 0})
      })
      .then(res => res.json())
      .then(
        res => {
          if (res.code === 5){
            this.props.quit();
          }
          else{
            if (res.code === 0){
              this.setState({
                behaviors: []
              });
            }
            else{
              this.setState({
                behaviors: res
              });
            }
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  getAttractions(){
    return fetch("/getAvailableAttractions",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({client_id: 0})
      })
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            if (data.code === 0){
              this.setState({
                attractions: []
              });
            }
            else{
              this.setState({
                attractions: data
              });
            }
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  getClientCategories(){
    return fetch("/getAvailableClientCategories",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({client_id: 0})
      })
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            if (data.code === 0){
              this.setState({
                clientCategories: []
              });
            }
            else{
              this.setState({
                clientCategories: data
              });
            }
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  updateData(){
      this.getBehaviors();
      this.getAttractions();
      this.getClientCategories();
  }

  componentDidMount() {
    this.updateData();
  }

  onSubmit(values){
    let handleToUpdate = this.props.handleToUpdate;
    values.bahaviors = this.state.selectedBehaviors
      .map(behavior => behavior.behavior_id);
    values.attractions = this.state.selectedAttractions
      .map(attraction => attraction.attraction_id);

    fetch("/addClient",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(values)
      })
      .then(res => res.json())
      .then(data => {
        if (data.code === 5){
          this.props.quit();
        }
        else{
          if (data.code === 2){
            this.setState({'numberError': true})
          }
          else{
            if (data.code === 3){
              this.setState({'phoneError': true})
            }
            else{
              this.close();
              handleToUpdate();
            }
          }
        }
      })
      .catch(error => console.error(error))
  }

  render() {
    return (
      <React.Fragment>
        <Button variant='outline-primary' onClick={() => this.show()} block>
          Новый клиент</Button>
        <Modal size="lg" show={this.state.show} onHide={() => this.close()}>
          <Modal.Header closeButton>
            <Modal.Title>Создать клиента</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Formik
              validationSchema={this.getSchema()}
              onSubmit={(values) => {
                  this.onSubmit(values);
                }
              }

              initialValues={{name: '', number: '', mail: '', phone: '', 
                birthdate: '', birthdate2: '', birthdate3: '', 
                mailSend: true, category: 0, comment: ''}}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="clientNumber">
                      <Form.Label column md={4}>Номер карты</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          placeholder="Номер"
                          name="number"
                          value={values.number}
                          onChange={handleChange}
                          isInvalid={!!(errors.number && touched.number)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.number}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="clientName">
                      <Form.Label column md={4}>ФИО</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          placeholder="ФИО"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!(errors.name && touched.name)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="clientPhone">
                      <Form.Label column md={4}>Телефон</Form.Label>
                      <Col md={8}>
                        <Field
                          name="phone"
                          render={({ field }) => (
                            <MaskedInput
                              {...field}
                              mask={phoneNumberMask}
                              id="phone"
                              placeholder="Телефон"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.phone && touched.phone
                                  ? "text-input error"
                                  : "text-input"
                              }
                            />
                          )}
                        />
                      {errors.phone && touched.phone 
                        ? <div class="text-danger">{errors.phone}</div> 
                        : ''}
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="mail">
                      <Form.Label column md={4}>E-mail</Form.Label>
                      <Col md={5}>
                        <Form.Control
                          type="email"
                          placeholder="Почта"
                          name="mail"
                          value={values.mail}
                          onChange={handleChange}
                          isInvalid={!!(errors.mail && touched.mail)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.mail}
                        </Form.Control.Feedback>
                      </Col>

                      <Col md={1}>
                        <Form.Control
                          type="checkbox"
                          name="mailSend"
                          checked={values.mailSend}
                          value={values.mailSend}
                          onChange={handleChange}
                        />
                      </Col>
                      <Form.Label column md={2}>Рассылка</Form.Label>
                    </Form.Group>

                    <Form.Group as={Row} controlId="clientBirthdate">
                      <Form.Label column md={4}>Дата рождения</Form.Label>
                      <Col md={8}>
                        <Field
                          name="birthdate"
                          render={({field}) => (
                            <MaskedInput
                              {...field}
                              mask={dateMask}
                              id="phone"
                              placeholder="Дата"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.birthdate && touched.birthdate
                                  ? "text-input error"
                                  : "text-input"
                              }
                            />
                          )}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="clientBirthdate2">
                      <Form.Label column md={4}>Дата рождения (доп)</Form.Label>
                      <Col md={8}>
                        <Field
                          name="birthdate2"
                          render={({field}) => (
                            <MaskedInput
                              {...field}
                              mask={dateMask}
                              id="phone"
                              placeholder="Дата"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.birthdate2 && touched.birthdate2
                                  ? "text-input error"
                                  : "text-input"
                              }
                            />
                          )}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="clientBirthdate3">
                      <Form.Label column md={4}>Дата рождения (доп)</Form.Label>
                      <Col md={8}>
                        <Field
                          name="birthdate3"
                          render={({field}) => (
                            <MaskedInput
                              {...field}
                              mask={dateMask}
                              id="phone"
                              placeholder="Дата"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.birthdate3 && touched.birthdate3
                                  ? "text-input error"
                                  : "text-input"
                              }
                            />
                          )}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="category">
                      <Form.Label column md={4}>Категория</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          as="select"
                          name="category"
                          value={values.category}
                          onChange={handleChange}
                          valueKey="client_category_id"
                          labelKey="value"
                          isInvalid={!!(errors.category && touched.category)}
                        >
                          <option value='0'>Не указана</option>
                          {
                            Object.keys(this.state.clientCategories).map((key, index) => (
                              <option value={this.state.clientCategories[key].client_category_id}>
                                {this.state.clientCategories[key].value}</option>
                          ))
                        }

                        </Form.Control>

                        <Form.Control.Feedback type="invalid">
                          {errors.category}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="behavior">
                      <Form.Label column md={4}>Модель поведения</Form.Label>
                      <Col md={8}>
                        <Picky
                          value={this.state.selectedBehaviors}
                          multiple={true}
                          options={
                            Object.keys(this.state.behaviors)
                              .map((key, index) =>
                              {
                                let dict = {};
                                dict.behavior_id = this.state
                                  .behaviors[key].behavior_id;
                                let behaviorString = this.state.behaviors
                                  .filter(value => value.behavior_id === this
                                      .state.behaviors[key].behavior_id)
                                  .map(item => item.value)
                                dict.value = behaviorString;
                                return dict;
                              })
                            }
                          valueKey="behavior_id"
                          labelKey="value"
                          includeSelectAll={false}
                          includeFilter={true}
                          onChange={this.selectBehavior}
                          onBlur={handleBlur}
                          dropdownHeight={600}
                          id="behaviors"
                          name="behaviors"
                        />

                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="attraction">
                      <Form.Label column md={4}>Канал привлечения</Form.Label>
                      <Col md={8}>
                        <Picky
                          value={this.state.selectedAttractions}
                          multiple={true}
                          options={
                            Object.keys(this.state.attractions)
                              .map((key, index) =>
                              {
                                let dict = {};
                                dict.attraction_id = this.state.attractions[key].attraction_id;
                                let behaviorString = this.state.attractions
                                  .filter(value => value.attraction_id === this
                                      .state.attractions[key].attraction_id)
                                  .map(item => item.value)
                                dict.value = behaviorString;
                                return dict;
                              })
                            }
                          valueKey="attraction_id"
                          labelKey="value"
                          includeSelectAll={false}
                          includeFilter={true}
                          onChange={this.selectAttraction}
                          onBlur={handleBlur}
                          dropdownHeight={600}
                          id="attractions"
                          name="attractions"
                        />

                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="comment">
                      <Form.Label column md={4}>Коментарий</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type='text'
                          placeholder="Комментарий"
                          name="comment"
                          value={values.comment}
                          onChange={handleChange}
                          isInvalid={!!(errors.comment && touched.comment)}
                          as="textarea" 
                          rows="3"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.comment}
                        </Form.Control.Feedback>
                      </Col>

                      {/* <Form.Label column md={2}>Рассылка</Form.Label> */}
                    </Form.Group>

                    {this.state.numberError 
                      ? <div class="text-danger">
                          Клиент с такой картой уже существует!
                        </div> 
                      : ''}
                    {this.state.phoneError 
                      ? <div class="text-danger">
                          Клиент с таким телефоном уже существует!
                        </div> 
                      : ''}

                    <div class="d-flex flex-row-reverse">
                      <Button type="submit">Создать</Button>
                      <Button variant="secondary" onClick={() => this.close()}>Отмена</Button>
                    </div>
                </Form>
              )}
            </Formik>

          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}

export default AddClientForm;
