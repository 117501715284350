import React from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import AddGamezoneForm from './moderatorAddGamezone';
import UpdateGamezoneForm from './moderatorUpdateGamezone';
import '../../css/bootstrap-4-3-1.css';
import '../../css/styles.css';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

function multilineCell(cell, row) {
    return "<textarea class='form-control cell' rows='4'>" + 
      cell +"</textarea>";
} 

class Gamezones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoaded: false,
        data:[],
        sortName: undefined,
        sortOrder: undefined,
        branches: []
    };
    this.onSortChange = this.onSortChange.bind(this);
    this.branchFormatter = this.branchFormatter.bind(this);
    this.branchStateFormatter = this.branchStateFormatter.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
    this.handleToUpdate = this.handleToUpdate.bind(this);
    this.removeGamezone = this.removeGamezone.bind(this);
  }

  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }

  actionFormatter(cell, row, enumObject) {
    return(
      <React.Fragment>
        <UpdateGamezoneForm 
          handleToUpdate = {this.handleToUpdate} 
          data = {{'gamezone_id': row.gamezone_id, 'name': row.name, 
            'definition': row.definition, 'branch_id': row.branch_id}} 
          quit = {this.props.quit} />
        <Row>
          <Button variant="danger" block 
            onClick={() => this.removeGamezone(row.gamezone_id)}>Удалить</Button>
        </Row>
      </React.Fragment>
    )
  }

  branchFormatter(cell, row, enumObject) {
    return(
      <Row>
        {this.state.branches
          .filter(branch => branch.branch_id === cell)[0].name }
      </Row>
    )
  }

  branchStateFormatter(cell, row, enumObject) {
    return(
      <Row>
        {this.state.branches
          .filter(branch => branch.branch_id === cell)[0].state === 0
            ? <Button variant="success" disabled block>Активен</Button>
            :<Button variant="danger" disabled block>Заблокирован</Button>
      }
      </Row>
    )
  }

  removeGamezone(gamezone_id){
    fetch("/removeGamezone",
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({'gamezone_id': gamezone_id})
    })
    .then(res => res.json())
    .then(data => {
      if (data.code === 5){
        this.props.quit();
      }
      else{
        this.updateData();
      }
    })
    .catch(error => console.error(error))
  }

  onComponentWillMount() {
    this.updateData()
  }

  updateData(){
    fetch("/getBranches")
    .then(res => res.json())
    .then(
      data => {
        if (data.code === 5){
          this.props.quit();
        }
        else{
          this.setState({
            isLoaded: true,
            branches: data
          });
        }
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
    .then(() =>
    fetch("/getGamezones")
      .then(res => res.json())
      .then(
        data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            this.setState({
              isLoaded: true,
              data: data
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
    )
  }

  normalize(inputArray){
    let dict = {};
    inputArray.map(item => dict[item[0]] = item[1]);
    return dict;
  }

  componentDidMount() {
    this.updateData();
  }

  handleToUpdate(){
    this.updateData();
    this.forceUpdate()
  }

  render() {
    const options = {
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      onSortChange: this.onSortChange,
      noDataText: 'Данные не найдены'
    };

    return (
      <React.Fragment>
        <BootstrapTable data={this.state.data} options={options} 
          pagination striped hover>
          <TableHeaderColumn 
            isKey
            dataField='gamezone_id'
            width='50'
            dataAlign ='center'
            dataSort
            headerAlign='center'>
              ID
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='name'
            dataSort
            headerAlign='center'
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите название'}}>
              Название
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='definition'
            width='350'
            headerAlign='center'
            dataAlign='left'
            dataFormat={multilineCell}
            filter={{type: 'TextFilter', delay: 1000,
              placeholder: 'Введите часть описания'} }>
              Описание
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='branch_id' 
            dataFormat={this.branchFormatter} 
            headerAlign='center' 
            dataAlign='center'
            filter={{type: 'SelectFilter', 
              options: this.normalize(this.state.branches
                .map(branch => [branch.branch_id, branch.name])),
              placeholder: 'Выберите филиал'}}>
              Филиал
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='branch_id' 
            width='180' 
            headerAlign='center' 
            dataAlign='center' 
            dataFormat={this.branchStateFormatter}>
              Статус филиала
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='branch_id'
            dataFormat={this.actionFormatter} 
            dataAlign='center'
            width='240'>
              <AddGamezoneForm handleToUpdate = {this.handleToUpdate} 
                quit = {this.props.quit} />
          </TableHeaderColumn>
        </BootstrapTable>
      </React.Fragment>
    )
  }
}

export default Gamezones;
