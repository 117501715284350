import React from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import SertificateCostInfo from './moderatorSertificateCostInfo';
import AddSertificateCostForm from './moderatorAddSertificateCost';
import '../../css/bootstrap-4-3-1.css'
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

let moment = require('moment');
require('moment/locale/ru');

function multilineCell(cell, row) {
    return "<textarea class='form-control cell' rows='2'>" + cell +"</textarea>";
} 

class SertificatesCost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoaded: false,
        data:[],
        sortName: undefined,
        sortOrder: undefined
    };
    this.onSortChange = this.onSortChange.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
    this.handleToUpdate = this.handleToUpdate.bind(this);
    this.updateData = this.updateData.bind(this);
    this.closeSertificateCost = this.closeSertificateCost.bind(this);
  }

  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }

  actionFormatter(cell, row, enumObject) {
    let res = <div></div>;
    if (this.props.role === 1){
      res = <Row>
          <Button variant="danger" 
            onClick={() => this.closeSertificateCost(row.sertificate_cost_id)} block >
              Аннулировать</Button>
        </Row>
    }
    return(
      <React.Fragment>
        <SertificateCostInfo data = {row} />
        {res}
      </React.Fragment>
    )
  }

  closeSertificateCost(sertificate_id){
    fetch("/closeSertificateCost",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({'sertificate_cost_id': sertificate_id})
      })
      .then(data => {
        if (data.code === 5){
          this.props.quit();
        }
        else{
          this.handleToUpdate();
        }
      })
      .catch(error => console.error(error))
  }

  dateFormatter(cell, row, enumObject) {
    return(
      <React.Fragment>
        {moment(cell).format("DD.MM.YYYY")}
      </React.Fragment>
    )
  }

  costFormatter(cell, row, enumObject) {
    return cell + ' руб.'
  }

  balanceFormatter(cell, row, enumObject) {
    return (parseInt(row['cost']) - parseInt(row['spent'])) + ' руб.'
  }

  stateFormatter(cell, row, enumObject) {
    if (row.state === 0){
      return(
        <Button variant="primary" disabled block>
          Открыт</Button>
      )
    }
    if (row.state === 1){
      return(
        <Button variant="danger" disabled block>
          Аннулирован</Button>
      )
    }
  }

  onComponentWillMount() {
    this.updateData()
  }

  updateData(){
    fetch("/getSertificatesCost")
      .then(res => res.json())
      .then(
        data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            this.setState({
              isLoaded: true,
              data: data
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  componentDidMount() {
    this.updateData();
  }

  handleToUpdate(){
    this.updateData();
    this.forceUpdate()
  }

  render() {
    const options = {
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      onSortChange: this.onSortChange,
      noDataText: 'Данные не найдены'
    };

    return (
      <React.Fragment>
        <BootstrapTable data={this.state.data} options={options} pagination striped hover>
          <TableHeaderColumn 
            isKey
            dataField='number'
            dataAlign ='center'
            dataSort
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите №'}}
            headerAlign='center'>
              Номер
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='openDate'
            dataAlign='center'
            dataSort
            width='220'
            dataFormat={this.dateFormatter}
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите дату'}}>
              Дата выдачи
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='cost'
            dataSort
            headerAlign='center'
            width='200'
            dataAlign='center'
            dataFormat={this.costFormatter}
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите номинал'}}>
              Номинал
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='cost'
            dataSort
            headerAlign='center'
            width='200'
            dataAlign='center'
            dataFormat={this.balanceFormatter}
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите номинал'}}>
              Остаток
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='state'
            width='180'
            headerAlign='center'
            dataFormat={this.stateFormatter}
            filter={{type: 'SelectFilter', placeholder: 'Выберите',
              options: {0: 'Открыт', 1: 'Аннулирован'}}}>
            Состояние
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='sertificate_id'
            dataFormat={this.actionFormatter}
            dataAlign='center'
            block
            width='200'>
              <AddSertificateCostForm handleToUpdate = {this.handleToUpdate} 
                quit = {this.props.quit} />
          </TableHeaderColumn>

          <TableHeaderColumn dataField='openUser' hidden></TableHeaderColumn>
          <TableHeaderColumn dataField='id' hidden></TableHeaderColumn>
          <TableHeaderColumn dataField='closeUser' hidden></TableHeaderColumn>
          <TableHeaderColumn dataField='closeDate' hidden></TableHeaderColumn>
          <TableHeaderColumn dataField='clients' hidden></TableHeaderColumn>
          <TableHeaderColumn dataField='clientName' hidden></TableHeaderColumn>
        </BootstrapTable>
      </React.Fragment>
    )
  }
}

export default SertificatesCost;
