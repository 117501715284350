import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import history from '../../common/history';
import * as yup from 'yup';
import { Formik } from 'formik';
import '../../css/bootstrap-4-3-1.css';
import '../../css/styles.css';

class UpdateModeratorForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      userNameError: false,
      userQuotaError: false
    }
    this.show = this.show.bind(this);
  }

  getSchema(){
    return yup.object({
      name: yup.string().required('Имя пользователя обязательное поле!')
        .max(20, 'Имя не должно превышать 20 символов!'),
      mail: yup.string().email('Введен невалидный почтовый адрес!')
        .required('Почтовый адрес обязательное поле!'),
      organization_id: yup.string()
        .test('is-selected', 'Укажите организацию!', value => 
          this.props
            .organizations
            .map(organization => organization.organization_id)
            .includes(parseInt(value, 10))
        ),
      password: yup.string().required('Придумайте пароль!')
        .max(20, 'Пароль не должен превышать 20 символов!')
        .min(6, 'Пароль должен быть не менее 6 символов!'),
    });
  }

  show(){
    this.setState({
      show: true,
      userNameError: false,
      userQuotaError: false
    });
  }

  close() {
    this.setState({show: false});
  }

  onSubmit(values){
    let handleToUpdate = this.props.handleToUpdate;
    values.user_id = this.props.data.user_id;
    values.user_role = 1;
    fetch("/checkUser",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(values)
      })
      .then(res => res.json())
      .then((data) => {
        if (data.code === 0){
          fetch("/updateUser",
            {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              method: 'POST',
              body: JSON.stringify(values)
            })
            .then(data => {
              this.close();
              handleToUpdate();
            })
            .catch(error => console.error(error))
        }
        else{
          if (data.code === 1){
            this.setState({userNameError: true});
          }
          if (data.code === 5){
                history.push('/login')
          }
          if (data.code === 2){
            this.setState({userQuotaError: true}); 
          }
        }
      })
      .catch(error => console.error(error))
  }

  render() {
    return (
      <React.Fragment>
        <Button variant='warning' onClick={ () => this.show() }>
          Изменить
        </Button>
        <Modal size="lg" show={this.state.show} onHide={() => this.close()}>
          <Modal.Header closeButton>
            <Modal.Title>Обновление информации о пользователя</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Formik
              validationSchema={this.getSchema()}
              onSubmit={(values) => {
                  this.onSubmit(values);
                }
              }

              initialValues={{
                name: this.props.data.name, 
                mail: this.props.data.mail, 
                password: this.props.data.password, 
                organization_id: this.props.data.organization_id}}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="name">
                      <Form.Label column md={4}>Имя пользователя</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          placeholder="Имя"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!(errors.name && touched.name)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="mail">
                      <Form.Label column md={4}>E-mail</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type="email"
                          placeholder="Почта"
                          name="mail"
                          value={values.mail}
                          onChange={handleChange}
                          isInvalid={!!(errors.mail && touched.mail)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.mail}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="organization_id">
                      <Form.Label column md={4}>Организация</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          as="select"
                          name="organization_id"
                          value={values.organization_id}
                          onChange={handleChange}
                          isInvalid={!!(errors.organization_id && 
                            touched.organization_id)}
                        >
                          <option>Выберите организацию</option>
                          {
                            Object.keys(this.props.organizations)
                              .map((key, index) => (
                                <option value={
                                  this.props.organizations[key].organization_id
                                }>
                                  {this.props.organizations[key].name}
                                </option>
                          ))
                        }
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                         {errors.organization_id}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="password">
                      <Form.Label column md={4}>Пароль</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type="password"
                          placeholder="Пароль"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!(errors.password && touched.password)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                        {this.state.userNameError 
                          ? <div class="text-danger">
                              Пользователь с таким именем уже существует!
                            </div> 
                          : ''}
                        {this.state.userQuotaError 
                          ? <div class="text-danger">
                              Превышена квота модераторов организации!
                            </div>
                          : ''}
                      </Col>
                    </Form.Group>

                    <div class="d-flex flex-row-reverse">
                      <Button type="submit">Сохранить</Button>
                      <Button variant="secondary" onClick={() => this.close()}>
                        Отмена
                      </Button>
                    </div>
                </Form>
              )}
            </Formik>

          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}

export default UpdateModeratorForm;
