import React from 'react';
import Button from 'react-bootstrap/Button';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import AddClientCategoryForm from './moderatorAddClientCategory';
import '../../css/bootstrap-4-3-1.css';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

class ClientCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoaded: false,
        data:[],
        sortName: undefined,
        sortOrder: undefined,
    };
    this.onSortChange = this.onSortChange.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
    this.handleToUpdate = this.handleToUpdate.bind(this);
    this.updateData = this.updateData.bind(this);
    this.deleteClientCategory = this.deleteClientCategory.bind(this);
  }

  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }

  actionFormatter(cell, row, enumObject) {
    return(
      <Button variant="danger" onClick={() => 
        this.deleteClientCategory(row.client_category_id)} block>Удалить</Button>
    )
  }

  deleteClientCategory(client_category_id){
    fetch("/removeClientCategory",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({'client_category_id': client_category_id})
      })
      .then(data => {
        if (data.code === 5){
          this.props.quit()
        }
        else{
          this.handleToUpdate();
        }
      })
      .catch(error => console.error(error))
  }

  onComponentWillMount() {
    this.updateData()
  }

  updateData(){
    fetch("/getClientCategories")
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit()
          }
          else{
            this.setState({
              isLoaded: true,
              data: data
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  componentDidMount() {
    this.updateData();
  }

  handleToUpdate(){
    this.updateData();
    this.forceUpdate()
  }

  render() {
    const options = {
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      onSortChange: this.onSortChange,
      noDataText: 'Данные не найдены'
    };

    return (
      <React.Fragment>
        <BootstrapTable data={this.state.data} options={options} striped hover >
          <TableHeaderColumn 
            isKey
            dataField='client_category_id'
            width='50'
            hidden
            dataSort
            headerAlign='center'>
              ID
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='value'
            dataSort
            headerAlign='center'
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите канал привлечения'} }>
              Канал привлечения
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='client_category_id' 
            dataFormat={this.actionFormatter} 
            dataAlign='center' 
            width='280'>
              <AddClientCategoryForm handleToUpdate = {this.handleToUpdate} 
                quit = {this.props.quit} />
          </TableHeaderColumn>
        </BootstrapTable>
      </React.Fragment>
    )
  }
}

export default ClientCategories;
