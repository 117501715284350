import React from 'react';
import Button from 'react-bootstrap/Button';
import Datetime from 'react-datetime';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import AddVisitForm from './moderatorAddVisit';
import AddVisitUserForm from '../../userPage/userAddVisit';
import VisitInfo from './moderatorVisitInfo';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import '../../css/bootstrap-4-3-1.css';
import '../../css/styles.css';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'react-datetime/css/react-datetime.css';

let moment = require('moment');
require('moment/locale/ru');

class Visits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoaded: false,
        data:[],
        sortName: undefined,
        sortOrder: undefined,
        branches: [],
        games: [],
        gamezones: [],
        startDate: moment().startOf('day').format("DD.MM.YYYY"),
        endDate: moment().endOf('day').format("DD.MM.YYYY")
    };
    this.onSortChange = this.onSortChange.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
    this.branchFormatter = this.branchFormatter.bind(this);
    this.handleToUpdate = this.handleToUpdate.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.getBranches = this.getBranches.bind(this);
    this.getGamezones = this.getGamezones.bind(this);
    this.getGames = this.getGames.bind(this);
    this.updateData = this.updateData.bind(this);
    this.removeVisit = this.removeVisit.bind(this);
  }

  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }

  actionFormatter(cell, row, enumObject) {
    let res = <div></div>;
    if (this.props.role === 1){
      res = <Row>
          <Button variant="danger" 
            onClick={() => this.removeVisit(row.visit_id)} block>Удалить</Button>
        </Row>
    }
    return(
      <React.Fragment>
        <VisitInfo data = {row} />
        {res}
      </React.Fragment>
    )
  }

  costFormatter(cell, row, enumObject) {
    return(
      <React.Fragment>
        {cell} руб.        
      </React.Fragment>
    )
  }

  dateFormatter(cell, row, enumObject) {
    return(
      <React.Fragment>
        {moment(cell).format("DD.MM.YYYY HH:mm")}
      </React.Fragment>
    )
  }

  branchFormatter(cell, row, enumObject) {
    let res = ''
    try{
      res = this.state.branches
          .filter(branch => branch.branch_id === cell)[0].name
    }
    catch(err){
      console.log(err)
    }
    return(
      <Row>
        {res}
      </Row>
    )
  }

  removeVisit(visit_id){
    fetch("/removeVisit",
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({'visit_id': visit_id})
    })
    .then(res => res.json())
    .then(data => {
      if (data.code === 5){
        this.props.quit();
      }
      else{
        this.updateData();
      }
    })
    .catch(error => console.error(error))
  }

  getBranches(){
    fetch("/getAvailableBranches")
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            this.setState({
              branches: data
            });
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  getGamezones(){
    fetch("/getGamezones")
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            this.setState({
              gamezones: data
            });
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  getGames(){
    fetch("/getGames")
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            this.setState({
              games: data
            });
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  updateData(){
    Promise.all([
        this.getBranches(),
        this.getGamezones(),
        this.getGames()
      ])
    .then(() =>
      fetch("/getVisits",
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({startDate: moment(this.state.startDate, "DD.MM.YYYY")
            .format("YYYY.MM.DD"), 
            endDate: moment(this.state.endDate, "DD.MM.YYYY").format("YYYY.MM.DD")})
        })
        .then(res => res.json())
        .then(data => {
            if (data.code === 5){
              this.props.quit();
            }
            else{
              this.setState({
                isLoaded: true,
                data: data
              });
            }
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
      )
  }

  componentDidMount() {
    this.updateData();
  }

  handleToUpdate(){
    this.updateData();
    this.forceUpdate()
  }

  handleStartDateChange(value){
    let d = moment(value, "DD.MM.YYYY", true);
    if (isNaN(d.year())){
      return
    }
    this.setState({startDate: d})
    this.updateData();
  }

  handleEndDateChange(value){
    let d = moment(value, "DD.MM.YYYY", true);
    if (isNaN(d.year())){
      return
    }
    this.setState({endDate: d})
    this.updateData();
  }

  normalize(inputArray){
    let dict = {};
    inputArray.map(item => dict[item[0]] = item[1]);
    return dict;
  }

  render() {
    const options = {
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      onSortChange: this.onSortChange,
      noDataText: 'Данные не найдены'
    };
    
    let addVisit = <AddVisitForm 
      branches = {this.state.branches} 
      gamezones = {this.state.gamezones} 
      handleToUpdate = {this.handleToUpdate}
      games = {this.state.games} 
      client_id = {0} 
      role = {this.props.role} 
      branch_id = {0} 
      quit = {this.props.quit} />
    if (this.props.user_role === 2){
      addVisit = <AddVisitUserForm 
        branches = {this.state.branches} 
        gamezones = {this.state.gamezones} 
        handleToUpdate = {this.handleToUpdate}
        games = {this.state.games} 
        client_id = {0} 
        role = {this.props.role} 
        branch_id = {this.props.branch_id} 
        quit = {this.props.quit} />      
    }

    return (
      <React.Fragment>
        <Row>
          <div class="col-md-5 row">
            <Col md={5}>Дата начала:</Col>
            <Col md={5}>
              <Datetime
                locale="ru"
                dateFormat="DD.MM.YYYY"
                defaultHour={14}
                timeFormat={ false }
                value={this.state.startDate}
                onChange={this.handleStartDateChange}
              />
            </Col>
          </div>

          <div class="col-md-5 row">
            <Col md={5}>Дата окончания:</Col>
            <Col md={5}>
              <Datetime
                locale="ru"
                dateFormat="DD.MM.YYYY"
                defaultHour={14}
                timeFormat={ false }
                value={this.state.endDate}
                onChange={this.handleEndDateChange}
              />
            </Col>
          </div>
        </Row>

        <BootstrapTable data={this.state.data} options={options} 
          pagination striped hover>
          <TableHeaderColumn 
            dataField='date'
            dataAlign ='center'
            dataFormat={ this.dateFormatter }
            dataSort
            headerAlign='center'>
              Дата
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='client'
            dataSort
            headerAlign='center'
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите имя'}}>
              Клиент
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='branch_id'
            headerAlign='center'
            dataAlign='left'
            dataFormat={this.branchFormatter}
            filter={{type: 'SelectFilter', 
              options: this.normalize(this.state.branches
                .map(branch => [branch.branch_id, branch.name])),
              placeholder: 'Выберите филиал'}}>
              Филиал
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='cost'
            headerAlign='center'
            dataAlign='center'
            dataFormat={this.costFormatter}>
              Стоимость
          </TableHeaderColumn>
          <TableHeaderColumn 
            isKey
            dataField='visit_id'
            dataFormat={this.actionFormatter}
            dataAlign='center'
            width='280'>
              {addVisit}
          </TableHeaderColumn>

          <TableHeaderColumn dataField='branch_name' hidden>
            </TableHeaderColumn>
          <TableHeaderColumn dataField='sertificate_number' hidden>
            </TableHeaderColumn>
          <TableHeaderColumn dataField='visit_rent' hidden>
            </TableHeaderColumn>
          <TableHeaderColumn dataField='visit_celebration' hidden>
            </TableHeaderColumn>
          <TableHeaderColumn dataField='client_quontity' hidden>
            </TableHeaderColumn>
          <TableHeaderColumn dataField='new_clients' hidden>
            </TableHeaderColumn>
          <TableHeaderColumn dataField='visit_gamezones' hidden>
            </TableHeaderColumn>
          <TableHeaderColumn dataField='visit_games' hidden>
            </TableHeaderColumn>
          <TableHeaderColumn dataField='cash' hidden>
            </TableHeaderColumn>
        </BootstrapTable>
      </React.Fragment>
    )
  }
}

export default Visits;
