import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ReactStars from "react-rating-stars-component";
import Picky from 'react-picky';
import * as yup from 'yup'
import { Formik } from 'formik';
import '../../css/bootstrap-4-3-1.css'
import 'react-picky/dist/picky.css';
import '../../css/styles.css'

class UpdateGameForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      branches: [],
      branchEmptiness: false,
      difficulty: 0,
      branchVisible: false,
      branchError: false,
      selectedBranches: []
    }
    this.show = this.show.bind(this);
    this.updateData = this.updateData.bind(this);
    this.ratingChanged = this.ratingChanged.bind(this);
    this.selectBranch = this.selectBranch.bind(this);
  }

  componentDidMount() {
    this.updateData();
  }
  
  getSchema(){
    return yup.object({
      name: yup.string().required('Название игры - обязательное поле!'),
    });
  }

  ratingChanged(newRating){
    this.setState({'difficulty': newRating})
  };

  show() {
    this.setState({'difficylty': 0, 'branchVisible': false})
    Promise.all([
      this.updateData()
    ])
    .then(res => {
      if (this.props.data.type === 1){
        let branches = [];
        try{
          this.props.data.branches
            .map(item => branches.push(
              {branch_id: item, name: this.state.branches
                .filter(branch => branch.branch_id === item)[0].name}
          ));
        }
        catch(err){
          console.log(err)
        }
        this.setState({
          'branchVisible': true, 
          'selectedBranches': branches
        })
      }

      this.setState({
        show: true,
        difficulty: this.props.data.difficulty
      })
    })
  }

  close() {
    this.setState({show: false});
  }

  selectBranch(value) {
    this.setState({selectedBranches: value});
  }

  updateData(){
    fetch("/getAvailableBranches")
      .then(res => res.json())
      .then(
        data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            if (data.code === 7){
             this.setState({'branchEmptiness': true}) 
            }
            else
            {
              this.setState({
                isLoaded: true,
                branches: data
              });
            }
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  onSubmit(values){
    let handleToUpdate = this.props.handleToUpdate;
    values.game_id = this.props.data.game_id;
    values.difficulty = this.state.difficulty;
    values.branches = this.state.selectedBranches
      .map(branch => branch.branch_id);
    fetch("/updateGame",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(values)
      })
      .then(res => res.json())
      .then(data => {
        if (data.code === 5){
          this.props.quit();
        }
        else{
          this.close();
          handleToUpdate();
        }
      })
      .catch(error => console.error(error))
  }

  render() {
    return (
      <Row>
        <Button variant='warning' onClick={() => this.show()} block>
          Изменить</Button>
        <Modal size="lg" show={this.state.show} onHide={() => this.close()}>
          <Modal.Header closeButton>
            <Modal.Title>Обновление информации об игре</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Formik
              validationSchema={this.getSchema()}
              onSubmit={(values) => {
                  this.onSubmit(values);
                }
              }
              initialValues={{name: this.props.data.name, 
                definition: this.props.data.definition, 
                type: this.props.data.type}}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="gameName">
                      <Form.Label column md={4}>Название</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          placeholder="Название"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!(errors.name && touched.name)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="gameDefinition">
                      <Form.Label column md={4}>Описание</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          as="textarea" rows="5"
                          placeholder="Описание"
                          name="definition"
                          value={values.definition}
                          onChange={handleChange}
                          isInvalid={!!(errors.definition && touched.definition)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.definition}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="gameDifficylty">
                      <Form.Label column md={4}>Сложность</Form.Label>
                      <Col md={8}>
                        <ReactStars
                          value={this.state.difficulty}
                          count={3}
                          half={false}
                          size={30}
                          color2={"#ffd700"}
                          onChange={this.ratingChanged}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="type">
                      <Form.Label column md={4}>Тип собственности</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          as="select"
                          name="type"
                          value={values.type}
                          onChange={(event) => {
                            let parsed = parseInt(event.target.value);
                            if (parsed === 0){
                              this.setState({'branchVisible': false})
                              this.setState({'branchError': false})
                            }
                            else{
                              this.setState({'branchVisible': true})
                              if (this.state.branchEmptiness){
                                this.setState({'branchError': true})
                              }
                            }
                            handleChange(event);
                          }}
                        >
                          <option value='0'>Для всех филиалов</option>
                          <option value='1'>Персональная</option>

                        </Form.Control>
                      </Col>
                    </Form.Group>

                    {this.state.branchVisible 
                      ? <Form.Group as={Row} controlId="branches">
                          <Form.Label column md={4}>Филиалы</Form.Label>
                          <Col md={8}>
                            <Picky
                              value={this.state.selectedBranches}
                              multiple={true}
                              options={
                                Object.keys(this.state.branches).map((key, index) =>
                                {
                                  let dict = {};
                                  dict.branch_id = this.state
                                    .branches[key].branch_id;
                                  let branchString = this.state.branches
                                    .filter(value => value.branch_id === this.state
                                      .branches[key].branch_id)
                                    .map(item => item.name)
                                  dict.name = branchString;
                                  return dict;
                                })
                              }
                              valueKey="branch_id"
                              labelKey="name"
                              includeSelectAll={false}
                              includeFilter={true}
                              onChange={this.selectBranch}
                              onBlur={handleBlur}
                              dropdownHeight={600}
                              id="branches"
                              name="branches"
                            />

                          </Col>
                        </Form.Group>
                      :''}

                    {this.state.branchError 
                      ? <div class="text-danger">
                          Для создания персональных игр необходимо наличие филиалов!
                        </div> 
                      : ''}

                    <div class="d-flex flex-row-reverse">
                      <Button type="submit">Сохранить</Button>
                      <Button variant="secondary" 
                        onClick={() => this.close()}>Отмена</Button>
                    </div>
                </Form>
              )}
            </Formik>

          </Modal.Body>
        </Modal>
      </Row>
    )
  }
}

export default UpdateGameForm;
