import React from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import Row from 'react-bootstrap/Row';
import AddVisitForm from '../moderatorVisits/moderatorAddVisit';
import AddVisitUserForm from '../../userPage/userAddVisit';
import AddClientForm from './moderatorAddClient';
import AddPrecostForm from './moderatorAddPrecost';
import UpdateClientForm from './moderatorUpdateClient';
import RemoveClientForm from './moderatorRemoveClient';
import '../../css/bootstrap-4-3-1.css';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

function multilineCell(cell, row) {
    return "<textarea class='form-control cell' rows='4'>" + (cell ? cell : '-') + "</textarea>";
} 

class Clients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoaded: false,
        data:[],
        branches: [],
        gamezones: [],
        games: [],
        categories: [],
        behaviors: [],
        sortName: undefined,
        sortOrder: undefined
    };
    this.onSortChange = this.onSortChange.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
    this.categoryFormatter = this.categoryFormatter.bind(this);
    this.phoneFormatter = this.phoneFormatter.bind(this);
    this.behaviorFormatter = this.behaviorFormatter.bind(this);
    this.handleToUpdate = this.handleToUpdate.bind(this);
    this.updateData = this.updateData.bind(this);
    this.getBranches = this.getBranches.bind(this);
    this.getGamezones = this.getGamezones.bind(this);
    this.getGames = this.getGames.bind(this);
    this.getBehaviors = this.getBehaviors.bind(this);
    this.getCategories = this.getCategories.bind(this);
  }

  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }

  actionFormatter(cell, row, enumObject) {
    let addVisit = <AddVisitForm 
      branches = {this.state.branches} 
      gamezones = {this.state.gamezones} 
      quit = {this.props.quit}
      games = {this.state.games} 
      client_id = {row.client_id} 
      role = {this.props.role} 
      branch_id = {this.props.branch_id} 
      precost = {row.precost}
      handleToUpdate = {this.handleToUpdate} />
    let removeClient = <RemoveClientForm 
      client_id = {row.client_id} 
      client_name = {row.name} 
      quit = {this.props.quit} 
      handleToUpdate = {this.handleToUpdate} />
    if (this.props.role === 2){
      addVisit = <AddVisitUserForm 
        branches = {this.state.branches} 
        gamezones = {this.state.gamezones} 
        quit = {this.props.quit}
        games = {this.state.games} 
        client_id = {row.client_id} 
        role = {this.props.role} 
        branch_id = {this.props.branch_id} 
        precost = {row.precost} 
        handleToUpdate = {this.handleToUpdate} />
      removeClient = ''
    }
    return(
      <React.Fragment>
        <UpdateClientForm 
          handleToUpdate = {this.handleToUpdate} 
          quit = {this.props.quit}
          data={{
            'client_id': row.client_id, 
            'name': row.name, 
            'number': row.number,
            'mail': row.email, 
            'phone': row.phone, 
            'birthdate': row.birthdate,
            'birthdate2': row.birthdate2, 
            'birthdate3': row.birthdate3,
            'client_category_id': row.client_category_id, 
            'mailSend': row.mailSend,
            'attractions': row.attractions, 
            'behaviors': row.behaviors,
            'comment': row.comment,
        }} />

        <Row>
          {addVisit}
        </Row>
        <Row>
          <AddPrecostForm 
            client_id = {row.client_id} 
            quit = {this.props.quit} 
            handleToUpdate = {this.handleToUpdate} />
        </Row>
        <Row>
          {removeClient}
        </Row>
      </React.Fragment>
    )
  }

  saleFormatter(cell, row, enumObject) {
    if (cell === '0'){
      return '-'
    }
    return(
      <React.Fragment>
        {cell}%
      </React.Fragment>
    )
  }

  categoryFormatter(cell, row, enumObject) {
    let temp = ''
    if (cell === null){
      return ""
    }
    try{
      temp = this.state.categories
        .filter(category => category.client_category_id === row.category_id)[0].value
    }
    catch{
      return ""
    }
    return(
      <Row>
        {temp}
      </Row>
    )
  }

  behaviorFormatter(cell, row, enumObject) {
    let value = "";
    cell.forEach(behavior_id => {
        value = value + this.state.behaviors
          .filter(behavior => behavior.behavior_id === behavior_id)[0].value +
            "&#13;&#10;";
    });
    return(multilineCell(value, row));
  }

  phoneFormatter(cell, row, enumObject) {
    let precostString = '';
    if (row.precost > 0){
      precostString = <div class='text-success'>Предоплата: {row.precost}р.</div>
    }
    return(
      <React.Fragment>
        {cell}{precostString}
      </React.Fragment>
    )
  }

  getBehaviors(){
    return fetch("/getBehaviors",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'GET',
      })
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            if (data.code === 0){
              this.setState({
                behaviors: []
              });
            }
            else{
              this.setState({
                behaviors: data
              });
            }
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  getCategories(){
    return fetch("/getClientCategories",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'GET',
        body: JSON.stringify({client_id: 0})
      })
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            if (data.code === 0){
              this.setState({
                categories: []
              });
            }
            else{
              this.setState({
                categories: data
              });
            }
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  onComponentWillMount() {
    this.updateData()
  }

  updateData(){
    Promise.all([
        this.getBranches(),
        this.getGamezones(),
        this.getGames(),
        this.getCategories(),
        this.getBehaviors()
      ])
    .then(
      fetch("/getClients")
        .then(res => res.json())
        .then(
          data => {
            if (data.code === 5){
              this.props.quit();
            }
            else{
              this.setState({
                isLoaded: true,
                data: data
              });
            }
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
      )
  }

  getBranches(){
    fetch("/getAvailableBranches")
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            this.setState({
              branches: data
            });
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  getGamezones(){
    fetch("/getGamezones")
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            this.setState({
              gamezones: data
            });
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  getGames(){
    fetch("/getGames")
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            this.setState({
              games: data
            });
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  componentDidMount() {
    this.updateData();
  }

  handleToUpdate(){
    this.updateData();
    this.forceUpdate()
  }

  normalize(inputArray){
    let dict = {};
    inputArray.map(item => dict[item[0]] = item[1]);
    return dict;
  }

  render() {
    const options = {
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      onSortChange: this.onSortChange,
      noDataText: 'Данные не найдены'
    };

    return (
      <React.Fragment>
        <BootstrapTable data={this.state.data} options={options} 
          pagination striped hover>
          <TableHeaderColumn 
            isKey 
            dataField='number' 
            width='140'
            dataAlign ='center'
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите №'}}
            dataSort
            headerAlign='center'>
              Карта
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='name' 
            dataSort 
            headerAlign='center'
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите имя'}}>
              ФИО
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='phone' 
            width='200' 
            headerAlign='center'
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите телефон'}}
            dataFormat={this.phoneFormatter}>
              Телефон
            </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='comment' 
            dataAlign='left'
            headerAlign='center'
            dataFormat={multilineCell}
          >
              Комментарий
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='behaviors'
            dataAlign='center'
            dataFormat={this.behaviorFormatter}
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите текст'}}>
            Модель поведения
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='sale' 
            width='110' 
            dataFormat={this.saleFormatter} 
            dataSort dataAlign='center'>
              Скидка
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='client_id' 
            dataFormat={ this.actionFormatter } 
            dataAlign='center' 
            block>
              <AddClientForm 
                handleToUpdate = {this.handleToUpdate} 
                quit = {this.props.quit} />
          </TableHeaderColumn>

          <TableHeaderColumn dataField='email' hidden></TableHeaderColumn>
          <TableHeaderColumn dataField='mailSend' hidden></TableHeaderColumn>
          <TableHeaderColumn dataField='birthdate2' hidden></TableHeaderColumn>
          <TableHeaderColumn dataField='birthdate3' hidden></TableHeaderColumn>
          <TableHeaderColumn dataField='attractions' hidden></TableHeaderColumn>
          <TableHeaderColumn dataField='behaviors' hidden></TableHeaderColumn>
          <TableHeaderColumn dataField='category' hidden></TableHeaderColumn>
          <TableHeaderColumn dataField='precost' hidden></TableHeaderColumn>
        </BootstrapTable>
      </React.Fragment>
    )
  }
}

export default Clients;
