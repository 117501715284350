import React from 'react';
import Datetime from 'react-datetime';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import AddTransactionForm from './moderatorAddTransaction';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import '../../css/bootstrap-4-3-1.css';
import '../../css/styles.css';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'react-datetime/css/react-datetime.css';

let moment = require('moment');
require('moment/locale/ru');

function multilineCell(cell, row) {
    return "<textarea class='form-control cell' rows='2'>" + cell +"</textarea>";
}

class Transactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoaded: false,
        data:[],
        sortName: undefined,
        sortOrder: undefined,
        branches: [],
        users: [],
        transaction_types: [],
        startDate: moment().startOf('day').format("DD.MM.YYYY"),
        endDate: moment().endOf('day').format("DD.MM.YYYY"),
        branch_id: '0',
        user_id: '0',
        inputCash: 0,
        inputNotCash: 0,
        outputCash: 0,
        outputNotCash: 0,
        totalCost: 0
    };
    this.totalCost = 0;
    this.onSortChange = this.onSortChange.bind(this);
    this.branchFormatter = this.branchFormatter.bind(this);
    this.sumFormatter = this.sumFormatter.bind(this);
    this.handleToUpdate = this.handleToUpdate.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.updateData = this.updateData.bind(this);
    this.branchChange = this.branchChange.bind(this);
    this.userChange = this.userChange.bind(this);
    this.getTransactionTypes = this.getTransactionTypes.bind(this);
  }

  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }

  branchChange(value){
    this.setState({'branch_id': value.target.value}, () => this.updateData())
  }

  userChange(value){
    this.setState({'user_id': value.target.value}, () => this.updateData());
  }

  dateFormatter(cell, row, enumObject) {
    return(
      <React.Fragment>
        {moment(cell).format("DD.MM.YYYY")}
      </React.Fragment>
    )
  }

  cashFormatter(cell, row, enumObject) {
    if (row.cash === 0){
      return(
        "Нет"
      )
    }
    return (
      "Да"
    )
  }

  sumFormatter(cell, row, enumObject) {
    let res = `<div class="text-success">+` + cell + ` руб.</div>`;
    if (row.type === 0){
      res = `<div class="text-danger">-` + cell + ` руб.</div>`;
      this.totalCost = this.totalCost - parseInt(cell)
    }
    else{
      this.totalCost = this.totalCost + parseInt(cell);
    }
    return(
      <div
        dangerouslySetInnerHTML={{
          __html: res
        }}></div>
    )
  }

  totalFormatter(cell, row, enumObject){
    if (cell < 0){
      return (
        <div class="text-danger">{cell}</div>
      )
    }
    return (
        <div class="text-success">{cell}</div>
    )
  }

  descriptionFormatter(cell, row, enumObject) {
    let value = cell;
    if (row.type === 3){
      value = 'Покупка сертификата №' + cell
    }
    if (row.type === 2){
      value = 'Посещение клиентом'
    }
    return(multilineCell(value, row));
  }

  getBranches(){
    return fetch("/getBranches")
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            this.setState({
              branches: data
            });
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  getUsers(){
    return fetch("/getUsers")
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            this.setState({
              users: data
            });
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  getTransactionTypes(){
    return fetch("/getTransactionTypes")
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            this.setState({
              transaction_types: data
            });
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  branchFormatter(cell, row, enumObject) {
    if (cell === null || cell === 0){
      return ''
    }
    return(
      <Row>
        {this.state.branches
          .filter(branch => branch.branch_id === cell)[0].name}
      </Row>
    )
  }

  updateData(){
    let inputCash = 0 , inputNotCash = 0, outputCash = 0, outputNotCash = 0;

    Promise.all([
      this.getBranches(),
      this.getUsers(),
      this.getTransactionTypes()
    ])
      .then(
        fetch("/getTransactions",
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({startDate: moment(this.state.startDate, "DD.MM.YYYY")
                .format("YYYY.MM.DD"), 
              endDate: moment(this.state.endDate, "DD.MM.YYYY").format("YYYY.MM.DD"),
              user: this.state.user_id, branch_id: this.state.branch_id
            })
          })
          .then(res => res.json())
          .then(data => {
              if (data.code === 5){
                this.props.quit();
              }
              else{
                data.forEach(item => {
                  if (item.type > 0 && item.cash === 1){
                    inputCash += item.cost
                  }
                  if (item.type > 0 && item.cash === 0){
                    inputNotCash += item.cost
                  }
                  if (item.type === 0 && item.cash === 1){
                    outputCash += item.cost
                  }
                  if (item.type === 0 && item.cash === 0){
                    outputNotCash += item.cost
                  }
                })
                this.setState({
                  isLoaded: true,
                  data: data,
                  inputCash: inputCash,
                  inputNotCash: inputNotCash,
                  outputCash: outputCash,
                  outputNotCash: outputNotCash,
                });
              }
            },
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
          )
    )
  }

  componentDidMount() {
    this.updateData();
  }

  handleToUpdate(){
    this.updateData();
    this.forceUpdate()
  }

  handleStartDateChange(value){
    let d = moment(value, "DD.MM.YYYY", true);
    if (isNaN(d.year())){
      return
    }
    this.setState({startDate: d}, () => this.updateData());
  }

  handleEndDateChange(value){
    let d = moment(value, "DD.MM.YYYY", true);
    if (isNaN(d.year())){
      return
    }
    this.setState({endDate: d}, () => this.updateData());
  }

  normalize(inputArray){
    let dict = {};
    inputArray.map(item => dict[item[0]] = item[1]);
    return dict;
  }

  render() {
    const options = {
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      onSortChange: this.onSortChange,
      noDataText: 'Данные не найдены'
    };

    return (
      <React.Fragment>
        <Row>
          <div class="col-md-5 row">
            <Col md={5}>Дата начала:</Col>
            <Col md={5}>
              <Datetime
                locale="ru"
                dateFormat="DD.MM.YYYY"
                defaultHour={14}
                timeFormat={ false }
                value={this.state.startDate}
                onChange={this.handleStartDateChange}
              />
            </Col>
          </div>

          <div class="col-md-5 row">
            <Col md={5}>Дата окончания:</Col>
            <Col md={5}>
              <Datetime
                locale="ru"
                dateFormat="DD.MM.YYYY"
                defaultHour={14}
                timeFormat={ false }
                value={this.state.endDate}
                onChange={this.handleEndDateChange}
              />
            </Col>
          </div>
        </Row>

        <Row>
          <div class="col-md-5 row">
            <Form.Group as={Row} controlId="branch">
              <Form.Label column md={5}>Филиал</Form.Label>
              <Col md={5}>
                <Form.Control
                  as="select"
                  name="branch"
                  value={this.state.branch}
                  onChange={this.branchChange}
                >
                  <option value='0'>Общеорганизационная</option>
                  {
                    Object.keys(this.state.branches).map((key, index) => (
                      <option value={this.state.branches[key].branch_id}>
                        {this.state.branches[key].name}</option>
                  ))
                }

                </Form.Control>

              </Col>
            </Form.Group>
          </div>

          <div class="col-md-5 row">
            <Col md={5}>Пользователь</Col>
            <Col md={5}>
              <Form.Control
                  as="select"
                  name="user"
                  value={this.state.user}
                  onChange={this.userChange}
                >
                  <option value='0'>Общеорганизационная</option>
                  {
                    Object.keys(this.state.users).map((key, index) => (
                      <option value={this.state.users[key].user_id}>
                        {this.state.users[key].name}</option>
                  ))
                }

                </Form.Control>
            </Col>
          </div>
        </Row>



        <BootstrapTable data={
          [
            {
              'rowName': 'Наличный р-т',
              'input': this.state.inputCash,
              'output': this.state.outputCash,
              'sum': this.state.inputCash - this.state.outputCash
            },
            {
              'rowName': 'Безналичный р-т',
              'input': this.state.inputNotCash,
              'output': this.state.outputNotCash,
              'sum': this.state.inputNotCash - this.state.outputNotCash
            },
            {
              'rowName': 'Итого',
              'input': this.state.inputCash + this.state.inputNotCash,
              'output': this.state.outputNotCash + this.state.outputCash,
              'sum': this.state.inputNotCash + this.state.inputCash 
                - this.state.outputNotCash - this.state.outputCash
            }
          ]
        } 
          striped hover >
          <TableHeaderColumn dataField='rowName' dataAlign ='center' isKey></TableHeaderColumn>
          <TableHeaderColumn dataField='input' dataAlign ='center'>Приход</TableHeaderColumn>
          <TableHeaderColumn dataField='output' dataAlign ='center'>Расход</TableHeaderColumn>
          <TableHeaderColumn dataField='sum' dataAlign ='center' 
            dataFormat={this.totalFormatter} >Итого</TableHeaderColumn>
        </BootstrapTable>

        <Row>
          <div class="col-md-5 row" dangerouslySetInnerHTML={{

              __html: '<div class="row"><div class="row">Список транзакций</div></div></div>'
            }}>
          </div>

          <div class="col-md-5 row" style={{marginLeft: "10%"}}>
            <AddTransactionForm handleToUpdate = {this.handleToUpdate} 
              branches = {this.state.branches} branch_id = {0} 
              quit = {this.props.quit} />
          </div>
        </Row>

        <BootstrapTable data={this.state.data} options={options} pagination striped hover >
          <TableHeaderColumn 
            dataField='date' 
            dataAlign ='center' 
            dataFormat={this.dateFormatter}
            dataSort
            headerAlign='center'
            width='160'>
              Дата
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='cost'
            dataSort
            headerAlign='center'
            dataAlign='center'
            dataFormat={this.sumFormatter}>
              Сумма
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='branch_id'
            headerAlign='center'
            dataAlign='left'
            dataFormat={this.branchFormatter} >
              Филиал
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='description'
            headerAlign='center'
            dataAlign='left'
            dataFormat={this.descriptionFormatter}>
              Описание
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='user'
            headerAlign='center'
            dataAlign='left'>
              Сотрудник
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='cash'
            headerAlign='center'
            dataAlign='left' 
            dataFormat={this.cashFormatter} >
              Нал. р-т
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='type'
            hidden>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='transaction_id'
            hidden
            isKey>
          </TableHeaderColumn>
        </BootstrapTable>
      </React.Fragment>
    )
  }
}

export default Transactions;
