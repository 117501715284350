import React from 'react';
import Moment from 'moment';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Moves from '../moderatorMoves/moderatorMoves';
import Behaviors from '../moderatorBehaviors/moderatorBehaviors';
import Attractions from '../moderatorAttractions/moderatorAttractions';
import ClientCategories from '../moderatorClientCategories/moderatorClientCategories';
import TransactionTypes from '../moderatorTransactionTypes/moderatorTransactionTypes';
import Sales from '../moderatorSales/moderatorSales';
import 'moment/locale/ru';
import '../../css/bootstrap-4-3-1.css';
import '../../css/styles.css';

class AddictiveInfo extends React.Component {

  render() {
    Moment.locale('ru');
    return (
      <React.Fragment>
        <Tabs defaultActiveKey="sale_types" id="uncontrolled-tab-example">
          <Tab eventKey="sale_types" title="Система скидок">
             <Sales quit = {this.props.quit} />
          </Tab>
          <Tab eventKey="move_types" title="Акции">
             <Moves quit = {this.props.quit} />
          </Tab>
          <Tab eventKey="behavier_types" title="Модели поведения клиентов">
             <Behaviors quit = {this.props.quit} />
          </Tab>
          <Tab eventKey="attraction_types" title="Каналы привлечения">
             <Attractions quit = {this.props.quit} />
          </Tab>
          <Tab eventKey="client_types" title="Категории клиентов">
             <ClientCategories quit = {this.props.quit} />
          </Tab>
          <Tab eventKey="transaction_types" title="Виды транзакций">
             <TransactionTypes quit = {this.props.quit} />
          </Tab>
        </Tabs>
      </React.Fragment>
    )
  }
}

export default AddictiveInfo;
