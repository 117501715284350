import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as yup from 'yup';
import { Formik } from 'formik';
import '../../css/bootstrap-4-3-1.css';
import 'react-picky/dist/picky.css';
import '../../css/styles.css';

class AddPrecostForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    }
  }

  getSchema(){
    return yup.object({
      summ: yup.number().required('Итоговая стоимость - обязательное поле!')
        .min(0, 'Итоговая стоимость должна быть положительным числом'),
    });
  }

  show() {
    this.setState({
      show: true
    })
  }

  close() {
    this.setState({show: false});
  }

  onSubmit(values){
    let handleToUpdate = this.props.handleToUpdate;
    values.client_id = this.props.client_id;

    fetch("/addPrecost",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(values)
      })
      .then(res => res.json())
      .then(data => {
        if (data.code === 5){
          this.props.quit();
        }
        else{
          this.close();
          handleToUpdate();
        }
      })
      .catch(error => console.error(error))
  }

  render() {
    return (
      <Row>
        <Button variant='outline-primary' onClick={ () => this.show() } block>
          +Предоплата</Button>
        <Modal size="lg" show={this.state.show} onHide={() => this.close()}>
          <Modal.Header closeButton>
            <Modal.Title>Внести предоплату клиента</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Formik
              validationSchema={this.getSchema()}
              onSubmit={(values) => {
                  this.onSubmit(values);
                }
              }
              initialValues={{summ: 0, paymentCash: true}}
            >
              {({
                handleSubmit,
                values,
                touched,
                errors,
                setFieldValue,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="clientNumber">
                      <Form.Label column md={4}>Сумма предоплаты</Form.Label>
                      <Col md={3}>
                        <Form.Control
                          type="number"
                          placeholder="0"
                          name="summ"
                          value={values.summ}
                          onChange={e => {
                            e.preventDefault();
                            const { value } = e.target;
                            const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                            if (value === '0' || regex.test(value.toString())) {
                              setFieldValue("summ", value);
                            }
                          }}
                          isInvalid={!!(errors.summ && touched.summ)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.summ}
                        </Form.Control.Feedback>
                      </Col>

                      <Form.Label column md={3}>Наличный р-т</Form.Label>
                      <Col md={1}>
                        <Form.Control
                          type="checkbox"
                          name="paymentCash"
                          checked={values.paymentCash}
                          value={values.paymentCash}
                          onChange={e => {
                            setFieldValue("paymentCash", !values.paymentCash);
                          }}
                        />
                      </Col>
                    </Form.Group>

                    <div class="text-danger">
                      Внимание! У клиента может быть только одна предоплата.
                    </div>
                    <div class="text-danger">
                      При добавлении предыдущая предоплата будет удалена!
                    </div>

                    <div class="d-flex flex-row-reverse">
                      <Button type="submit">Создать</Button>
                      <Button variant="secondary" 
                        onClick={() => this.close()}>Отмена</Button>
                    </div>
                </Form>
              )}
            </Formik>

          </Modal.Body>
        </Modal>
      </Row>
    )
  }
}

export default AddPrecostForm;
