import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import MaskedInput from 'react-text-mask';
import * as yup from 'yup';
import { Formik, Field } from 'formik';
import '../../css/bootstrap-4-3-1.css';
import '../../css/styles.css';

const phoneNumberMask = [
  "+",
  "7",
  " ",
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

class AddSertificateCostForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      numberError: false,
      cardError: false,
      cardOwnerId: null,
      cardOwnerName: '',
      priceEqualCost: true
    }
    this.show = this.show.bind(this);
    this.checkPhone = this.checkPhone.bind(this);
  }

  getSchema(){
    return yup.object({
      number: yup.string().required('Номер - обязательное поле!')
        .min(4, 'Номер не может быть менее 4 символов')
        .max(40, 'Номер не может быть более 40 символов'),
      cost: yup.number().required('Номинал - обязательное поле!'),
      price: yup.number().required('Стоимость - обязательное поле!'),
    });
  }

  show() {
    this.setState({
      show: true,
      numberError: false,
      cardError: false,
      cardOk: false,
      gamezonesDisabled: false
    })
  }

  close() {
    this.setState({show: false});
  }

  checkPhone(number){
    fetch("/getClientInfoByPhone",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({phone: number})
      })
      .then(res => res.json())
      .then(data => {
        if (data.code === 5){
          this.props.quit();
        }
        if (data.code === 2){
          this.setState({cardError: true, cardOwnerName: '', cardOwnerId: null});
        }
        if (data.code === 1){
          this.setState({cardError: false, cardOwnerName: '', cardOwnerId: null});
        }
        if (data.code === 0){
          this.setState({cardError: false, cardOwnerName: data.name, 
            cardOwnerId: data.client_id});
        }
      })
      .catch(error => console.error(error))
  }

  onSubmit(values){
    let handleToUpdate = this.props.handleToUpdate;
    if (this.state.priceEqualCost){
      values.price = values.cost;
    };

    if (!this.state.cardError){
      values.client_id = this.state.cardOwnerId;

      fetch("/addSertificateCost",
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify(values)
        })
        .then(res => res.json())
        .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            if (data.code === 2){
              this.setState({numberError: true})
            }
            else{
              this.close();
              handleToUpdate();
            }
          }
        })
        .catch(error => console.error(error))
    }
  }

  render() {
    return (
      <React.Fragment>
        <Button variant='outline-primary' onClick={ () => this.show() } block>
          Новый сертификат</Button>
        <Modal size="lg" show={this.state.show} onHide={() => this.close()}>
          <Modal.Header closeButton>
            <Modal.Title>Создание нового сертификата</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Formik
              validationSchema={this.getSchema()}
              onSubmit={(values) => {
                  this.onSubmit(values);
                }
              }
              initialValues={{number: '', cost: '0', price: '0', phone: '', cash: true}}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                setFieldValue
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="sertificateNumber">
                      <Form.Label column md={4}>Номер</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          placeholder="Номер"
                          name="number"
                          value={values.number}
                          onChange={handleChange}
                          isInvalid={!!(errors.number && touched.number)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.number}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="clientPhone">
                      <Form.Label column md={4}>Телефон клиента</Form.Label>
                      <Col md={6}>
                        <Field
                          name="phone"
                          render={({ field }) => (
                            <MaskedInput
                              {...field}
                              mask={phoneNumberMask}
                              id="phone"
                              placeholder="Телефон"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.phone && touched.phone
                                  ? "text-input error"
                                  : "text-input"
                              }
                            />
                          )}
                        />
                      {errors.phone && touched.phone 
                        ? <div class="text-danger">{errors.phone}</div> 
                        : ''}
                      </Col>

                      <Button variant="primary" style={{marginTop: "0"}} 
                        onClick={() => this.checkPhone(values.phone)}>
                          Проверить</Button>
                    </Form.Group>

                    <Form.Group as={Row} controlId="clientInfo">
                      <Form.Label column md={4}></Form.Label>
                      <Col md={8}>
                        {this.state.cardError 
                          ? <div class="text-danger">Такого клиента не существует!</div> 
                          : ''}
                        {this.state.cardOwnerId 
                          ? <div>ФИО клиента: {this.state.cardOwnerName}</div> 
                          : ''}
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="sertificateCost">
                      <Form.Label column md={4}>Номинал</Form.Label>
                      <Col md={4}>
                        <Form.Control
                          type="number"
                          placeholder="Номинал"
                          name="cost"
                          value={values.cost}
                          onChange={e => {
                            e.preventDefault();
                            const { value } = e.target;
                            const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                            if (regex.test(value.toString())) {
                              setFieldValue("cost", value);
                            }
                          }}
                          isInvalid={!!(errors.cost && touched.cost)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.cost}
                        </Form.Control.Feedback>
                      </Col>

                      <Form.Label column md={3}>Наличный р-т</Form.Label>
                        <Col md={1}>
                          <Form.Control
                            type="checkbox"
                            name="paymentCash"
                            checked={values.cash}
                            value={values.cash}
                            onChange={e => {
                              setFieldValue("cash", !values.cash);
                            }}
                          />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="sertificatePrice">
                      <Form.Label column md={4}>Стоимость равна номиналу</Form.Label>
                        <Col md={1}>
                          <Form.Control
                            type="checkbox"
                            name="paymentCash"
                            checked={this.state.priceEqualCost}
                            onChange={() => {
                              this.setState({priceEqualCost: !this.state.priceEqualCost})
                            }}
                          />
                      </Col>

                      <Form.Label column md={3}>Стоимость</Form.Label>
                      <Col md={4}>
                        <Form.Control
                          type="number"
                          placeholder="Стоимость"
                          name="price"
                          value={this.state.priceEqualCost ? values.cost : values.price}
                          disabled={this.state.priceEqualCost}
                          onChange={e => {
                            e.preventDefault();
                            const { value } = e.target;
                            const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                            if (regex.test(value.toString())) {
                              setFieldValue("price", value);
                            }
                          }}
                          isInvalid={!!(errors.price && touched.price)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.price}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    {this.state.numberError 
                      ? <div class="text-danger">Сертификат с таким номером уже существует!</div> 
                      : ''}
                      
                    <div class="d-flex flex-row-reverse">
                      <Button type="submit">Создать</Button>
                      <Button variant="secondary" onClick={() => this.close()}>Отмена</Button>
                    </div>
                </Form>
              )}
            </Formik>

          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}

export default AddSertificateCostForm;
