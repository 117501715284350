import React from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import AddVisitForm from '../moderatorPage/moderatorVisits/moderatorAddVisit';
import AddVisitUserForm from './userAddVisit';
import VisitInfo from '../moderatorPage/moderatorVisits/moderatorVisitInfo';
import '../css/bootstrap-4-3-1.css';
import '../css/styles.css';
import '../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'react-datetime/css/react-datetime.css';

let moment = require('moment');
require('moment/locale/ru');

class UserVisits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoaded: false,
        data:[],
        sortName: undefined,
        sortOrder: undefined,
        branches: [],
        games: [],
        gamezones: [],
    };
    this.onSortChange = this.onSortChange.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
    this.handleToUpdate = this.handleToUpdate.bind(this);
    this.getBranches = this.getBranches.bind(this);
    this.getGamezones = this.getGamezones.bind(this);
    this.getGames = this.getGames.bind(this);
    this.updateData = this.updateData.bind(this);
  }

  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }

  actionFormatter(cell, row, enumObject) {
    return(
      <React.Fragment>
        <VisitInfo data = {row} />
      </React.Fragment>
    )
  }

  costFormatter(cell, row, enumObject) {
    return(
      <React.Fragment>
        {cell} руб.        
      </React.Fragment>
    )
  }

  dateFormatter(cell, row, enumObject) {
    return(
      <React.Fragment>
        {moment(cell).format("DD.MM.YYYY")}
      </React.Fragment>
    )
  }

  getBranches(){
    fetch("/getAvailableBranches")
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            this.setState({
              branches: data
            });
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  getGamezones(){
    fetch("/getGamezones")
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            this.setState({
              gamezones: data
            });
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  getGames(){
    fetch("/getGames")
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            this.setState({
              games: data
            });
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  updateData(){
    Promise.all([
        this.getBranches(),
        this.getGamezones(),
        this.getGames()
      ])
    .then(() =>
      fetch("/getVisits",
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({branch_id: this.props.branch})
        })
        .then(res => res.json())
        .then(data => {
            if (data.code === 5){
              this.props.quit();
            }
            else{
              this.setState({
                isLoaded: true,
                data: data
              });
            }
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
      )
  }

  componentDidMount() {
    this.updateData();
  }

  handleToUpdate(){
    this.updateData();
    this.forceUpdate()
  }

  handleStartDateChange(value){
    let d = moment(value, "DD.MM.YYYY", true);
    let y = isNaN(d.year());
    if (y){
      return
    }
    this.setState({startDate: d})
    this.updateData();
  }

  handleEndDateChange(value){
    let d = moment(value, "DD.MM.YYYY", true);
    if (isNaN(d.year())){
      return
    }
    this.setState({endDate: d})
    this.updateData();
  }

  normalize(inputArray){
    let dict = {};
    inputArray.map(item => dict.item[0] = item[1]);
    return dict;
  }

  render() {
    const options = {
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      onSortChange: this.onSortChange,
      noDataText: 'Данные не найдены'
    };

    let addVisit = <AddVisitUserForm 
      branches = {this.state.branches}
      gamezones = {this.state.gamezones}
      handleToUpdate = {this.handleToUpdate}
      games = {this.state.games}
      client = {0}
      user_role = {this.props.user_role}
      branch_id = {this.props.branch_id} 
      quit = {this.props.quit} />      

    return (
      <React.Fragment>
        <BootstrapTable data={this.state.data} options={options} pagination striped hover>
          <TableHeaderColumn 
            dataField='date' 
            dataAlign ='center' 
            dataFormat={this.dateFormatter}
            dataSort
            headerAlign='center'>
              Дата
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='client'
            dataSort
            headerAlign='center'
            filter={{type: 'TextFilter', delay: 1000 , placeholder: 'Введите имя'}}>
              Клиент
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='cost' 
            headerAlign='center'
            dataAlign='center'
            dataFormat={this.costFormatter}>
              Стоимость
          </TableHeaderColumn>
          <TableHeaderColumn 
            isKey 
            dataField='visit_id'
            dataFormat={this.actionFormatter}
            dataAlign='center'
            width='280'>
              {addVisit}
          </TableHeaderColumn>

          <TableHeaderColumn dataField='sertificate_number' hidden></TableHeaderColumn>
          <TableHeaderColumn dataField='visit_rent' hidden></TableHeaderColumn>
          <TableHeaderColumn dataField='visit_celebration' hidden></TableHeaderColumn>
          <TableHeaderColumn dataField='client_quontity' hidden></TableHeaderColumn>
          <TableHeaderColumn dataField='new_clients' hidden></TableHeaderColumn>
          <TableHeaderColumn dataField='visit_gamezones' hidden></TableHeaderColumn>
          <TableHeaderColumn dataField='visit_games' hidden></TableHeaderColumn>
        </BootstrapTable>
      </React.Fragment>
    )
  }
}

export default UserVisits;
