import React from 'react';
import Button from 'react-bootstrap/Button';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import AddOrganizationForm from './adminAddOrganization';
import UpdateOrganizationForm from './adminUpdateOrganization';
import history from '../../common/history';
import '../../css/bootstrap-4-3-1.css';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

class Organizations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoaded: false,
        data:[],
        sortName: undefined,
        sortOrder: undefined,
    };
    this.onSortChange = this.onSortChange.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
    this.handleToUpdate = this.handleToUpdate.bind(this);
    this.updateData = this.updateData.bind(this);
    this.lockOrganization = this.lockOrganization.bind(this);
  }

  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }

  actionFormatter(cell, row, enumObject) {
    let button;
    if (row.state === 0){
      button = <Button variant="danger" 
        onClick={() => this.lockOrganization(row.organization_id)}>
          Закрыть
        </Button>
    }
    if (row.state === 1){
      button = <Button variant="primary" 
        onClick={() => this.unlockOrganization(row.organization_id)}>
          Открыть
        </Button>
    }
    return(
      <Row>
        <Col md={6}>
          <UpdateOrganizationForm handleToUpdate = {this.handleToUpdate} 
            data = {{
              'contactName': row.contactName, 
              'contactPhone': row.contactPhone,
              'name': row.name, 
              'quotaModerator': row.quotaModerator, 
              'organization_id': row.organization_id,
              'quotaUser': row.quotaUser, 
              'quotaBranch': row.quotaBranch
            }}/>
        </Col>
        <Col md={6}>
          {button}
        </Col>
      </Row>
    )
  }

  onComponentWillMount() {
    this.updateData()
  }

  updateData(){
    fetch("/getOrganizations")
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            history.push('/login')
          }
          else{
            this.setState({
              isLoaded: true,
              data: data
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  lockOrganization(organization_id){
    fetch("/lockOrganizationById",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({"organization_id": organization_id})
      })
      .then(data => {
        if (data.code === 5){
          history.push('/login')
        }
        else{
          this.handleToUpdate();
        }
      })
      .catch(error => console.error(error))
  }

  unlockOrganization(organization_id){
    fetch("/unlockOrganizationById",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({"organization_id": organization_id})
      })
      .then(data => {
        if (data.code === 5){
          history.push('/login')
        }
        else{
          this.handleToUpdate();
        }
      })
      .catch(error => console.error(error))
  }

  componentDidMount() {
    this.updateData();
  }

  handleToUpdate(){
    this.updateData();
    this.forceUpdate()
  }

  contactFormatter(cell, row, enumObject) {
    return(
      <React.Fragment>
        {row.contactName}<br/>Тел.: {row.contactPhone}
      </React.Fragment>
    )
  }

  stateFormatter(cell, row, enumObject) {
    if (row.state === 0){
      return(
        <Button variant="success" disabled block>
          Активна</Button>
      )
    }
    if (row.state === 1){
      return(
        <Button variant="danger" disabled block>
          Заблокирована</Button>
      )
    }
  }

  render() {
    const options = {
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      onSortChange: this.onSortChange,
      noDataText: 'Данные не найдены'
    };

    return (
      <React.Fragment>
        <BootstrapTable 
          data={this.state.data} 
          options={options} 
          pagination 
          striped 
          hover>
          <TableHeaderColumn 
            isKey 
            dataField='organization_id' 
            width='50' 
            dataAlign ='center'
            dataSort
            headerAlign='center'>
              ID
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='name'
            dataSort
            headerAlign='center'
            filter={{ 
              type: 'TextFilter', 
              delay: 1000 , 
              placeholder: 'Введите название'
            }}>
              Название
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='contactName' 
            dataSort headerAlign='center'
            filter={{ 
              type: 'TextFilter', 
              delay: 1000, 
              placeholder: 'Введите имя'
            }}
            dataFormat={this.contactFormatter}>
              Контакт
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='quotaModerator' 
            width='75' 
            headerAlign='center' 
            dataAlign='center'>
              Кв. м.
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='quotaUser'
            width='75'
            headerAlign='center' 
            dataAlign='center'>
              Кв. п.
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='quotaBranch' 
            width='75' 
            headerAlign='center' 
            dataAlign='center'>
              Кв. ф.
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='state' 
            dataFormat={this.stateFormatter} 
            width='160' 
            headerAlign='center' 
            dataAlign='center'>
              Статус
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='organization_id'
            dataFormat={this.actionFormatter} 
            dataAlign='center'>
              <AddOrganizationForm handleToUpdate = {this.handleToUpdate} />
          </TableHeaderColumn>
        </BootstrapTable>
      </React.Fragment>
    )
  }
}

export default Organizations;
