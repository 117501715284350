import React from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import ReactStars from "react-rating-stars-component";
import AddGameForm from './moderatorAddGame';
import UpdateGameForm from './moderatorUpdateGame';
import '../../css/bootstrap-4-3-1.css';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

function multilineCell(cell, row) {
    return "<textarea class='form-control cell' rows='4'>" + 
      cell +"</textarea>";
} 

class Games extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoaded: false,
        data:[],
        sortName: undefined,
        sortOrder: undefined,
        branches: []
    };
    this.onSortChange = this.onSortChange.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
    this.dificultyFormatter = this.dificultyFormatter.bind(this);
    this.branchesFormatter = this.branchesFormatter.bind(this);
    this.handleToUpdate = this.handleToUpdate.bind(this);
    this.updateData = this.updateData.bind(this);
  }

  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }

  removeGame(game_id){
    fetch("/removeGame",
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({'game_id': game_id})
    })
    .then(res => res.json())
    .then(data => {
      if (data.code === 5){
        this.props.quit();
      }
      else{
        this.updateData();
      }
    })
    .catch(error => console.error(error))
  }

  actionFormatter(cell, row, enumObject) {
    return(
      <React.Fragment>
        <UpdateGameForm 
          handleToUpdate = {this.handleToUpdate} 
          quit = {this.props.quit} 
          data = {{
            'game_id': row.game_id, 
            'name': row.name, 
            'definition': row.definition, 
            'difficulty': row.difficulty, 
            'type': row.type, 
            'branches': row.branches
          }} 
        />
        <Row>
          <Button variant="danger" block 
            onClick={() => this.removeGame(row.game_id)}>Удалить</Button>
        </Row>
        </React.Fragment>
    )
  }

  onComponentWillMount() {
    this.updateData()
  }

  updateData(){
    fetch("/getAvailableBranches")
    .then(res => res.json())
    .then(data => {
        if (data.code === 5){
          this.props.quit();
        }
        else{
          this.setState({
            branches: data
          });
        }
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
    .then(() =>
      fetch("/getGames")
        .then(res => res.json())
        .then(data => {
            if (data.code === 5){
              this.props.quit();
            }
            else{
              this.setState({
                isLoaded: true,
                data: data
              });
            }
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    )
  }

  componentDidMount() {
    this.updateData();
  }

  handleToUpdate(){
    this.updateData();
    this.forceUpdate()
  }

  dificultyFormatter(cell, row, enumObject){
    return(
      <ReactStars 
        count={3}
        value={cell}
        half={false}
        edit={false}
        size={30}
        color2={"#ffd700"}
      />
    )
  }

  branchesFormatter(cell, row, enumObject){
    return(
      <div dangerouslySetInnerHTML=
      {{
        __html: this.state.branches
          .filter(branch => cell.includes(branch.branch_id))
          .map(branch => branch.name)
          .join('<br/>')
      }}>
      </div>
    )
  }

  normalize(inputArray){
    let dict = {};
    inputArray.map(item => dict[item[0]] = item[1]);
    return dict;
  }

  render() {
    const options = {
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      onSortChange: this.onSortChange,
      noDataText: 'Данные не найдены'
    };

    let branches = [];
    try{
      branches = this.normalize(this.state.branches
        .map(branch => [branch.branch_id, branch.name]))
    }
    catch(err){
      console.log(err)
    }

    return (
      <React.Fragment>
        <BootstrapTable 
          data={this.state.data}
          options={options}
          pagination
          striped
          hover >
          <TableHeaderColumn 
            isKey
            dataField='game_id'
            width='80'
            dataAlign ='center'
            dataSort
            headerAlign='center'>
              ID
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='name' 
            dataSort 
            headerAlign='center'
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите название'}} >
              Название
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='definition' 
            dataFormat={multilineCell} 
            width='350' 
            headerAlign='center'
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите часть описания'}}>
              Описание
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='difficulty' 
            dataFormat={this.dificultyFormatter} 
            dataAlign='center' 
            dataSort >
              Сложность
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='branches' 
            dataFormat={this.branchesFormatter} 
            dataAlign='center'
            filter={{type: 'SelectFilter', options: branches,
              placeholder: 'Выберите филиал'}}>
              Филиалы
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='type' 
            dataFormat={this.actionFormatter} 
            dataAlign='center' 
            block>
            <AddGameForm handleToUpdate = {this.handleToUpdate} 
              quit = {this.props.quit} />
          </TableHeaderColumn>
        </BootstrapTable>
      </React.Fragment>
    )
  }
}

export default Games;
