import React from 'react';
import Button from 'react-bootstrap/Button';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import AddAttractionForm from './moderatorAddAttraction';
import '../../css/bootstrap-4-3-1.css'
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

class Attractions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoaded: false,
        data:[],
        sortName: undefined,
        sortOrder: undefined,
    };
    this.onSortChange = this.onSortChange.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
    this.handleToUpdate = this.handleToUpdate.bind(this);
    this.updateData = this.updateData.bind(this);
    this.deleteAttraction = this.deleteAttraction.bind(this);
  }

  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }

  actionFormatter(cell, row, enumObject) {
    return(
      <Button variant="danger" block
        onClick={() => this.deleteAttraction(row.attraction_id)}>Удалить</Button>
    )
  }

  deleteAttraction(attraction_id){
    fetch("/removeAttraction",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({'attraction_id': attraction_id})
      })
      .then(data => {
        if (data.code === 5){
          this.props.quit()
        }
        else{
          this.handleToUpdate();
        }
      })
      .catch(error => console.error(error))
  }

  onComponentWillMount() {
    this.updateData()
  }

  updateData(){
    fetch("/getAttractions")
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit()
          }
          else{
            this.setState({
              isLoaded: true,
              data: data
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  componentDidMount() {
    this.updateData();
  }

  handleToUpdate(){
    this.updateData();
    this.forceUpdate()
  }

  render() {
    const options = {
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      onSortChange: this.onSortChange,
      noDataText: 'Данные не найдены'
    };

    return (
      <React.Fragment>
        <BootstrapTable data={this.state.data} options={options} striped hover >
          <TableHeaderColumn 
            isKey 
            dataField='attraction_id' 
            width='50' 
            hidden
            dataSort
            headerAlign='center'>
              ID
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='value' 
            dataSort
            headerAlign='center'
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите канал привлечения'}}>
              Канал привлечения
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='attraction_id' 
            dataFormat={this.actionFormatter} 
            dataAlign='center' 
            width='280'>
              <AddAttractionForm handleToUpdate = {this.handleToUpdate} 
                quit = {this.props.quit} />
          </TableHeaderColumn>
        </BootstrapTable>
      </React.Fragment>
    )
  }
}

export default Attractions;
