import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as yup from 'yup';
import { Formik } from 'formik';
import '../../css/bootstrap-4-3-1.css';
import '../../css/styles.css';

class AddGamezoneForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      branches: [],
      branchEmptiness: false
    }
    this.show = this.show.bind(this);
    this.updateData = this.updateData.bind(this);
  }

  getSchema(){
    return yup.object({
      name: yup.string().required('Название игровой зоны обязательное поле!'),
      branch_id: yup.number().min(1, 'Выберите филиал!'),
    });
  }

  show() {
    Promise.all([
      this.updateData(),
    ])
    .then(res => {
      this.setState({
        show: true
      })
    })
  }

  close() {
    this.setState({show: false});
  }

  updateData(){
    fetch("/getAvailableBranches")
      .then(res => res.json())
      .then(
        data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            if (data.code === 7){
             this.setState({'branchEmptiness': true}) 
            }
            else
            {
              this.setState({
                isLoaded: true,
                branches: data
              });
            }
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  onSubmit(values){
    let handleToUpdate = this.props.handleToUpdate;
    fetch("/addGamezone",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(values)
      })
      .then(res => res.json())
      .then(data => {
        if (data.code === 5){
          this.props.quit();
        }
        else{
          this.close();
          handleToUpdate();
        }
      })
      .catch(error => console.error(error))
  }

  render() {
    return (
      <React.Fragment>
        <Button variant='outline-primary' onClick={ () => this.show() } block>
          Новая игровая зона</Button>
        <Modal size="lg" show={this.state.show} onHide={() => this.close()}>
          <Modal.Header closeButton>
            <Modal.Title>Создание новой игровой зоны</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Formik
              validationSchema={this.getSchema()}
              onSubmit={(values) => {
                  this.onSubmit(values);
                }
              }
              initialValues={{name: '', definition: '', branch_id: '0'}}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="gamezoneName">
                      <Form.Label column md={4}>Название</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          placeholder="Название"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!(errors.name && touched.name)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="gamezoneDefinition">
                      <Form.Label column md={4}>Описание</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          as="textarea" rows="5"
                          placeholder="Описание"
                          name="definition"
                          value={values.definition}
                          onChange={handleChange}
                          isInvalid={!!(errors.definition && touched.definition)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.definition}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                     <Form.Group as={Row} controlId="gamezoneBranch">
                        <Form.Label column md={4}>Филиал</Form.Label>
                        <Col md={8}>
                          <Form.Control
                            as="select"
                            name="branch_id"
                            value={values.branch_id}
                            onChange={handleChange}
                            isInvalid={!!(errors.branch_id && touched.branch_id)}
                          >
                            <option value='0'>Выберите филиал</option>
                            {
                              Object.keys(this.state.branches).map((key, index) => (
                                <option value={this.state.branches[key].branch_id}>
                                  {this.state.branches[key].name}</option>
                            ))
                          }

                          </Form.Control>

                            <Form.Control.Feedback type="invalid">
                              {errors.branch_id}
                            </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      {this.state.branchEmptiness 
                        ? <div class="text-danger">
                            Для создания игровой зоны необходимо наличие филиала!
                          </div>
                        : ''}

                    <div class="d-flex flex-row-reverse">
                      <Button type="submit">Создать</Button>
                      <Button variant="secondary" onClick={() => this.close()}>Отмена</Button>
                    </div>
                </Form>
              )}
            </Formik>

          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}

export default AddGamezoneForm;
