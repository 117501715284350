import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../../css/bootstrap-4-3-1.css';
import '../../css/styles.css';

let moment = require('moment');
require('moment/locale/ru');

class SertificateCostInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      visits: [],
    }
    this.show = this.show.bind(this);
  }

  show() {
    fetch("/getSertificatesCostInfo?id=" + this.props.data.sertificate_cost_id)
      .then(res => res.json())
      .then(
        data => {
            this.setState({
              visits: data
            });
        },
      )

    this.setState({
      show: true,
    })
  }

  close() {
    this.setState({show: false});
  }

  render() {
    return (
      <React.Fragment>
        <Button variant='primary' onClick={ () => this.show() } block>
          Информация</Button>
        <Modal size="lg" show={this.state.show} onHide={() => this.close()}>
          <Modal.Header closeButton>
            <Modal.Title>Информация о сертификате</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <p>Номер: {this.props.data.number}</p>
            <p>
              { this.props.data.clientName === null 
                  ? 'Покупатель: не зарегистрирован в системе'
                  :'Покупатель: ' + this.props.data.clientName
              }
            </p>
            <p>Стоимость: {this.props.data.cost}</p>
            <p>Выдан: {moment(this.props.data.openDate)
              .format("DD.MM.YYYY")} пользователем {this.props.data.openUser}</p>
            {this.props.data.state === 1 
              ? <p>Аннулирован {moment(this.props.data.closeDate)
                  .format("DD.MM.YYYY")}, сотрудник: {this.props.data.closeUser}</p> 
              : ''}
            <p>Посещения по сертификату: {this.state.visits.length === 0 && 'нет'} </p>
            {
              this.state.visits.length > 0 &&
                <ol>
                  {
                    this.state.visits.map(visit => 
                      <li key={`${visit.date}`}>{`${visit.branch} ${new Date(Date.parse(visit.date)).toLocaleString('ru-RU')}: ${visit.cost} руб.`}</li>)
                  }
                </ol>
            }

          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}

export default SertificateCostInfo;
