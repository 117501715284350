import React from 'react';
import Button from 'react-bootstrap/Button';
import AddTransactionCategoryForm from './moderatorAddTransactionType';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../css/bootstrap-4-3-1.css';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

class TransactionTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoaded: false,
        data:[],
        sortName: undefined,
        sortOrder: undefined,
    };
    this.onSortChange = this.onSortChange.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
    this.handleToUpdate = this.handleToUpdate.bind(this);
    this.updateData = this.updateData.bind(this);
    this.deleteTraansactionCategory = this.deleteTraansactionCategory.bind(this);
  }

  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }

  actionFormatter(cell, row, enumObject) {
    return(
      <div>
        <Button variant="danger" block
          onClick={() => this.deleteTraansactionCategory(row.transaction_type_id)}>
            Удалить</Button>
        {
          row.visible === 0 && <Button variant="warning" block
            onClick={() => this.toggleVisibility(row.transaction_type_id)}>
              Заблокировать</Button>
        }

        {
          row.visible === 1 && <Button variant="success" block
            onClick={() => this.toggleVisibility(row.transaction_type_id)}>
              Разблокировать</Button>
        }
      </div>
    )
  }

  baseFormatter(cell, row, enumObject) {
    if (row.base === 0){
      return(
        <Button variant="danger" disabled block>
          Расходная</Button>
      )
    }
    if (row.base === 1){
      return(
        <Button variant="success" disabled block>
          Приходная</Button>
      )
    }
  }

  visibilityFormatter(cell, row) {
    if (cell === 0){
      return(
        <Button variant="success" disabled block>
          Доступна</Button>
      )
    }
    if (cell === 1){
      return(
        <Button variant="danger" disabled block>
          Недоступно</Button>
      )
    }
  }

  deleteTraansactionCategory(transaction_type_id){
    fetch("/removeTransactionType",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({'transaction_type_id': transaction_type_id})
      })
      .then(data => {
        if (data.code === 5){
          this.props.quit()
        }
        else{
          this.handleToUpdate();
        }
      })
      .catch(error => console.error(error))
  }

  toggleVisibility(transaction_type_id){
    fetch("/toggleTransactionTypeVisibility",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({'transaction_type_id': transaction_type_id})
      })
      .then(data => {
        if (data.code === 5){
          this.props.quit()
        }
        else{
          this.handleToUpdate();
        }
      })
      .catch(error => console.error(error))
  }

  onComponentWillMount() {
    this.updateData()
  }

  updateData(){
    fetch("/getTransactionTypes")
      .then(res => res.json())
      .then(
        data => {
          if (data.code === 5){
            this.props.quit()
          }
          else{
            this.setState({
              isLoaded: true,
              data: data
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  componentDidMount() {
    this.updateData();
  }

  handleToUpdate(){
    this.updateData();
    this.forceUpdate()
  }

  render() {
    const options = {
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      onSortChange: this.onSortChange,
      noDataText: 'Данные не найдены'
    };

    return (
      <React.Fragment>
        <BootstrapTable data={this.state.data} options={options} striped hover >
          <TableHeaderColumn 
            isKey
            dataField='transaction_type_id'
            width='50'
            hidden
            dataSort
            headerAlign='center'>
              ID
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='value'
            dataSort
            headerAlign='center'
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите тип транзакции'}}>
              Вид транзакции
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='base'
            width='150'
            headerAlign='center'
            dataFormat={this.baseFormatter}
            filter={{type: 'SelectFilter', placeholder: 'Выберите',
              options: {0: 'Расходная', 1: 'Приходная'}}}>
              Тип
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='visible'
            width='150'
            headerAlign='center'
            dataFormat={this.visibilityFormatter}
            filter={{type: 'SelectFilter', placeholder: 'Выберите',
              options: {0: 'Доступна', 1: 'Недоступна'}}}>
              Доступность
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='transaction_type_id'
            dataFormat={this.actionFormatter}
            dataAlign='center'
            width='280'>
              <AddTransactionCategoryForm handleToUpdate = {this.handleToUpdate} 
                quit = {this.props.quit} />
          </TableHeaderColumn>
        </BootstrapTable>
      </React.Fragment>
    )
  }
}

export default TransactionTypes;
