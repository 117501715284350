import React from 'react';
import Button from 'react-bootstrap/Button';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../css/bootstrap-4-3-1.css';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

let moment = require('moment');
require('moment/locale/ru');

class Precosts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoaded: false,
        data:[],
        sortName: undefined,
        sortOrder: undefined,
    };
    this.onSortChange = this.onSortChange.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
    this.handleToUpdate = this.handleToUpdate.bind(this);
    this.updateData = this.updateData.bind(this);
    this.deletePrecost = this.deletePrecost.bind(this);
  }

  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }

  deletePrecost(precost_id){
    fetch("/removePrecost",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({'precost_id': precost_id})
      })
      .then(data => {
        if (data.code === 5){
          this.props.quit()
        }
        else{
          this.handleToUpdate();
        }
      })
      .catch(error => console.error(error))
  }

  onComponentWillMount() {
    this.updateData()
  }

  updateData(){
    fetch("/getPrecosts")
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit()
          }
          else{
            this.setState({
              isLoaded: true,
              data: data
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  clientFormatter(cell, row, enumObject) {
    let res = row.client_number ?? 'Без карты' + ' - ' + row.client_name
    return(
        <React.Fragment>
            <div>{res}</div>
            <div>{row.client_phone ?? ''}</div>
        </React.Fragment>
    )
  }

  stateFormatter(cell, row, enumObject) {
    if (row.state === 0){
      return(
        <Button variant="primary" disabled block>
          Внесена</Button>
      )
    }
    if (row.state === 1){
      return(
        <Button variant="success" disabled block>
          Реализована</Button>
      )
    }
    if (row.state === 2){
      return(
        <Button variant="danger" disabled block>
          Аннулирована</Button>
      )
    }
  }

  actionFormatter(cell, row, enumObject) {
    let disabled = row.state === 0 ? false : true
    return(
        <Button variant="danger" disabled={disabled} 
            onClick={() => this.deletePrecost(row.precost_id)} block>Аннулировать</Button>
    )
  }

  dateFormatter(cell, row, enumObject) {
    return(
      <React.Fragment>
        {moment(cell).format("DD.MM.YYYY")}
      </React.Fragment>
    )
  }

  componentDidMount() {
    this.updateData();
  }

  handleToUpdate(){
    this.updateData();
    this.forceUpdate()
  }

  render() {
    const options = {
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      onSortChange: this.onSortChange,
      noDataText: 'Данные не найдены'
    };

    return (
      <React.Fragment>
        <BootstrapTable data={this.state.data} options={options} striped hover >
          <TableHeaderColumn 
            dataField='client_number'
            dataSort
            headerAlign='center'
            dataFormat={this.clientFormatter}
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите имя или номер карты'}}>
              Клиент
          </TableHeaderColumn>
          <TableHeaderColumn 
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите дату'}}
            headerAlign='center'
            dataAlign='center'
            dataField='date'
            dataFormat={this.dateFormatter}>
              Дата
          </TableHeaderColumn>
          <TableHeaderColumn 
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите сумму'}}
            headerAlign='center'
            dataAlign='center'
            dataField='summ'>
              Сумма
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={this.stateFormatter}
            filter={{type: 'SelectFilter', placeholder: 'Выберите',
                options: {0: 'Внесена', 1: 'Реализована', 2: 'Аннулирована'}}}
            headerAlign='center'
            dataField='state'>
              Состояние
          </TableHeaderColumn>

          <TableHeaderColumn 
            isKey 
            dataField='precost_id' 
            width='200'
            dataSort
            dataFormat={this.actionFormatter}
            headerAlign='center'>
          </TableHeaderColumn>
        </BootstrapTable>
      </React.Fragment>
    )
  }
}

export default Precosts;
