import React from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import AddSaleForm from './moderatorAddSale';
import '../../css/bootstrap-4-3-1.css';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

class Sales extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoaded: false,
        data:[],
        sortName: undefined,
        sortOrder: undefined,
    };
    this.onSortChange = this.onSortChange.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
    this.valueFormatter = this.valueFormatter.bind(this);
    this.conditionFormatter = this.conditionFormatter.bind(this);
    this.handleToUpdate = this.handleToUpdate.bind(this);
    this.updateData = this.updateData.bind(this);
    this.deleteSale = this.deleteSale.bind(this);
  }

  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }

  actionFormatter(cell, row, enumObject) {
    return(
      <Button variant="danger" 
        onClick={() => this.deleteSale(row.sale_id)} block>Удалить</Button>
    )
  }

  deleteSale(sale_id){
    fetch("/removeSale",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({'sale_id': sale_id})
      })
      .then(data => {
        if (data.code === 5){
          this.props.quit()
        }
        else{
          this.handleToUpdate();
        }
      })
      .catch(error => console.error(error))
  }

  valueFormatter(cell, row, enumObject) {
    return(
      <Row>
        Скидка {cell}% 
      </Row>
    )
  }

  conditionFormatter(cell, row, enumObject) {
    return(
      <Row>
        Потратить {cell} руб.
      </Row>
    )
  }

  onComponentWillMount() {
    this.updateData()
  }

  updateData(){
    fetch("/getSales")
      .then(res => res.json())
      .then(
        data => {
          if (data.code === 5){
            this.props.quit()
          }
          else{
            this.setState({
              isLoaded: true,
              data: data
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  componentDidMount() {
    this.updateData();
  }

  handleToUpdate(){
    this.updateData();
    this.forceUpdate()
  }

  render() {
    const options = {
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      onSortChange: this.onSortChange,
      noDataText: 'Данные не найдены'
    };

    return (
      <React.Fragment>
        <BootstrapTable data={this.state.data} options={options} striped hover>
          <TableHeaderColumn 
            isKey
            dataField='sale_id'
            width='50'
            dataAlign ='center'
            dataSort
            headerAlign='center'>
              ID
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='value'
            dataSort
            headerAlign='center'
            dataFormat={this.valueFormatter}
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите название'}}>
              Размер скидки
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='condition' 
            dataFormat={this.conditionFormatter}
            headerAlign='center'
            dataAlign='center'>
              Условие предоставления
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='sale_id'
            dataFormat={this.actionFormatter} 
            dataAlign='center'
            width='200'>
              <AddSaleForm handleToUpdate = {this.handleToUpdate} 
                quit = {this.props.quit} />
          </TableHeaderColumn>
        </BootstrapTable>
      </React.Fragment>
    )
  }
}

export default Sales;
