import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import '../../css/bootstrap-4-3-1.css';
import '../../css/styles.css';

let moment = require('moment');
require('moment/locale/ru');

class VisitInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    }
    this.show = this.show.bind(this);
  }

  show() {
    this.setState({
      show: true
    })
  }

  close() {
    this.setState({show: false});
  }

  render() {
    return (
      <React.Fragment>
        <Button variant='primary' onClick={() => this.show()} block>
          Информация</Button>
        <Modal size="lg" show={this.state.show} onHide={() => this.close()}>
          <Modal.Header closeButton>
            <Modal.Title>Информация о посещении</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Row>
              <Col md={4}>Дата и время:</Col>
              <Col md={6}>
                {moment(this.props.data.date).format("DD.MM.YYYY HH:mm")}
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                {this.props.data.client 
                  ? 'Посещение клиента ' + this.props.data.client 
                  : 'Посещение незарегистрированного клиента'}
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                Филиал:
              </Col>
              <Col md={6}>
                {this.props.data.branch_name}
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                {this.props.data.sertificate_number || this.props.data.sertificate_cost_number
                  ? <React.Fragment>
                      { this.props.data.sertificate_number ? 'Сертификат на посещение №' + this.props.data.sertificate_number : ''} 
                      { this.props.data.sertificate_cost_number ? 'Сертификат на стоимость №' + this.props.data.sertificate_cost_number : ''} 
                    </React.Fragment>
                  : 'Без сертификата'}
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                {this.props.data.rent === 1 && this.props.data.celebration === 1 
                  ? 'Аренда, детский праздник'
                  : this.props.data.rent === 1 
                    ? 'Аренда': this.props.data.celebration === 1 
                      ? 'Детский праздник' 
                      : 'Обычное посещение'}
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                Кол-во посетителей:
              </Col>
              <Col md={2}>
                {this.props.data.client_quontity}
              </Col>
              <Col md={3}>
                Новых:
              </Col>
              <Col md={2}>
                {this.props.data.new_clients}
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                Игровые зоны: 
              </Col>
              <div class="col-md-6" dangerouslySetInnerHTML={{__html:
                this.props.data.visit_gamezones.map(item =>
                  `${item.gamezone} (${item.time} мин)<br/>`
                ).join("")
              }}
              />
            </Row>
            <Row>
              <Col md={4}>
                Игры:
              </Col>
              <div class="col-md-6" dangerouslySetInnerHTML={{__html:
                this.props.data.visit_games.map(item =>
                  `${item.game}<br>`
                ).join("")
              }}
              />
            </Row>
            <Row>
              <Col md={4}>
                Стоимость:
              </Col>
              <Col md={3}>
                {this.props.data.cost} {this.props.data.cash === 1 
                  ? ' (нал.)' 
                  : '( безнал.)'}
              </Col>
              <Col md={3}>
                {this.props.data.precost === 0 
                  ? '' 
                  : '+предоплата ' + this.props.data.precost + 'р.'}
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                {this.props.data.move 
                  ? 'По акции "' + this.props.data.move + '"' 
                  : ''}
              </Col>
            </Row>
            
          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}

export default VisitInfo;
