import React from 'react';
import Card from 'react-bootstrap/Card';
import Moment from 'moment';
import 'moment/locale/ru';
import '../../css/bootstrap-4-3-1.css';
import '../../css/styles.css';

class Info extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
    this.updateData = this.updateData.bind(this);
  }

  componentDidMount() {
    this.updateData();
    this.forceUpdate()
  }

  updateData(){
    fetch("/userOrganizationInfo")
      .then(res => res.json())
      .then(
        (data) => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            this.setState({
              isLoaded: true,
              data: data
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    Moment.locale('ru');
    return (
      <React.Fragment>
        <Card border="secondary">
          <Card.Body>
            <Card.Title>{this.state.data.name}</Card.Title>
            <Card.Text>
              <p>Контактное лицо: {this.state.data.contact_name} </p>
              <p>Телефон контактного лица: {this.state.data.contact_phone} </p>
              <p>Квота на создание модераторов: {this.state.data.moderator_quota} </p>
              <p>Квота на создание пользователей: {this.state.data.user_quota} </p>
              <p>Квота на создание филиалов: {this.state.data.branch_quota} </p>
              <p>Дата регистрации в Калипсо: {Moment(this.state.data.registration_date)
                .format('DD MMMM YYYY')} </p>
              <p>Если у вас возники вопросы при работе в системе, обратитесь к администратору по тел.: 8-999-999-99-99</p>
            </Card.Text>
          </Card.Body>
        </Card>
      </React.Fragment>
    )
  }
}

export default Info;
