import React from 'react';
import ReactDOM from 'react-dom';
import LoginPage from './loginPage';
import AdminPage from './adminPage/adminPage';
import ModeratorPage from './moderatorPage/moderatorPage'
import * as serviceWorker from './serviceWorker';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_role: -1,
      branch_id: 0
    };
    this.setUserInfo = this.setUserInfo.bind(this);
  }

  setUserInfo(user_role, branch_id){
    this.setState({user_role: user_role, branch_id: branch_id})
  }

  render(){
    if (this.state.user_role === -1){
      return (<LoginPage setUserInfo={this.setUserInfo} />);
    }
    if (this.state.user_role === 0){
       return (<AdminPage setUserInfo={this.setUserInfo} user_role={0} />);
    }
    if (this.state.user_role === 1){
      return (<ModeratorPage setUserInfo={this.setUserInfo} 
        user_role={1} branch_id={0} />);
    }
    if (this.state.user_role === 2){
      return (<ModeratorPage setUserInfo={this.setUserInfo} user_role={2} 
        branch_id={this.state.branch_id} />);
    }
    return (
      'Error!'
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
//serviceWorker.register();
