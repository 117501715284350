import React from 'react';
import Moment from 'moment';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import TotalReports from './moderatorTotalReports/moderatorTotalReports';
import BranchReports from './moderatorBranchReports/moderatorBranchReports';
import 'moment/locale/ru';
import '../../css/bootstrap-4-3-1.css';
import '../../css/styles.css';

class Reports extends React.Component {

  render() {
    Moment.locale('ru');
    return (
      <React.Fragment>
        <Tabs defaultActiveKey="total_reports" id="uncontrolled-tab-example">
          <Tab eventKey="total_reports" title="Отчеты по организации">
             <TotalReports />
          </Tab>
          <Tab eventKey="branch_reports" title="Отчеты по филиалам">
             <BranchReports />
          </Tab>
        </Tabs>
      </React.Fragment>
    )
  }
}

export default Reports;
