import React from 'react';
import Header from '../common/header';
import Menu from '../menu';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import '../css/styles.css';
import Users from './adminUsers/adminUsers';
import Organizations from './adminOrganizations/adminOrganizations';

let items = [
  {
    "name": "Организации",
    "link": "#link_organizations",
    "roles": [0]
  },
  {
    "name": "Модераторы",
    "link": "#link_users",
    "roles": [0]
  }
]

class AdminPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <Row>
          <Tab.Container id="menu" defaultActiveKey="#link_organizations">
            <Row>
              <Col md={2}>
                <Menu items={items} setUserInfo={this.props.setUserInfo} 
                  user_role={this.props.user_role} />
              </Col>

              <Col sm={10}>
                <Tab.Content>
                  <Tab.Pane eventKey="#link_organizations" unmountOnExit='true' 
                    mountOnEnter='true'>
                    <Organizations />
                  </Tab.Pane>

                  <Tab.Pane eventKey="#link_users" unmountOnExit='true' 
                    mountOnEnter='true'>
                    <Users />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Row>
      </React.Fragment>
    )
  }
}

export default AdminPage;
