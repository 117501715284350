import React from 'react';
import Button from 'react-bootstrap/Button';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import AddMoveForm from './moderatorAddMove';
import '../../css/bootstrap-4-3-1.css';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

function multilineCell(cell, row) {
    return "<textarea class='form-control cell' rows='4'>" + cell +"</textarea>";
} 

class Moves extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoaded: false,
        data:[],
        sortName: undefined,
        sortOrder: undefined,
    };
    this.onSortChange = this.onSortChange.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
    this.handleToUpdate = this.handleToUpdate.bind(this);
    this.updateData = this.updateData.bind(this);
    this.toggleMove = this.toggleMove.bind(this);
  }

  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }

  actionFormatter(cell, row, enumObject) {
    return(
        <Button variant={row.state === 0 ? 'warning' : 'primary'}
          onClick={() => this.toggleMove(row.move_id)} block>{row.state === 0 ? 'Скрыть' : 'Показать'}</Button>
    )
  }

  toggleMove(move_id){
    fetch("/toggleMove",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({'move_id': move_id})
      })
      .then(data => {
        if (data.code === 5){
          this.props.quit()
        }
        else{
          this.handleToUpdate();
        }
      })
      .catch(error => console.error(error))
  }

  onComponentWillMount() {
    this.updateData()
  }

  updateData(){
    fetch("/getMoves")
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit()
          }
          else{
            this.setState({
              isLoaded: true,
              data: data
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  componentDidMount() {
    this.updateData();
  }

  handleToUpdate(){
    this.updateData();
    this.forceUpdate()
  }

  render() {
    const options = {
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      onSortChange: this.onSortChange,
      noDataText: 'Данные не найдены'
    };

    return (
      <React.Fragment>
        <BootstrapTable data={this.state.data} options={options} striped hover >
          <TableHeaderColumn 
            isKey 
            dataField='move_id' 
            width='50'
            hidden
            dataSort
            headerAlign='center'>
              ID
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='name'
            dataSort
            headerAlign='center'
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите название'}}>
              Название
          </TableHeaderColumn>
          <TableHeaderColumn 
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите часть описания'}}
            headerAlign='center'
            dataField='definition'
            dataFormat={multilineCell}>
              Описание
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='move_id' 
            dataFormat={this.actionFormatter}
            dataAlign='center'
            width='200'>
              <AddMoveForm handleToUpdate = {this.handleToUpdate} 
                quit = {this.props.quit} />
          </TableHeaderColumn>
        </BootstrapTable>
      </React.Fragment>
    )
  }
}

export default Moves;
