import React from 'react';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../../css/bootstrap-4-3-1.css';
import '../../css/styles.css';

class RemoveClientForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    }
    this.show = this.show.bind(this);
  }

  show() {
    this.setState({
      show: true,
    })
  }

  close() {
    this.setState({show: false});
  }

  onSubmit(){
    let handleToUpdate = this.props.handleToUpdate;

    fetch("/removeClient",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({client_id: this.props.client_id})
      })
      .then(res => res.json())
        .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            this.close();
            handleToUpdate();
          }
        })
        .catch(error => console.error(error))
  }

  render() {
    return (
      <Row>
        <Button variant='outline-danger' onClick={ () => this.show() } block>
          Удалить</Button>
        <Modal size="lg" show={this.state.show} onHide={() => this.close()}>
          <Modal.Header closeButton>
            <Modal.Title>Удаление клиента</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <React.Fragment>
              Вы уверены что хотите удалить клиента "{this.props.client_name}"?
            </React.Fragment>

            <div class="d-flex flex-row-reverse">
              <Button variant="primary" 
                onClick={() => this.onSubmit()}>Удалить</Button>
              <Button variant="secondary" 
                onClick={() => this.close()}>Отмена</Button>
            </div>
          </Modal.Body>
        </Modal>
      </Row>
    )
  }
}

export default RemoveClientForm;
