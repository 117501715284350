import React from 'react';
import Header from './common/header';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as yup from 'yup';
import { Formik } from 'formik';

const schema = yup.object({
  name: yup.string().required('Введите имя пользователя!'),
  password: yup.string().required('Введите пароль!')
});

class LoginPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {errorText: ''}
    this.login = this.login.bind(this);
  }

  login(values) {
    fetch("/signIn",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(values)
      })
    .then(res => res.json())
    .then(data => {
      if (data.code === 1){
        this.setState({errorText: 'Пользователя с таким именем не существует!'});
        this.forceUpdate();
      }
      if (data.code === 2){
        this.setState({errorText: 'Пользователь был заблокирован!'});
        this.forceUpdate();
      }
      if (data.code === 3){
        this.setState({errorText: 'Введен неверный пароль!'});
        this.forceUpdate();
      }
      if (data.code === 0){
        let setUserInfo = this.props.setUserInfo;
        setUserInfo(data.user_role, data.branch_id)
      }
    })
    .catch(error => console.error(error))
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <Row>
          <Col md={{span: 4, offset: 4}}>
          <Formik
            validationSchema={schema}
            onSubmit={(values) => this.login(values)}
            initialValues={{name: '', password: ''}}
          >
            {({
              handleSubmit,
              handleChange,
              values,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>

                <Form.Group as={Row} controlId="userName">
                  <Form.Label column md={4}>Имя пользователя</Form.Label>
                  <Col md={8}>
                    <Form.Control
                      type="text"
                      placeholder="Имя"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="userPassword">
                  <Form.Label column md={4}>Пароль</Form.Label>
                  <Col md={8}>
                    <Form.Control
                      type="password"
                      placeholder="Пароль"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Col sm={{ span: 10, offset: 3 }}>
                  <div class="text-danger">{this.state.errorText}</div>
                </Col>

                <Form.Group as={Row}>
                    <Col sm={{ span: 10, offset: 5 }}>
                      <Button size="lg" type="submit">Войти</Button>
                    </Col>
                </Form.Group>

              </Form>
            )}
            </Formik>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export default LoginPage;
