import React from 'react';
import ModeratorSingleReport from '../moderatorSingleReport';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import '../../../css/bootstrap-4-3-1.css';
import '../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

class moderatorBranchReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      branches: [],
      selectedBranch: '0'
    };
    this.getBranches = this.getBranches.bind(this);
    this.branchChange = this.branchChange.bind(this);
  }

  branchChange(value){
    this.setState({'selectedBranch': value.target.value})
  }

  getBranches(){
    return fetch("/getBranches")
      .then(res => res.json())
      .then(
        data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            this.setState({
              branches: data
            });
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  componentDidMount() {
    this.getBranches();
  }

  render() {
    return (
      <React.Fragment>
        <Form.Group as={Row} controlId="branch">
          <Form.Label column md={5}>Филиал</Form.Label>
          <Col md={5}>
            <Form.Control
              as="select"
              name="branch_id"
              value={this.state.selectedBranch}
              onChange={this.branchChange}
            >
              <option value='0'>Не выбран</option>
              {
                Object.keys(this.state.branches).map((key, index) => (
                  <option value={this.state.branches[key].branch_id}>
                    {this.state.branches[key].name}</option>
              ))
            }
            </Form.Control>
          </Col>
        </Form.Group>

        <ModeratorSingleReport 
          name='Аналитический отчет' 
          url='/localAnaliticReport' 
          startDate='true' 
          endDate='true' 
          branch_id={this.state.selectedBranch} />
        <ModeratorSingleReport 
          name='Финансовый отчет' 
          url='/localEconomicReport' 
          startDate='true' 
          endDate='true' 
          branch_id={this.state.selectedBranch} />
        <ModeratorSingleReport 
          name='Финансовый отчет по дням' 
          url='/localEconomicByDayReport' 
          startDate='true' 
          endDate='true' 
          branch_id={this.state.selectedBranch} />
      </React.Fragment>
    )
  }
}

export default moderatorBranchReports;
