import React from 'react';
import ModeratorSingleReport from '../moderatorSingleReport';
import '../../../css/bootstrap-4-3-1.css';
import '../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

class moderatorTotalReports extends React.Component {

  render() {
    return (
      <React.Fragment>
        <ModeratorSingleReport name='Аналитический отчет' branch_id={-1}
          url='/analiticReport' startDate='true' endDate='true' />
        <ModeratorSingleReport name='Финансовый отчет' branch_id={-1}
          url='/economicReport' startDate='true' endDate='true' />
        <ModeratorSingleReport name='Финансовый отчет по дням' branch_id={-1}
          url='/economicByDayReport' startDate='true' endDate='true' />
        <ModeratorSingleReport name='Повторная посещаемость' branch_id={-1}
          url='/repeatVisitReport' startDate='false' endDate='true' />
        <ModeratorSingleReport name='Список клиентов с ближайшими ДР' branch_id={-1}
          url='/getClientList' startDate='true' endDate='true'/>
      </React.Fragment>
    )
  }
}

export default moderatorTotalReports;
