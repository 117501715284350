import React from 'react';
import Header from '../common/header';
import Menu from '../menu';
import Users from './moderatorUsers/moderatorUsers';
import Branches from './moderatorBranches/moderatorBranches';
import Clients from './moderatorClients/moderatorClients';
import Sertificates from './moderatorSertificates/moderatorSertificates';
import SertificatesCost from './moderatorSertificatesCost/moderatorSertificatesCost';
import Visits from './moderatorVisits/moderatorVisits';
import UserVisits from '../userPage/userVisits';
import Precosts from './moderatorPrecosts/moderatorPrecosts';
import Games from './moderatorGames/moderatorGames';
import Gamezones from './moderatorGamezones/moderatorGamezones';
import Info from './moderatorInfo/moderatorInfo';
import AddictiveInfo from './moderatorAddictiveInfo/moderatorAddictiveInfo';
import Transactions from './moderatorTransactions/moderatorTransactions';
import UserTransactions from '../userPage/userTransactions';
import Reports from './moderatorReports/moderatorReports';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import '../css/styles.css'

let items = [
  {
    "name": "Работа с клиентами",
    "variant": "dark",
    "roles": [1, 2]
  },
  {
    "name": "- Клиенты",
    "link": "#link_clients",
    "roles": [1, 2]
  },
  {
    "name": "- Посещения",
    "link": "#link_visits",
    "roles": [1, 2]
  },
  {
    "name": "- Сертификаты на посещение",
    "link": "#link_sertificates",
    "roles": [1, 2]
  },
  {
    "name": "- Сертификаты на стоимость",
    "link": "#link_sertificates_cost",
    "roles": [1, 2]
  },
  {
    "name": "- Предоплаты",
    "link": "#link_precosts",
    "roles": [1]
  },
  {
    "name": "- Касса",
    "link": "#link_cassa",
    "roles": [1, 2]
  },
  {
    "name": "Администрирование",
    "variant": "dark",
    "roles": [1]
  },
  {
    "name": "- Филиалы",
    "link": "#link_branches",
    "roles": [1]
  },
  {
    "name": "- Пользователи",
    "link": "#link_users",
    "roles": [1]
  },
  {
    "name": "- Отчеты",
    "link": "#link_reports",
    "roles": [1]
  },
  {
    "name": "- Сведения об организации",
    "link": "#link_info",
    "roles": [1]
  },
  {
    "name": "Настройки",
    "variant": "dark",
    "roles": [1]
  },
  {
    "name": "- Игровые зоны",
    "link": "#link_game_zones",
    "roles": [1]
  },
  {
    "name": "- Игры",
    "link": "#link_games",
    "roles": [1]
  },
  {
    "name": "- Доп. информация",
    "link": "#link_addictive_info",
    "roles": [1]
  }
]

class ModeratorPage extends React.Component {
  constructor(props) {
    super(props);
    this.quit = this.quit.bind(this);
  }

  quit(){
    this.props.setUserInfo(-1, 0);
  }

  render() {
    let visits = <UserVisits role={this.props.user_role}  
      quit = {this.quit} branch_id={this.props.branch_id} />;
    let transactions = <UserTransactions user_role={this.props.user_role} 
      branch_id={this.props.branch_id} quit = {this.quit} />;
    if (this.props.user_role === 1){
      visits = <Visits role={this.props.user_role} quit = {this.quit} />;
      transactions = <Transactions user_role={this.props.user_role} 
        branch_id={this.props.branch_id} quit = {this.quit} />;
    }
    return (
      <React.Fragment>
        <Header />
        <Row>
          <Tab.Container id="menu" defaultActiveKey="#link_clients">
            <Row>
              <Col md={2}>
                <Menu items={items} setUserInfo={this.props.setUserInfo} 
                  user_role={this.props.user_role} />
              </Col>
              <Col sm={10}>
                <Tab.Content>
                  <Tab.Pane eventKey="#link_branches" unmountOnExit='true' 
                    mountOnEnter='true'>
                    <Branches quit = {this.quit} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="#link_game_zones" unmountOnExit='true' 
                    mountOnEnter='true'>
                    <Gamezones quit = {this.quit} />
                  </Tab.Pane> 
                  <Tab.Pane eventKey="#link_games" unmountOnExit='true' 
                    mountOnEnter='true'>
                    <Games quit = {this.quit} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="#link_clients" unmountOnExit='true' 
                    mountOnEnter='true'>
                    <Clients role={this.props.user_role} 
                      branch_id={this.props.branch_id} quit = {this.quit} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="#link_visits" unmountOnExit='true'
                    mountOnEnter='true'>
                    {visits}
                  </Tab.Pane>
                  <Tab.Pane eventKey="#link_sertificates" unmountOnExit='true' 
                    mountOnEnter='true'>
                    <Sertificates role={this.props.user_role} 
                      quit = {this.quit} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="#link_sertificates_cost" unmountOnExit='true' 
                    mountOnEnter='true'>
                    <SertificatesCost role={this.props.user_role} 
                      quit = {this.quit} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="#link_precosts" unmountOnExit='true' 
                    mountOnEnter='true'>
                    <Precosts />
                  </Tab.Pane>
                  <Tab.Pane eventKey="#link_cassa" unmountOnExit='true' 
                    mountOnEnter='true'>
                    {transactions}
                  </Tab.Pane>
                  <Tab.Pane eventKey="#link_users" unmountOnExit='true' 
                    mountOnEnter='true'>
                    <Users quit = {this.quit} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="#link_reports" unmountOnExit='true' 
                    mountOnEnter='true'>
                    <Reports />
                  </Tab.Pane>
                  <Tab.Pane eventKey="#link_info" unmountOnExit='true' 
                    mountOnEnter='true'>
                    <Info quit = {this.quit} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="#link_addictive_info" unmountOnExit='true' 
                    mountOnEnter='true'>
                    <AddictiveInfo quit = {this.quit} />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Row>
      </React.Fragment>
    )
  }
}

export default ModeratorPage;
