import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Datetime from 'react-datetime';
import {baseUrl} from '../../common/constants';
import '../../css/bootstrap-4-3-1.css';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

let moment = require('moment');
require('moment/locale/ru');

class moderatorSingleReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        startDate: moment().startOf('day').format("DD.MM.YYYY"),
        endDate: moment().endOf('day').format("DD.MM.YYYY"),
    };
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  handleStartDateChange(value){
    let d = moment(value, "DD.MM.YYYY", true);
    if (isNaN(d.year())){
      return
    }
    this.setState({startDate: d.format("DD.MM.YYYY")});
  }

  handleEndDateChange(value){
    let d = moment(value, "DD.MM.YYYY", true);
    if (isNaN(d.year())){
      return
    }
    this.setState({endDate: d.format("DD.MM.YYYY")});
  }

  dateFormatter(cell, row, enumObject) {
    return(
      <React.Fragment>
        {moment(cell).format("DD.MM.YYYY")}
      </React.Fragment>
    )
  }

  render() {
    return (
      <Row>
        <Col md={2}>{this.props.name}</Col>
        <Col md={2}>
          <a href={baseUrl+this.props.url+'?start='+
            this.state.startDate+'&end='+this.state.endDate+
            '&branch_id='+this.props.branch_id}> 
            <Button variant='primary' block >Скачать</Button>
          </a>
        </Col>

        { 
          this.props.startDate === 'true' 
            ? <Col md={4}>
                <Row>
                  <Col md={5}>Дата отсчета:</Col>
                  <Col md={5}>
                    <Datetime
                      locale="ru"
                      dateFormat="DD.MM.YYYY"
                      defaultHour={14}
                      timeFormat={ false }
                      value={this.state.startDate}
                      onChange={this.handleStartDateChange}
                    />
                  </Col>
                </Row>
              </Col> 
            : ''
        }

        {
          this.props.endDate === 'true' 
            ? <Col md={4}>
                <Row>
                  <Col md={5}>Дата окончания:</Col>
                  <Col md={5}>
                    <Datetime
                      locale="ru"
                      dateFormat="DD.MM.YYYY"
                      defaultHour={14}
                      timeFormat={ false }
                      value={this.state.endDate}
                      onChange={this.handleEndDateChange}
                    />
                  </Col> 
                </Row>
              </Col> 
            : ''
        }
      </Row>
    )
  }
}

export default moderatorSingleReports;
