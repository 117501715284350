import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as yup from 'yup';
import { Formik } from 'formik';
import '../../css/bootstrap-4-3-1.css';
import '../../css/styles.css';

class AddSertificateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      numberError: false,
      cardError: false,
      cardOwnerId: null,
      cardOwnerName: '',
      gamezonesDisabled: false
    }
    this.show = this.show.bind(this);
    this.checkCardNumber = this.checkCardNumber.bind(this);
  }

  getSchema(){
    return yup.object({
      number: yup.string().required('Номер - обязательное поле!'),
      cost: yup.number().required('Стоимость - обязательное поле!'),
      rent: yup.boolean(),
      gamezones: yup.number().test('gamezonesTest', 
        'Кол-во игровых зон должно быть положительным числом!', function(value){
          const rent = this.parent.rent;
          if (rent) return true;
          if (value > 0) return true;
          return false; 
        }
      ),
      duration: yup.number().required('Продолжительность - обязательное поле!')
        .positive('Продолжительность должна быть положительным числом!'),
    });
  }

  show() {
    this.setState({
      show: true,
      numberError: false,
      cardError: false,
      cardOk: false,
      gamezonesDisabled: false
    })
  }

  close() {
    this.setState({show: false});
  }

  checkCardNumber(number){
    fetch("/getClientInfo",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({number: number})
      })
      .then(res => res.json())
      .then(data => {
        if (data.code === 5){
          this.props.quit();
        }
        if (data.code === 2){
          this.setState({cardError: true, cardOwnerName: '', cardOwnerId: null});
        }
        if (data.code === 1){
          this.setState({cardError: false, cardOwnerName: '', cardOwnerId: null});
        }
        if (data.code === 0){
          this.setState({cardError: false, cardOwnerName: data.name, 
            cardOwnerId: data.client_id});
        }
      })
      .catch(error => console.error(error))
  }

  onSubmit(values){
    let handleToUpdate = this.props.handleToUpdate;
    if (!this.state.cardError){
      values.client_id = this.state.cardOwnerId;

      fetch("/addSertificate",
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify(values)
        })
        .then(res => res.json())
        .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            if (data.code === 2){
              this.setState({numberError: true})
            }
            else{
              this.close();
              handleToUpdate();
            }
          }
        })
        .catch(error => console.error(error))
    }
  }

  render() {
    return (
      <React.Fragment>
        <Button variant='outline-primary' onClick={ () => this.show() } block>
          Новый сертификат</Button>
        <Modal size="lg" show={this.state.show} onHide={() => this.close()}>
          <Modal.Header closeButton>
            <Modal.Title>Создание нового сертификата</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Formik
              validationSchema={this.getSchema()}
              onSubmit={(values) => {
                  this.onSubmit(values);
                }
              }
              initialValues={{number: '', cost: '0', gamezones: '', duration: '', 
                cardNumber: '', rent: false, cash: true}}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
                setFieldValue
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="sertificateNumber">
                      <Form.Label column md={4}>Номер</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          placeholder="Номер"
                          name="number"
                          value={values.number}
                          onChange={handleChange}
                          isInvalid={!!(errors.number && touched.number)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.number}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="sertificateCardNumber">
                      <Form.Label column md={4}>Номер</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="text"
                          placeholder="Карта клиента"
                          name="cardNumber"
                          value={values.cardNumber}
                          onChange={handleChange}
                          isInvalid={!!(errors.cardNumber && touched.cardNumber)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.cardNumber}
                        </Form.Control.Feedback>
                      </Col>

                      <Button variant="primary" style={{marginTop: "0"}} 
                        onClick={() => this.checkCardNumber(values.cardNumber)}>
                          Проверить</Button>
                    </Form.Group>

                    <Form.Group as={Row} controlId="clientInfo">
                      <Form.Label column md={4}></Form.Label>
                      <Col md={8}>
                        {this.state.cardError 
                          ? <div class="text-danger">Такой карты не существует!</div> 
                          : ''}
                        {this.state.cardOwnerId 
                          ? <div>ФИО клиента: {this.state.cardOwnerName}</div> 
                          : ''}
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="sertificateGamezones">
                      <Form.Label column md={4}>Кол-во игровых зон</Form.Label>
                      <Col md={5}>
                        <Form.Control
                          type="number"
                          placeholder="Кол-во"
                          name="gamezones"
                          value={values.gamezones}
                          disabled={values.rent}
                          onChange={e => {
                            e.preventDefault();
                            const { value } = e.target;
                            const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                            if (regex.test(value.toString())) {
                              setFieldValue("gamezones", value);
                            }
                          }}
                          isInvalid={!!(errors.gamezones && touched.gamezones)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.gamezones}
                        </Form.Control.Feedback>
                      </Col>

                      <Form.Label column md={2}>Аренда</Form.Label>
                      <Col md={1}>
                        <Form.Control
                          type="checkbox"
                          name="rent"
                          checked={values.rent}
                          value={values.rent}
                          onChange={e => {
                            setFieldValue("rent", !values.rent);
                            if (!values.rent){
                              values.gamezones = '';
                              this.setState({
                                gamezonesDisabled: false
                              });
                            }
                            else{
                              values.gamezones = '';
                              this.setState({
                                gamezonesDisabled: true
                              });
                            }
                          }}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="sertificateDuration">
                      <Form.Label column md={4}>Продолжительность</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type="number"
                          placeholder="мин"
                          name="duration"
                          value={values.duration}
                          step={10}
                          onChange={e => {
                            e.preventDefault();
                            const { value } = e.target;
                            const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                            if (regex.test(value.toString())) {
                              setFieldValue("duration", value);
                            }
                          }}
                          isInvalid={!!(errors.duration && touched.duration)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.duration}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="sertificateCost">
                      <Form.Label column md={4}>Стоимость</Form.Label>
                      <Col md={4}>
                        <Form.Control
                          type="number"
                          placeholder="Стоимость"
                          name="cost"
                          value={values.cost}
                          onChange={e => {
                            e.preventDefault();
                            const { value } = e.target;
                            const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                            if (regex.test(value.toString())) {
                              setFieldValue("cost", value);
                            }
                          }}
                          isInvalid={!!(errors.cost && touched.cost)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.cost}
                        </Form.Control.Feedback>
                      </Col>

                      <Form.Label column md={3}>Наличный р-т</Form.Label>
                      <Col md={1}>
                        <Form.Control
                          type="checkbox"
                          name="paymentCash"
                          checked={values.cash}
                          value={values.cash}
                          onChange={e => {
                            setFieldValue("cash", !values.cash);
                          }}
                        />
                      </Col>
                    </Form.Group>

                    {this.state.numberError 
                      ? <div class="text-danger">Сертификат с таким номером уже существует!</div> 
                      : ''}
                      
                    <div class="d-flex flex-row-reverse">
                      <Button type="submit">Создать</Button>
                      <Button variant="secondary" onClick={() => this.close()}>Отмена</Button>
                    </div>
                </Form>
              )}
            </Formik>

          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}

export default AddSertificateForm;
