import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import AddTransactionForm from '../moderatorPage/moderatorTransactions/moderatorAddTransaction';
import {baseUrl} from '../common/constants';
import '../css/bootstrap-4-3-1.css';
import '../css/styles.css';
import '../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'react-datetime/css/react-datetime.css';

let moment = require('moment');
require('moment/locale/ru');

function multilineCell(cell, row) {
    return "<textarea class='form-control cell' rows='2'>" + cell +"</textarea>";
}

class UserTransactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoaded: false,
        data:[],
        sortName: undefined,
        sortOrder: undefined,
        branches: [],
        users: [],
        transactionTypes: [],
        branch: '0',
        user: '0',
        totalCost: 0
    };
    this.totalCost = 0;
    this.onSortChange = this.onSortChange.bind(this);
    this.sumFormatter = this.sumFormatter.bind(this);
    this.handleToUpdate = this.handleToUpdate.bind(this);
    this.updateData = this.updateData.bind(this);
    this.getTransactionTypes = this.getTransactionTypes.bind(this);
  }

  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }

  dateFormatter(cell, row, enumObject) {
    return(
      <React.Fragment>
        {moment(cell).format("DD.MM.YYYY")}
      </React.Fragment>
    )
  }

  sumFormatter(cell, row, enumObject) {
    let res = `<div class="text-success">+` + cell + ` руб.</div>`;
    if (row.type === 0){
      res = `<div class="text-danger">-` + cell + ` руб.</div>`;
      this.totalCost = this.totalCost - parseInt(cell)
    }
    else{
      this.totalCost = this.totalCost + parseInt(cell);
    }
    return(
      <div
        dangerouslySetInnerHTML={{
          __html: res
        }}></div>
    )
  }

  descriptionFormatter(cell, row, enumObject) {
    let value = cell;
    if (row.type === 3){
      value = 'Покупка сертификата №' + cell
    }
    if (row.type === 2){
      value = 'Посещение клиентом'
    }
    return(multilineCell(value, row));
  }

  getBranches(){
    return fetch("/getBranches")
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            this.setState({
              branches: data
            });
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  getTransactionTypes(){
    return fetch("/getTransactionTypes")
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            this.setState({
              transactionTypes: data
            });
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  getUsers(){
    return fetch("/getUsers")
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            this.setState({
              users: data
            });
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  branchFormatter(cell, row, enumObject) {
    if (cell === null || cell === 0){
      return ''
    }
    return(
      <Row>
        {this.state.branches
          .filter(branch => branch.id === cell)[0].name}
      </Row>
    )
  }

  updateData(){
    let temp = 0;

    Promise.all([
      this.getBranches(),
      this.getUsers(),
      this.getTransactionTypes()
    ])
      .then(
        fetch("/getTransactions",
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({branch_id: this.props.branch_id})
          })
          .then(res => res.json())
          .then(data => {
              if (data.code === 5){
                this.props.quit();
              }
              else{
                data.forEach(item => {
                  if (item.type === 0){
                    temp -= item.cost
                  }
                  else{
                    temp += item.cost;
                  }
                })
                this.setState({
                  isLoaded: true,
                  data: data,
                  totalCost: temp
                });
              }
            },
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
          )
    )
  }

  componentDidMount() {
    this.updateData();
  }

  handleToUpdate(){
    this.updateData();
    this.forceUpdate()
  }

  normalize(inputArray){
    let dict = {};
    inputArray.map(item => dict.item[0] = item[1]);
    return dict;
  }

  render() {
    const options = {
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      onSortChange: this.onSortChange,
      noDataText: 'Данные не найдены'
    };

    let sumStyle = '<div class="text-success">';
    if (this.state.totalCost < 0){
      sumStyle = '<div class="text-danger">'
    }

    return (
      <React.Fragment>
        <Row>
          <div class="col-md-4" dangerouslySetInnerHTML={{

              __html: '<div class="row"><div class="row">Итоговая сумма: &nbsp;' + 
                sumStyle + this.state.totalCost + ' руб.</div></div></div>'
            }}>
          </div>

          <Col md={2} style={{marginLeft: "10%"}}>
            <AddTransactionForm
              handleToUpdate = {this.handleToUpdate}
              branches = {this.state.branches} 
              branch_id = {this.props.branch_id} 
              transaction_types = {this.state.transactionTypes} 
              quit = {this.props.quit} />
          </Col>

          <Col md={2} style={{marginLeft: "10%"}}>
            <a href={baseUrl+"/getUserReport"}> 
              <Button variant='primary' block >Скачать отчет</Button>
            </a>
          </Col>
        </Row>

        <BootstrapTable data={this.state.data} options={options} pagination striped hover >
          <TableHeaderColumn 
            dataField='date' 
            dataAlign ='center'
            dataFormat={this.dateFormatter}
            dataSort
            headerAlign='center'
            width='160'>
              Дата
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='cost'
            dataSort
            headerAlign='center'
            dataAlign='center' 
            dataFormat={this.sumFormatter}>
              Сумма
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='description'
            headerAlign='center'
            dataAlign='left' 
            dataFormat={this.descriptionFormatter}>
              Описание
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='user'
            headerAlign='center'
            dataAlign='left'>
              Сотрудник
          </TableHeaderColumn>

          <TableHeaderColumn 
            dataField='type' 
            hidden>
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='transaction_id'
            hidden
            isKey>
          </TableHeaderColumn>
        </BootstrapTable>
      </React.Fragment>
    )
  }
}

export default UserTransactions;
