import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import AddBranchForm from './moderatorAddBranch';
import UpdateBranchForm from './moderatorUpdateBranch';
import '../../css/bootstrap-4-3-1.css';
import '../../css/styles.css';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

class Branches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoaded: false,
        data:[],
        sortName: undefined,
        sortOrder: undefined,
    };
    this.onSortChange = this.onSortChange.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
    this.handleToUpdate = this.handleToUpdate.bind(this);
    this.updateData = this.updateData.bind(this);
    this.lockBranch = this.lockBranch.bind(this);
    this.unlockBranch = this.unlockBranch.bind(this);
  }

  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder,
      quoteError: false
    });
  }

  closeModal() {
    this.setState({quoteError: false});
  }

  actionFormatter(cell, row, enumObject) {
    let button;
    if (row.state === 0){
      button = <Button variant="danger" 
        onClick={() => this.lockBranch(row.branch_id)}>Закрыть</Button>
    }
    if (row.state === 1){
      button = <Button variant="primary" 
        onClick={() => this.unlockBranch(row.branch_id)}>Открыть</Button>
    }
    return(
      <Row>
        <Col md={6}>
          <UpdateBranchForm handleToUpdate = {this.handleToUpdate} 
            data = {{'name': row.name, 
              'adress': row.adress, 'branch_id': row.branch_id}} 
            quit = {this.props.quit} />
        </Col>
        <Col md={6}>
          {button}
        </Col>
      </Row>
    )
  }

  stateFormatter(cell, row, enumObject) {
    if (row.state === 0){
      return(
        <Button variant="success" disabled block>
          Активен</Button>
      )
    }
    if (row.state === 1){
      return(
        <Button variant="danger" disabled block>
          Заблокирован</Button>
      )
    }
  }

  onComponentWillMount() {
    this.updateData()
  }

  updateData(){
    fetch("/getBranches")
      .then(res => res.json())
      .then(
        result => {
          if (result.code === 5){
            this.props.quit();
          }
          else{
            this.setState({
              isLoaded: true,
              data: result
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  lockBranch(branch_id){
    fetch("/lockBranchById",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({'branch_id': branch_id})
      })
      .then(res => res.json())
      .then(data => {
        if (data.code === 5){
          this.props.quit();
        }
        else{
          this.handleToUpdate();
        }
      })
      .catch(error => console.error(error))
  }

  unlockBranch(branch_id){
    fetch("/unlockBranchById",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({'branch_id': branch_id})
      })
      .then(res => res.json())
      .then(data => {
        if (data.code === 5){
          this.props.quit();
        }
        else{
          if (data.code === 2){
            this.setState({quoteError: true})
          }
          this.handleToUpdate();
        }
      })
      .catch(error => console.error(error))
  }

  componentDidMount() {
    this.updateData();
  }

  handleToUpdate(){
    this.updateData();
    this.forceUpdate()
  }

  render() {
    const options = {
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      onSortChange: this.onSortChange,
      noDataText: 'Данные не найдены'
    };

    return (
      <React.Fragment>
        <Modal size="md" show={this.state.quoteError} 
          onHide={() => this.closeModal()}>
          <Modal.Body>
            Превышена квота создания филиалов!
            <div class="d-flex flex-row-reverse">
              <Button variant="primary" onClick={() => this.closeModal()}>
                ОК
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <BootstrapTable data={this.state.data} options={options} pagination striped hover>
          <TableHeaderColumn 
            isKey
            dataField='branch_id'
            width='50'
            dataAlign ='center'
            dataSort 
            headerAlign='center'>
              ID
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='name'
            dataSort
            headerAlign='center'
            filter={{type: 'TextFilter', delay: 1000,
              placeholder: 'Введите название'}}>
              Название
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='adress' 
            width='500'
            filter={{type: 'TextFilter', delay: 1000,
              placeholder: 'Введите адрес'}}
            headerAlign='center' 
            dataAlign='left'>
              Адрес
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='state' 
            dataFormat={this.stateFormatter} 
            headerAlign='center' 
            dataAlign='center'
            filter={{type: 'SelectFilter', placeholder: 'Выберите',
              options: {0: 'Активный', 1: 'Заблокированный'}}}>
              Статус
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='branch_id' 
            dataFormat={this.actionFormatter} 
            dataAlign='center' 
            width='240'>
            <AddBranchForm handleToUpdate = {this.handleToUpdate} 
              quit = {this.props.quit} />
          </TableHeaderColumn>
        </BootstrapTable>
      </React.Fragment>
    )
  }
}

export default Branches;
