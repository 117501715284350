import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Picky from 'react-picky';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import * as yup from 'yup'
import { Formik, Field } from 'formik';
import '../../css/bootstrap-4-3-1.css';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'react-picky/dist/picky.css';
import '../../css/styles.css'
import MaskedInput from 'react-text-mask';

const phoneNumberMask = [
  "+",
  "7",
  " ",
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const dateMask = [
  /[0-3]/,
  /[0-9]/,
  ".",
  /[0-1]/,
  /[0-9]/,
  ".",
  /[1-2]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/
];

let moment = require('moment');
require('moment/locale/ru');

function multilineCell(cell, row) {
    return "<textarea class='form-control cell' rows='2'>" + 
      cell +"</textarea>";
}

class UpdateClientForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      numberError: false,
      phoneError: false,
      behaviors: [],
      attractions: [],
      categories: [],
      selectedBehaviors: [],
      selectedAttractions: [],
      spendings: []
    }
    this.show = this.show.bind(this);
    this.getBehaviors = this.getBehaviors.bind(this);
    this.getAttractions = this.getAttractions.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.getSpendings = this.getSpendings.bind(this);
    this.selectBehavior = this.selectBehavior.bind(this);
    this.selectAttraction = this.selectAttraction.bind(this);
    console.log(props.data)
  }

  getSchema(){
    return yup.object({
      name: yup.string().required('ФИО обязательное поле!'),
      mail: yup.string().email('Введен невалидный почтовый адрес!'),
      comment: yup.string().nullable().max(1000, 'Комментарий не должен превышать 1000 символов!'),
    });
  }

  dateFormatter(cell, row, enumObject) {
    return(
      <React.Fragment>
        {moment(cell).format("DD.MM.YYYY")}
      </React.Fragment>
    )
  }

  branchFormatter(cell, row, enumObject) {
    let res = '';
    if (row.type === 1){
      res = row.branch
    }
    if (row.type === 2){
      res = 'Сертификат №' + row.sertificate
    }
    if (row.type === 4){
      res = 'Предоплата'
    }
    return(
      <React.Fragment>
        {res}
      </React.Fragment>
    )
  }

  costFormatter(cell, row, enumObject) {
    return(
      <React.Fragment>
        {row.sertificate && row.type === 1 
          ? row.cost + " (Сертификат №" + row.sertificate + ")" 
          : row.cost}
      </React.Fragment>
    )
  }

  gamesFormatter(cell, row, enumObject) {
    let value = "";
    cell.forEach(item => value = value + item + '; ')
    return (multilineCell(value.substring(0, value.length - 2), row));
  }

  selectBehavior(value) {
    this.setState({ selectedBehaviors: value });
  }

  selectAttraction(value) {
    this.setState({ selectedAttractions: value });
  }

  close() {
    this.setState({show: false});
  }

  commentFormatter(cell){
    return (multilineCell(cell));
  }

  getSpendings(){
    return fetch("/getClientSpendings",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({client_id: this.props.data.client_id})
      })
      .then(res => res.json())
      .then(
        data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            this.setState({
              spendings: data
            });
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  getBehaviors(){
    return fetch("/getAvailableBehaviors",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({client_id: this.props.data.client_id})
      })
      .then(res => res.json())
      .then(
        data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            if (data.code === 0){
              this.setState({
                behaviors: []
              });
            }
            else{
              this.setState({
                behaviors: data
              });
            }
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  getAttractions(){
    return fetch("/getAvailableAttractions",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({client_id: this.props.data.client_id})
      })
      .then(res => res.json())
      .then(
        data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            if (data.code === 0){
              this.setState({
                attractions: []
              });
            }
            else{
              this.setState({
                attractions: data
              });
            }
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  getCategories(){
    return fetch("/getAvailableClientCategories",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({client_id: this.props.data.client_id})
      })
      .then(res => res.json())
      .then(
        data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            if (data.code === 0){
              this.setState({
                types: []
              });
            }
            else{
              this.setState({
                categories: data
              });
            }
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
    )
  }

  show() {
    Promise.all([
      this.getBehaviors(),
      this.getAttractions(),
      this.getCategories(),
      this.getSpendings()
    ])
    .then(res => {
      let attractions = [];
      if (this.props.data.attractions.length > 0){
        this.props.data.attractions
          .map(item => attractions.push(
              {attraction_id: item, value: this.state.attractions
                .filter(attraction => attraction.attraction_id === item)[0].value}
          ))
        }
      let behaviors = [];
      if (this.props.data.behaviors.length > 0){
        this.props.data.behaviors
          .map(item => behaviors.push(
              {behavior_id: item, value: this.state.behaviors
                .filter(behavior => behavior.behavior_id === item)[0].value}
          ))
      }
      this.setState({
        show: true,
        numberError: false,
        phoneError: false,
        selectedBehaviors: behaviors,
        selectedAttractions: attractions
      })
    })
  }

  onSubmit(values){
    let handleToUpdate = this.props.handleToUpdate;
    values.client_id = this.props.data.client_id;
    values.behaviors = this.state.selectedBehaviors;
    values.attractions = this.state.selectedAttractions;

    fetch("/updateClient",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(values)
      })
      .then(res => res.json())
      .then(data => {
        if (data.code === 5){
          this.props.quit();
        }
        else{
          if (data.code === 2){
            this.setState({'numberError': true})
          }
          else{
            if (data.code === 3){
              this.setState({'phoneError': true})
            }
            else{
              this.close();
              handleToUpdate();
            }
          }
        }
      })
      .catch(error => console.error(error))
  }

  render() {
    const options = {
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      onSortChange: this.onSortChange,
      noDataText: 'Данные не найдены'
    };

    return (
      <React.Fragment>
        <Button variant='primary' onClick={ () => this.show() } block>
          Информация</Button>
        <Modal size="lg" show={this.state.show} onHide={() => this.close()}>
          <Modal.Header closeButton>
            <Modal.Title>Обновить информацию о клиенте</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Formik
              validationSchema={this.getSchema()}
              onSubmit={(values) => {
                  this.onSubmit(values);
                }
              }

              initialValues={{
                name: this.props.data.name, 
                number: this.props.data.number, 
                mail: this.props.data.mail, 
                phone: this.props.data.phone, 
                birthdate: this.props.data.birthdate, 
                birthdate2: this.props.data.birthdate2, 
                birthdate3: this.props.data.birthdate3, 
                mailSend: this.props.data.mailSend,
                client_category_id: this.props.data.client_category_id ?
                  this.props.data.client_category_id : '0',
                comment: this.props.data.comment, 
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="clientNumber">
                      <Form.Label column md={4}>Номер карты</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          placeholder="Номер"
                          name="number"
                          value={values.number}
                          onChange={handleChange}
                          isInvalid={!!(errors.number && touched.number)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.number}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="clientName">
                      <Form.Label column md={4}>ФИО</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          placeholder="ФИО"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!(errors.name && touched.name)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="clientPhone">
                      <Form.Label column md={4}>Телефон</Form.Label>
                      <Col md={8}>
                        <Field
                          name="phone"
                          render={({ field }) => (
                            <MaskedInput
                              {...field}
                              mask={phoneNumberMask}
                              id="phone"
                              placeholder="Телефон"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.phone && touched.phone
                                  ? "text-input error"
                                  : "text-input"
                              }
                            />
                          )}
                        />
                      {errors.phone && touched.phone ? 
                        <div class="text-danger">{errors.phone}</div> : ''}
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="mail">
                      <Form.Label column md={4}>E-mail</Form.Label>
                      <Col md={4}>
                        <Form.Control
                          type="email"
                          placeholder="Почта"
                          name="mail"
                          value={values.mail}
                          onChange={handleChange}
                          isInvalid={!!(errors.mail && touched.mail)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.mail}
                        </Form.Control.Feedback>
                      </Col>

                      <Col md={2}>
                        <Form.Control
                          type="checkbox"
                          name="mailSend"
                          checked={values.mailSend}
                          value={values.mailSend}
                          onChange={handleChange}
                        />
                      </Col>
                      <Form.Label column md={2}>Рассылка</Form.Label>
                    </Form.Group>

                    <Form.Group as={Row} controlId="clientBirthdate">
                      <Form.Label column md={4}>Дата рождения</Form.Label>
                      <Col md={8}>
                        <Field
                          name="birthdate"
                          render={({ field }) => (
                            <MaskedInput
                              {...field}
                              mask={dateMask}
                              id="phone"
                              placeholder="Дата"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.birthdate && touched.birthdate
                                  ? "text-input error"
                                  : "text-input"
                              }
                            />
                          )}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="clientBirthdate2">
                      <Form.Label column md={4}>Дата рождения (доп)</Form.Label>
                      <Col md={8}>
                        <Field
                          name="birthdate2"
                          render={({ field }) => (
                            <MaskedInput
                              {...field}
                              mask={dateMask}
                              id="phone"
                              placeholder="Дата"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.birthdate2 && touched.birthdate2
                                  ? "text-input error"
                                  : "text-input"
                              }
                            />
                          )}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="clientBirthdate3">
                      <Form.Label column md={4}>Дата рождения (доп)</Form.Label>
                      <Col md={8}>
                        <Field
                          name="birthdate3"
                          render={({ field }) => (
                            <MaskedInput
                              {...field}
                              mask={dateMask}
                              id="phone"
                              placeholder="Дата"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.birthdate3 && touched.birthdate3
                                  ? "text-input error"
                                  : "text-input"
                              }
                            />
                          )}
                        />
                      </Col>
                    </Form.Group> 

                    <Form.Group as={Row} controlId="client_category_id">
                      <Form.Label column md={4}>Категория</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          as="select"
                          name="client_category_id"
                          value={values.client_category_id}
                          onChange={handleChange}
                          isInvalid={!!(errors.client_category_id 
                            && touched.client_category_id)}
                        >
                          <option value='0'>Не указана</option>
                          {
                            Object.keys(this.state.categories)
                              .map((key, index) => (
                                  <option value={this.state
                                    .categories[key].client_category_id}>
                                {this.state.categories[key].value}</option>
                          ))
                        }

                        </Form.Control>

                          <Form.Control.Feedback type="invalid">
                            {errors.client_category_id}
                          </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="behavior_id">
                      <Form.Label column md={4}>Модель поведения</Form.Label>
                      <Col md={8}>
                        <Picky
                          value={this.state.selectedBehaviors}
                          multiple={true}
                          options={
                            Object.keys(this.state.behaviors)
                              .map((key, index) =>
                              {
                                let dict = {};
                                dict.behavior_id = 
                                  this.state.behaviors[key].behavior_id;
                                let behaviorString = this.state.behaviors
                                  .filter(value => value.behavior_id === 
                                      this.state.behaviors[key].behavior_id)
                                  .map(item => item.value)
                                dict.value = behaviorString;
                                return dict;
                              })
                            }
                          valueKey="behavior_id"
                          labelKey="value"
                          includeSelectAll={false}
                          includeFilter={true}
                          onChange={this.selectBehavior}
                          onBlur={handleBlur}
                          dropdownHeight={600}
                          id="behaviors"
                          name="behaviors"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="attraction_id">
                      <Form.Label column md={4}>Канал привлечения</Form.Label>
                      <Col md={8}>
                        <Picky
                          value={this.state.selectedAttractions}
                          multiple={true}
                          options={
                            Object.keys(this.state.attractions)
                              .map((key, index) =>
                              {
                                let dict = {};
                                dict.attraction_id = 
                                  this.state.attractions[key].attraction_id;
                                let attractionString = this.state.attractions
                                  .filter(value => value.attraction_id === 
                                    this.state.attractions[key].attraction_id)
                                  .map(item => item.value)
                                dict.value = attractionString;
                                return dict;
                              })
                            }
                          valueKey="attraction_id"
                          labelKey="value"
                          includeSelectAll={false}
                          includeFilter={true}
                          onChange={this.selectAttraction}
                          onBlur={handleBlur}
                          dropdownHeight={600}
                          id="attractions"
                          name="attractions"
                        />

                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="comment">
                      <Form.Label column md={4}>Коментарий</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type='text'
                          placeholder="Комментарий"
                          name="comment"
                          value={values.comment}
                          onChange={handleChange}
                          isInvalid={!!(errors.comment && touched.comment)}
                          as="textarea" 
                          rows="3"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.comment}
                        </Form.Control.Feedback>
                      </Col>

                      {/* <Form.Label column md={2}>Рассылка</Form.Label> */}
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column md={4}></Form.Label>
                      <Col md={8}>
                        {this.state.numberError 
                          ? <div class="text-danger">
                              Клиент с такой картой уже существует!
                            </div> 
                          : ''}
                        {this.state.phoneError 
                          ? <div class="text-danger">
                              Клиент с таким телефоном уже существует!
                            </div>
                          : ''}
                      </Col>
                    </Form.Group>

                    <BootstrapTable 
                      data={this.state.spendings} 
                      options={options} 
                      pagination 
                      striped 
                      hover>
                      <TableHeaderColumn  
                        dataField='date' 
                        width='160' 
                        dataSort 
                        dataFormat={ this.dateFormatter }
                        filter={{ type: 'TextFilter', delay: 1000,
                          placeholder: 'Введите дату'}}
                        headerAlign='center'>
                          Дата
                      </TableHeaderColumn>
                      <TableHeaderColumn 
                        dataField='branch' 
                        dataSort 
                        headerAlign='center' 
                        dataFormat={ this.branchFormatter }
                        filter={{type: 'TextFilter', delay: 1000, 
                          placeholder: 'Введите филиал'}}>
                          Филиал/Сертификат
                      </TableHeaderColumn>
                      <TableHeaderColumn 
                        dataField='cost' 
                        headerAlign='center' 
                        dataSort 
                        dataFormat={ this.costFormatter }>
                          Стоимость
                      </TableHeaderColumn>
                      <TableHeaderColumn 
                        dataField='games' 
                        headerAlign='center' 
                        dataSort 
                        dataFormat={ this.gamesFormatter }
                        filter={{type: 'TextFilter', delay: 1000, 
                          placeholder: 'Введите название'}}>
                          Игры
                      </TableHeaderColumn>
                      <TableHeaderColumn 
                        dataField='type' 
                        hidden>
                      </TableHeaderColumn>
                      <TableHeaderColumn 
                        dataField='sertificate' 
                        hidden>
                      </TableHeaderColumn>
                      <TableHeaderColumn 
                        dataField='local_id' 
                        hidden
                        isKey>
                      </TableHeaderColumn>
                    </BootstrapTable>

                    <div class="d-flex flex-row-reverse">
                      <Button type="submit">Сохранить</Button>
                      <Button variant="secondary" 
                        onClick={() => this.close()}>Отмена</Button>
                    </div>
                </Form>
              )}
            </Formik>

          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}

export default UpdateClientForm;
