import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'; 
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import AddUserForm from './moderatorAddUser';
import UpdateUserForm from './moderatorUpdateUser';
import '../../css/bootstrap-4-3-1.css';
import '../../css/styles.css';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoaded: false,
        data:[],
        branches: [],
        sortName: undefined,
        sortOrder: undefined,
        error: false,
        errorText: ''
    };
    this.onSortChange = this.onSortChange.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
    this.roleFormatter = this.roleFormatter.bind(this);
    this.handleToUpdate = this.handleToUpdate.bind(this);
    this.stateFormatter = this.stateFormatter.bind(this);
    this.branchFormatter = this.branchFormatter.bind(this);
    this.updateData = this.updateData.bind(this);
  }

  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }

  closeModal() {
    this.setState({error: false});
  }

  lockUser(user_id){
    fetch("/lockUserById",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({'user_id': user_id})
      })
      .then(res => res.json())
      .then(data => {
        if (data.code === 5){
          this.props.quit();
        }
        else{
          if (data.code === 7){
            this.setState({error: true, 
              errorText: 'Вы не можете заблокировать сами себя! ' +
                'Обратитесь к другому модератору или администраторам'})
          }
          else{
            this.handleToUpdate();
          }
        }
      })
      .catch(error => console.error(error))
  }

  unlockUser(user_id){
    fetch("/unlockUserById",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({'user_id': user_id})
      })
      .then(res => res.json())
      .then(data => {
        if (data.code === 5){
          this.props.quit();
        }
        else{
          if (data.code === 2){
            this.setState({error: true, 
              errorText: 'Превышена квота пользователей (или модераторов)!'})
          }
          if (data.code === 7){
            this.setState({error: true, 
              errorText: 'Вы не можете разблокировать сами себя!' +
               'Обратитесь к другому модератору или администраторам'})
          }
          else{
            this.handleToUpdate();
          }
        }
      })
      .catch(error => console.error(error))
  }

  actionFormatter(cell, row, enumObject) {
    let button;
    if (row.state === 0){
      button = <Button variant="danger" 
        onClick={() => this.lockUser(row.user_id)}>Закрыть</Button>
    }
    if (row.state === 1){
      button = <Button variant="primary" 
        onClick={() => this.unlockUser(row.user_id)}>Открыть</Button>
    }
    return(
      <Row>
        <Col md={6}>
          <UpdateUserForm 
            handleToUpdate = {this.handleToUpdate} 
            data = {{'name': row.name, 'user_role': row.user_role,
              'branch': row.branch, 'email': row.email, 
              'user_id': row.user_id}} 
            quit = {this.props.quit} />
        </Col>
        <Col md={6}>
          {button}
        </Col>
      </Row>
    )
  }

  stateFormatter(cell, row, enumObject) {
    if (row.state === 0){
      return(
        <Button variant="success" disabled block>
          Активен</Button>
      )
    }
    if (row.state === 1){
      return(
        <Button variant="danger" disabled block>
          Заблокирован</Button>
      )
    }
  }

  roleFormatter(cell, row, enumObject) {
    if (row.role === 1){
      return(
        <Button variant="success" disabled block>
          Модератор</Button>
      )
    }
    if (row.role === 2){
      return(
        <Button variant="primary" disabled block>
          Пользователь</Button>
      )
    }
  }

  branchFormatter(cell, row, enumObject) {
    if (cell){
      return(
        this.state.branches.filter(item => item.branch_id === cell)[0].name
      )
    }
    return('')
  }

  onComponentWillMount() {
    this.updateData()
  }

  updateData(){
    fetch("/getBranches")
    .then(res => res.json())
    .then(data => {
        if (data.code === 5){
          this.props.quit();
        }
        else{
          this.setState({
            isLoaded: true,
            branches: data
          });
        }
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
    .then(() =>
      fetch("/getUsers")
        .then(res => res.json())
        .then(
          (data) => {
            if (data.code === 5){
              this.props.quit();
            }
            else{
              this.setState({
                isLoaded: true,
                data: data
              });
            }
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
      )
  }

  componentDidMount() {
    this.updateData();
  }

  handleToUpdate(){
    this.updateData();
    this.forceUpdate()
  }

  render() {
    const options = {
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      onSortChange: this.onSortChange,
      noDataText: 'Данные не найдены'
    };

    return (
      <React.Fragment>
        <Modal size="md" show={this.state.error} 
          onHide={() => this.closeModal()}>
          <Modal.Body>
            {this.state.errorText}
            <div class="d-flex flex-row-reverse">
              <Button variant="primary" onClick={() => this.closeModal()}>
                ОК
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <BootstrapTable data={this.state.data} options={options} pagination striped hover>
          <TableHeaderColumn 
            isKey
            dataField='user_id'
            width='60'
            dataAlign ='center'
            dataSort
            headerAlign='center'>
              ID
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='name'
            dataSort
            headerAlign='center'
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите имя'}}>
              Имя пользователя
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='user_role' 
            dataSort
            dataFormat={this.roleFormatter} 
            headerAlign='center'
            width='150'
            filter={{type: 'SelectFilter', placeholder: 'Выберите',
              options: {1: 'Модератор', 2: 'Пользователь'}}}>
              Роль
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='branch_id'
            dataSort
            dataFormat={this.branchFormatter} 
            headerAlign='center'
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите название'}}>
              Филиал
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='email'
            headerAlign='center'
            filter={{type: 'TextFilter', delay: 1000, 
              placeholder: 'Введите почту' } }>
              Почта
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='state'
            headerAlign='center' 
            width='160'
            dataFormat={this.stateFormatter}
            dataAlign='center'
            filter={{type: 'SelectFilter', placeholder: 'Выберите',
              options: {0: 'Активен', 1: 'Заблокирован'}}}>
              Статус
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='user_id'
            dataFormat={this.actionFormatter} 
            dataAlign='center' 
            width='240'>
              <AddUserForm handleToUpdate = {this.handleToUpdate} 
                quit = {this.props.quit} />
          </TableHeaderColumn>
        </BootstrapTable>
      </React.Fragment>
    )
  }
}

export default Users;
