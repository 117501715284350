import React from 'react';
import header from '../images/header.jpg'
import '../css/bootstrap-4-3-1.css'

class Header extends React.Component {
  render() {
    return (
      <img src={header} width="100%" alt='Header'></img>
    )
  }
}

export default Header;
