import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import AddModeratorForm from './adminAddUser';
import UpdateModeratorForm from './adminUpdateUser';
import history from '../../common/history';
import '../../css/bootstrap-4-3-1.css';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoaded: false,
        data:[],
        organizations: [],
        sortName: undefined,
        sortOrder: undefined,
        quoteError: false
    };
    this.onSortChange = this.onSortChange.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
    this.organizationFormatter = this.organizationFormatter.bind(this);
    this.handleToUpdate = this.handleToUpdate.bind(this);
    this.updateData = this.updateData.bind(this);
    this.updateOrganizationData = this.updateOrganizationData.bind(this);
  }

  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }

  closeModal() {
    this.setState({quoteError: false});
  }

  lockUser(user_id){
    fetch("/lockUserById",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({'user_id': user_id, 'user_role': 1})
      })
      .then(data => {
        if (data.code === 5){
          history.push('/login')
        }
        else{
          this.handleToUpdate();
        }
      })
      .catch(error => console.error(error))
  }

  unlockUser(user_id){
    fetch("/unlockUserById",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({'user_id': user_id, 'user_role': 1})
      })
      .then(res => res.json())
      .then(data => {
        if (data.code === 5){
          history.push('/login')
        }
        else{
          if (data.code === 2){
            this.setState({quoteError: true})
          }
          this.handleToUpdate();
        }
      })
      .catch(error => console.error(error))
  }

  actionFormatter(cell, row, enumObject) {
    let button;
    if (row.state === 0){
      button = <Button variant="danger" 
        onClick={() => this.lockUser(row.user_id)}>
          Закрыть
        </Button>
    }
    if (row.state === 1){
      button = <Button variant="primary" 
        onClick={() => this.unlockUser(row.user_id)}>
          Открыть
        </Button>
    }
    return(
      <Row>
        <Col md={6}>
          <UpdateModeratorForm handleToUpdate = {this.handleToUpdate} 
            organizations = {this.state.organizations}
            data = {{
              'name': row.name, 
              'mail': row.email, 
              'user_id': row.user_id,
              'organization_id': row.organization_id, 
              'password': '******'
            }}/>
        </Col>
        <Col md={6}>
          {button}
        </Col>
      </Row>
    )
  }

  stateFormatter(cell, row, enumObject) {
    if (row.state === 0){
      return(
        <Button variant="success" disabled block>
          Активен</Button>
      )
    }
    if (row.state === 1){
      return(
        <Button variant="danger" disabled block>
          Заблокирован</Button>
      )
    }
  }

  organizationFormatter(cell, row, enumObject) {
    return(
      this.state.organizations
        .filter(organization => organization.organization_id === cell)[0].name
    )
  }

  onComponentWillMount() {
    this.updateData()
  }

  updateData(){
    Promise.all([
      this.updateOrganizationData(),
    ])
    .then(result => {
      fetch("/getModerators")
        .then(res => res.json())
        .then((data) => {
            if (data.code === 5){
              history.push('/login')
            }
            else{
              this.setState({
                isLoaded: true,
                data: data
              });
            }
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
      })
  }

  updateOrganizationData(){
    fetch("/getOrganizations")
      .then(res => res.json())
      .then((data) => {
          if (data.code === 5){
            history.push('/login')
          }
          else{
            this.setState({
              isLoaded: true,
              organizations: data
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  componentDidMount() {
    this.updateData();
  }

  handleToUpdate(){
    this.updateData();
    this.forceUpdate()
  }

  render() {
    const options = {
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      onSortChange: this.onSortChange,
      noDataText: 'Данные не найдены'
    };

    return (
      <React.Fragment>
        <Modal size="md" show={this.state.quoteError} 
          onHide={() => this.closeModal()}>
          <Modal.Body>
            Превышена квота создания модераторов!
            <div class="d-flex flex-row-reverse">
              <Button variant="primary" onClick={() => this.closeModal()}>
                ОК
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <BootstrapTable 
          data={this.state.data} 
          options={options} 
          pagination 
          striped 
          hover>
          <TableHeaderColumn 
            isKey 
            dataField='user_id'
            width='60'
            dataAlign ='center'
            dataSort
            headerAlign='center'>
              ID
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='name' 
            dataSort 
            headerAlign='center'
            filter={{ 
              type: 'TextFilter', 
              delay: 1000, 
              placeholder: 'Введите имя'
            }}>
              Имя пользователя
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='organization_id' 
            dataSort 
            dataFormat={this.organizationFormatter} 
            headerAlign='center' 
            filter={{ 
              type: 'SelectFilter', 
              options: Object.assign({}, ...this.state.organizations
                .map(organization => ({[organization.organization_id]: organization.name}))),
              delay: 1000, 
              placeholder: 'Выберите организацию'
            }}>
              Организация
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='email' 
            headerAlign='center'
            filter={{ 
              type: 'TextFilter', 
              delay: 1000, 
              placeholder: 'Введите почту' 
            }}>
              Почта
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='state' 
            headerAlign='center' 
            width='160' 
            dataFormat={this.stateFormatter} 
            dataAlign='center'>
              Статус
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='user_id' 
            dataFormat={this.actionFormatter} 
            dataAlign='center'>
              <AddModeratorForm 
                handleToUpdate = {this.handleToUpdate} 
                organizations = {this.state.organizations} 
              />
          </TableHeaderColumn>
        </BootstrapTable>
      </React.Fragment>
    )
  }
}

export default Users;
