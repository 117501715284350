import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import history from '../../common/history';
import * as yup from 'yup';
import { Formik, Field } from 'formik';
import MaskedInput from "react-text-mask";
import '../../css/bootstrap-4-3-1.css';
import '../../css/styles.css';

const schema = yup.object({
  name: yup.string().required('Название организации обязательное поле!')
    .max(20, 'Название не должно превышать 20 символов!'),
  contactName: yup.string().required('Имя контактного лица обязательное поле!')
    .max(20, 'Имя контактного лица не должно превышать 20 символов!'),
  contactPhone: yup.string().required('Телефон конт. лица обязательное поле!')
    .max(20, 'Телефон конт. лица не должен превышать 20 символов!'),
});

const phoneNumberMask = [
  "+",
  "7",
  " ",
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

class AddOrganizationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      organizationNameError: false
    }
    this.show = this.show.bind(this);
  }

  show() {
    this.setState({
      show: true,
      organizationNameError: false
    });
  }

  close() {
    this.setState({show: false});
  }

  onSubmit(values){
    let handleToUpdate = this.props.handleToUpdate;
    values.organization_id = 0;
    values.role = 2
    fetch("/checkOrganizationName",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(values)
      })
      .then(res => res.json())
      .then((data) => {
        if (data.code === 5){
          history.push('/login')
        }
        if (data.code === 0){
          fetch("/addOrganization",
            {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              method: 'POST',
              body: JSON.stringify(values)
            })
            .then(data => {
              if (data.code === 5){
                history.push('/login')
              }
              else{
                this.close();
                handleToUpdate();
              }
            })
            .catch(error => console.error(error))
        }
        else{
          this.setState({organizationNameError: true});
        }
      })
      .catch(error => console.error(error))
  }

  render() {
    return (
      <React.Fragment>
        <Button letiant='outline-primary' onClick={ () => this.show() }>
          Новая организация</Button>
        <Modal size="lg" show={this.state.show} onHide={() => this.close()}>
          <Modal.Header closeButton>
            <Modal.Title>Создание новой организации</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Formik
              validationSchema={schema}
              onSubmit={(values) => {
                  this.onSubmit(values);
                }
              }

              initialValues={{
                name: '', 
                contactName: '', 
                contactPhone: '', 
                quotaModerator: '1', 
                quotaUser: '1', 
                quotaBranch: '1'
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="organizationName">
                      <Form.Label column md={4}>Название</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          placeholder="Название"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!(errors.name && touched.name)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="organizationСontactName">
                      <Form.Label column md={4}>Контактное лицо</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          placeholder="ФИО"
                          name="contactName"
                          value={values.contactName}
                          onChange={handleChange}
                          isInvalid={!!(errors.contactName 
                            && touched.contactName)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.contactName}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="organizationContactPhone">
                      <Form.Label column md={4}>Телефон конт. лица</Form.Label>
                      <Col md={8}>
                        <Field
                          name="contactPhone"
                          render={({ field }) => (
                            <MaskedInput
                              {...field}
                              mask={phoneNumberMask}
                              id="phone"
                              placeholder="Телефон"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.contactPhone && touched.contactPhone
                                  ? "text-input error"
                                  : "text-input"
                              }
                            />
                          )}
                        />
                      {errors.contactPhone && touched.contactPhone 
                        ? <div class="text-danger">
                            {errors.contactPhone}
                          </div> 
                        : ''}
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="organizationQuotaModerator">
                      <Form.Label column md={4}>Квота модераторов</Form.Label>
                      <Col md={8}>
                       <Form.Control
                          name="quotaModerator"
                          type="number"
                          value={values.quotaModerator}
                          onChange={(event) => {
                            let parsed = parseInt(event.target.value);
                            if (parsed > 0)
                            {
                              handleChange(event);
                            }
                          }}
                        />

                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="organizationQuotaUser">
                      <Form.Label column md={4}>Квота пользователей</Form.Label>
                      <Col md={8}>
                       <Form.Control
                          name="quotaUser"
                          type="number"
                          value={values.quotaUser}
                          onChange={(event) => {
                            let parsed = parseInt(event.target.value);
                            if (parsed > 0)
                            {
                              handleChange(event);
                            }
                          }
                        }
                        />

                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="organizationQuotaBranch">
                      <Form.Label column md={4}>Квота филиалов</Form.Label>
                      <Col md={8}>
                       <Form.Control
                          name="quotaBranch"
                          type="number"
                          value={values.quotaBranch}
                          onChange={(event) => {
                            let parsed = parseInt(event.target.value);
                            if (parsed > 0)
                            {
                              handleChange(event);
                            }
                          }
                        }
                        />

                      </Col>
                    </Form.Group>

                    {this.state.organizationNameError 
                      ? <div class="text-danger">
                          Организация с таким названием уже существует!
                        </div> 
                      : ''}

                    <div class="d-flex flex-row-reverse">
                      <Button type="submit">Создать</Button>
                      <Button letiant="secondary" 
                        onClick={() => this.close()}>Отмена</Button>
                    </div>
                </Form>
              )}
            </Formik>

          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}

export default AddOrganizationForm;
