import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as yup from 'yup';
import { Formik } from 'formik';
import '../css/bootstrap-4-3-1.css';
import 'react-picky/dist/picky.css';
import '../css/styles.css';
import Picky from 'react-picky';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

let moment = require('moment');
require('moment/locale/ru');

class AddVisitUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      moves: [],
      date: moment(),
      selectedGames: [],
      gamezonesTimes: [],
      sertificateInfo: null,
      sertificateError: 0,
      sertificateOk: false,
      sertificateOk: false,
      sertificateCostLeft: null,
      sertificateCostError: 0,
      sertificateCostOk: false,
    }
    this.show = this.show.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.selectGame = this.selectGame.bind(this);
    this.resetHandler = this.resetHandler.bind(this);
    this.updateData = this.updateData.bind(this);
  }

  getSchema(sertificateCostLeft){
    return yup.object({
      person: yup.number().required('Кол-во посетителей - обязательное поле!')
        .positive('Количество пользователей должно быть положительным числом'),
      cost: yup.number().required('Итоговая стоимость - обязательное поле!')
        .min(0, 'Итоговая стоимость должна быть положительным числом'),
      paymentCash: yup.string().oneOf(['1', '0'], 'Укажите тип расчета'),
      sertificateCost: yup.boolean().required(),
      sertificateCostCost: yup.string()
        .test('sert', 'Сумма не указана!', function(value){
          const sertificateCost = this.parent.sertificateCost;
          console.log(sertificateCost)
          return parseInt(value) > 0 || !sertificateCost
        })        
        .test('sert2', 'Превышен остаток сертификата!', function(value){
          const sertificateCost = this.parent.sertificateCost;
          return parseInt(value) <= sertificateCostLeft || !sertificateCost
        }),
    });
  }

  show() {
    Promise.all([
      this.updateData(),
    ])
    .then(res => {
      this.setState({
        show: true,
        date: moment(),
        sertificateError: 0,
        sertificateInfo: null,
        sertificateCostError: 0,
        sertificateCostLeft: null,
        sertificateCostOk: false,
        selectedGames: [],
        sertificateOk: false
      })
    })
  }

  close() {
    this.setState({
      show: false
    });
  }

  selectGame(value) {
    this.setState({ selectedGames: value });
  }

  handleDateChange(value){
    let d = moment(value, "DD.MM.YYYY", true);
    let y = isNaN(d.year());
    if (y){
      return
    }
    this.setState({date: d})
  }

  handleTimeChange(value){
    let d = moment(value, "HH:mm", true);
    let y = isNaN(d.hour());
    if (y){
      return
    }
    this.setState({time: d})
  }

  resetHandler(handler, e){
    let times = [];
    this.props.gamezones.filter(gamezone => gamezone.branch_id === e.target.value)
      .forEach(gamezone => {
        let time = {gamezone_id: gamezone.gamezone_id, time: ''}
        times.push(time);
      })
    this.setState({
      selectedGames: [],
      gamezonesTimes: times
    });
    handler(e);
  }

  gamezoneTimeChange(gamezone_id, e){
    const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
    if (!regex.test(e.target.value) && e.target.value !== ''){
      e.preventDefault();
      return false
    }
    let res = [];
    this.state.gamezonesTimes.forEach(item=> {
      if (item.gamezone_id !== gamezone_id){
        res.push(item);
      }
    });
    res.push({gamezone_id: gamezone_id, time: e.target.value});
    this.setState({gamezonesTimes: res})
  }

  checkSertificate(number){
    fetch("/checkSertificate",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({number: number})
      })
      .then(res => res.json())
      .then(data => {
        if (data.code === 5){
          this.props.quit();
        }
        if (data.code === 1){
          this.setState({sertificateError: 1, sertificateInfo: '', 
            sertificateOk: false});
        }
        if (data.code === 2){
          this.setState({sertificateError: 2, sertificateInfo: '',
            sertificateOk: false});
        }
        if (data.code === 0){
          this.setState({sertificateError: 0, sertificateInfo: data.info, 
            sertificateOk: true});
        }
      })
      .catch(error => console.error(error))
  }

  checkSertificateCost(number){
    fetch("/checkSertificateCost",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({number: number})
      })
      .then(res => res.json())
      .then(data => {
        if (data.code === 5){
          this.props.quit();
        }
        if (data.code === 1){
          this.setState({sertificateCostError: 1, sertificateCostLeft: 0, 
            sertificateCostOk: false});
        }
        if (data.code === 2){
          this.setState({sertificateCostError: 2, sertificateCostLeft: 0, 
            sertificateCostOk: false});
        }
        if (data.code === 0){
          this.setState({sertificateCostError: 0, sertificateCostLeft: parseInt(data.left),
             sertificateCostOk: true});
        }
      })
      .catch(error => console.error(error))
  }

  updateData(){
    fetch("/getAvailableMoves")
      .then(res => res.json())
      .then(data => {
          if (data.code === 5){
            this.props.quit();
          }
          else{
            this.setState({
              moves: data
            });
          }
        },
        (error) => {
          this.setState({          
            error
          });
        }
      )
  }

  onSubmit(values){
    values.games = this.state.selectedGames.map(game => game.game_id);
    values.date = this.state.date.add(3, 'hours');
    values.gamezonesTimes = this.state.gamezonesTimes;
    values.client_id = 0;
    if (this.props.client_id){
      values.client_id = this.props.client_id;
    }
    values.sertificateOk = this.state.sertificateOk;
    values.paymentCash = parseInt(values.paymentCash)

    let handleToUpdate = this.props.handleToUpdate;

    if (this.state.sertificateError === 0 && this.state.sertificateCostError === 0){
      fetch("/addVisit",
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify(values)
        })
        .then(res => res.json())
        .then(data => {
          if (data.code === 5){
            this.props.quit()
          }
          else{
            this.close();
            handleToUpdate();
          }
        })
        .catch(error => console.error(error))
    }
  }

  render() {
    return (
      <Row>
        <Button variant='outline-primary' onClick={() => this.show()} block>
          {this.props.client_id 
            ? '+Посещение' 
            : '+Посещение без карты'}
        </Button>
        <Modal size="lg" show={this.state.show} onHide={() => this.close()}>
          <Modal.Header closeButton>
            <Modal.Title>Новое посещение</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Formik
              validationSchema={this.getSchema(this.state.sertificateCostLeft)}
              onSubmit={(values) => {
                  this.onSubmit(values);
                }
              }
              initialValues={{branch_id: this.props.branch_id, person: 1, cost: '', 
                sertificate: false, sertificateNumber: '',
                sertificateCost: false, sertificateCostNumber: '',
                rent: false, celebration: false, duration: '', move_id: 0, 
                newPerson: 0, paymentCash: 2, usePrecost: false,
                sertificateCostCost: '0'
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                setFieldValue
              }) => (
                <Form noValidate onSubmit={handleSubmit}>

                    <Form.Group as={Row} controlId="visitDate">
                      <Form.Label column md={4}>Дата</Form.Label>
                      <Col md={8}>
                        <Datetime
                          locale="ru"
                          dateFormat="DD.MM.YYYY"
                          defaultHour={14}
                          timeFormat="HH:mm"
                          value={this.state.date}
                          onChange={this.handleDateChange}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="sertificate">
                      <Form.Label column md={4}>Сертификат на посещение</Form.Label>
                      <Col md={1}>
                        <Form.Control
                          type="checkbox"
                          name="sertificate"
                          checked={values.sertificate}
                          value={values.sertificate}
                          onChange={e => {
                            setFieldValue("sertificate", !values.sertificate);
                            setFieldValue("sertificateNumber", "");  
                            this.setState({sertificateInfo: null, sertificateError: 0})
                          }}
                        />
                      </Col>

                      <Form.Label column md={1}>Номер</Form.Label>
                      <Col md={4}>
                        <Form.Control
                          type="text"
                          name="sertificateNumber"
                          disabled={!values.sertificate}
                          value={values.sertificateNumber}
                          onChange={handleChange}
                        />
                      </Col>

                      <Button variant="primary" style={{marginTop: "0"}} 
                        onClick={() => this.checkSertificate(values.sertificateNumber)}>
                          Проверить</Button>
                    </Form.Group>

                    <Form.Group as={Row} controlId="sertificateInfo">
                      <Form.Label column md={4}></Form.Label>
                      <Col md={8}>
                        <p>{this.state.sertificateInfo}</p>
                        {this.state.sertificateError === 1 
                          ? <div class="text-danger">
                              Такого сертификата не существует!
                            </div> 
                          : ''}
                        {this.state.sertificateError === 2 
                          ? <div class="text-danger">
                              Данный сертификат уже не действителен!
                            </div> 
                          : ''}
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="sertificate_cost">
                      <Form.Label column md={4}>Сертификат на стоимость</Form.Label>
                      <Col md={1}>
                        <Form.Control
                          type="checkbox"
                          name="sertificate_cost"
                          checked={values.sertificateCost}
                          value={values.sertificateCost}
                          onChange={e => {
                            setFieldValue("sertificateCost", !values.sertificateCost);
                            setFieldValue("sertificateCostNumber", "");  
                            this.setState({sertificateCostInfo: null, sertificateCostError: 0})
                          }}
                        />
                      </Col>

                      <Form.Label column md={1}>Номер</Form.Label>
                      <Col md={4}>
                        <Form.Control
                          type="text"
                          name="sertificateCostNumber"
                          disabled={!values.sertificateCost}
                          value={values.sertificateCostNumber}
                          onChange={handleChange}
                        />
                      </Col>

                      <Button variant="primary" style={{marginTop: "0"}} 
                        onClick={() => this.checkSertificateCost(values.sertificateCostNumber)}>
                          Проверить</Button>
                    </Form.Group>

                    <Form.Group as={Row} controlId="sertificateCostInfo">
                      <Form.Label column md={4}></Form.Label>
                      <Col md={8}>
                        { (this.state.sertificateCostError === 0 && values.sertificateCost) 
                          && <p>Остаток сертификата: {parseInt(this.state.sertificateCostLeft) > 0 ? 
                            this.state.sertificateCostLeft : 0} руб.</p>}
                        {this.state.sertificateCostError === 1 
                          ? <div class="text-danger">
                              Такого сертификата не существует!
                            </div> 
                          : ''}
                        {this.state.sertificateCostError === 2  
                          ? <div class="text-danger">
                              Данный сертификат уже не действителен!
                            </div> 
                          : ''}
                        { (this.state.sertificateCostError === 0 && parseInt(this.state.sertificateCostLeft) > 0 && values.sertificateCost) 
                          && <div style={{display: 'flex'}}>
                            <Col md={6} style={{paddingLeft: 0}}>
                              Использовать: 
                            </Col>
                            <Col md={6} style={{paddingRight: 0}}>
                              <Form.Control
                                type="number"
                                name="sertificateCostCost"
                                value={values.sertificateCostCost}
                                onChange={e => {
                                  e.preventDefault();
                                  const { value } = e.target;
                                  const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                  if (value === '0' || regex.test(value.toString())) {
                                    setFieldValue("sertificateCostCost", value);
                                  }
                                }}
                                isInvalid={!!(errors.sertificateCostCost && touched.sertificateCostCost)}
                              />

                              <Form.Control.Feedback type="invalid">
                                {errors.sertificateCostCost}
                              </Form.Control.Feedback>
                            </Col>
                          </div>}
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="celebration">
                      <Form.Label column md={4}>Дет. праздник</Form.Label>
                      <Col md={1}>
                        <Form.Control
                          type="checkbox"
                          name="celebration"
                          checked={values.celebration}
                          value={values.celebration}
                          onChange={e => {
                            setFieldValue("celebration", !values.celebration);
                          }}
                        />
                      </Col>

                      <Form.Label column md={2}>Аренда зала</Form.Label>
                      <Col md={1}>
                        <Form.Control
                          type="checkbox"
                          name="rent"
                          checked={values.rent}
                          value={values.rent}
                          onChange={e => {
                            setFieldValue("rent", !values.rent);
                            if (!values.rent){
                              let times = [];
                              this.props.gamezones
                                .filter(gamezone => gamezone.branch_id === values.branch_id)
                                .forEach(gamezone => {
                                  let time = {gamezone_id: gamezone.gamezone_id, time: ''}
                                  times.push(time);
                                })
                              this.setState({
                                gamezonesTimes: times
                              });
                            }
                            else{
                              values.duration = 0;
                            }
                          }}
                        />
                      </Col>

                      <Col md={4}>
                        <Form.Control
                          type="number"
                          placeholder="мин"
                          name="duration"
                          step={10}
                          value={values.duration}
                          disabled={!values.rent}
                          onChange={e => {
                            e.preventDefault();
                            const { value } = e.target;
                            const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                            if (regex.test(value.toString())) {
                              setFieldValue("duration", value);
                            }
                          }}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="visitPerson">
                      <Form.Label column md={4}>Кол-во посетителей</Form.Label>
                      <Col md={3}>
                        <Form.Control
                          type="number"
                          placeholder="Кол-во"
                          name="person"
                          value={values.person}
                          onChange={e => {
                            e.preventDefault();
                            const { value } = e.target;
                            const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                            if (regex.test(value.toString())) {
                              setFieldValue("person", value);
                            }
                          }}
                          isInvalid={!!(errors.person && touched.person)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.person}
                        </Form.Control.Feedback>
                      </Col>

                      <Form.Label column md={1}>Новых</Form.Label>
                      <Col md={4}>
                        <Form.Control
                          type="number"
                          placeholder="Кол-во"
                          name="newPerson"
                          value={values.newPerson}
                          onChange={e => {
                            e.preventDefault();
                            const { value } = e.target;
                            const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                            if (regex.test(value.toString())) {
                              setFieldValue("newPerson", value);
                            }
                          }}
                          isInvalid={!!(errors.newPerson && touched.newPerson)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.newPerson}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="branch_id">
                      <Form.Label column md={4}>Игровые зоны</Form.Label>
                        <Col md={8}>
                        {
                              this.props.gamezones.filter(gamezone => gamezone.branch_id === parseInt(this.props.branch_id, 10))
                              .map(gz => {
                                return (
                                  <Row>
                                    <Form.Label column md={4}>
                                      {gz.name}
                                    </Form.Label>

                                    <Form.Label column md={2}>Время</Form.Label>
                                    <Col md={4}>
                                      <Form.Control
                                        type="number"
                                        placeholder="мин"
                                        disabled={values.rent}
                                        step={10}
                                        name={"time" + gz.gamezone_id}
                                        
                                        onChange={e => {
                                          e.preventDefault();
                                          const { value } = e.target;
                                          const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                          let res = [];
                                          this.state.gamezonesTimes.forEach(item=> {
                                            if (item.gamezone_id !== gz.gamezone_id){
                                              res.push(item);
                                            }
                                          });
                                          if (regex.test(value.toString())) {
                                            res.push({gamezone_id: gz.gamezone_id, 
                                                time: e.target.value});
                                          }
                                          else{
                                            res.push({gamezone_id: gz.gamezone_id, time: ''});
                                          }
                                          this.setState({gamezonesTimes: res})
                                        }}
                                      />
                                    </Col>
                                  </Row>)
                              })
                        }
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="games">
                      <Form.Label column md={4}>Игры</Form.Label>
                      <Col md={8}>
                        <Picky
                          value={this.state.selectedGames}
                          multiple={true}
                          options={
                            Object.keys(this.props.games
                              .filter(game => game.branches
                                .includes(parseInt(values.branch_id, 10))))
                              .map((key, index) =>
                              {
                                let dict = {};
                                dict.game_id = this.props.games[key].game_id;
                                let gameString = this.props.games
                                  .filter(value => value.game_id === this.props
                                    .games[key].game_id)
                                  .map(item => item.name)
                                dict.name = gameString;
                                return dict;
                              })
                            }
                          valueKey="game_id"
                          labelKey="name"
                          includeSelectAll={false}
                          includeFilter={true}
                          onChange={this.selectGame}
                          onBlur={handleBlur}
                          dropdownHeight={600}
                          id="games"
                          name="games"
                        />

                      </Col>
                    </Form.Group>

                    { this.props.precost > 0 
                      ? <React.Fragment>
                          <Row>
                            <div class='text-success'>
                              У клиента обнаружена предоплата {this.props.precost}р.
                            </div> 
                          </Row>
                          <Form.Group as={Row} controlId="precost">
                            <Form.Label column md={4}>Использовать предоплату</Form.Label>
                            <Col md={1}>
                              <Form.Control
                                type="checkbox"
                                name="usePrecost"
                                checked={values.usePrecost}
                                value={values.usePrecost}
                                onChange={e => {
                                  setFieldValue("usePrecost", !values.usePrecost);
                                }}
                              />
                            </Col>
                          </Form.Group>
                        </React.Fragment>
                      : ''
                    }

                    <Form.Group as={Row} controlId="visitCost">
                      <Form.Label column md={4}>Итоговая стоимость (без п.о.)</Form.Label>
                      <Col md={3}>
                        <Form.Control
                          type="number"
                          placeholder="Стоимость"
                          name="cost"
                          value={values.cost}
                          onChange={e => {
                            e.preventDefault();
                            const { value } = e.target;
                            const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                            if (value === '0' || regex.test(value.toString())) {
                              setFieldValue("cost", value);
                            }
                          }}
                          isInvalid={!!(errors.cost && touched.cost)}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.cost}
                        </Form.Control.Feedback>
                      </Col>

                      <Form.Label column md={2}>Нал. р-т</Form.Label>
                      <Col md={3}>
                        <Form.Control
                          as="select"
                          name="paymentCash"
                          value={values.paymentCash}
                          onChange={handleChange}
                          isInvalid={!!(errors.paymentCash && touched.paymentCash)}
                        >
                          <option value={2}>Не указан</option>
                          <option value={0}>Безнал.</option>
                          <option value={1}>Нал.</option>
                        </Form.Control>

                        <Form.Control.Feedback type="invalid">
                          {errors.paymentCash}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="visitMove">
                      <Form.Label column md={4}>По акции</Form.Label>
                      <Col md={8}>
                        <Form.Control
                          as="select"
                          name="move_id"
                          value={values.move_id}
                          onChange={handleChange}
                          isInvalid={!!(errors.move_id && touched.move_id)}
                        >
                          <option value='0'>Без акции</option>
                            {
                              Object.keys(this.state.moves).map((key, index) => (
                                <option value={this.state.moves[key].move_id}>
                                  {this.state.moves[key].name}</option>
                            ))
                          }
                        </Form.Control>

                        <Form.Control.Feedback type="invalid">
                          {errors.move_id}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <div class="d-flex flex-row-reverse">
                      <Button type="submit">Создать</Button>
                      <Button variant="secondary" 
                        onClick={() => this.close()}>Отмена</Button>
                    </div>
                </Form>
              )}
            </Formik>

          </Modal.Body>
        </Modal>
      </Row>
    )
  }
}

export default AddVisitUserForm;
